import { StoreContext } from '@/components/App'
import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { InputWrapper } from './Filters.styles'
import { RadioList } from '@/components/Generic/Radio/Radio.styles'
import { RadioInput } from '@/components/Generic/Radio/Radio'
import { SelectInput } from '@/components/Generic'
import { statusOptions, StatusValue } from '@/store/TransactionListFilter.store'
import { SelectOption } from '@/types/generic'

export const StatusInput: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionsPage: trans } = store.TranslationsState.translations
  const { transactionListFiltersPosition } = store.Settings
  const { status } = store.TransactionListFilterState

  if (transactionListFiltersPosition === 'left') {
    return (
      <InputWrapper width={'120px'} style={{ gridArea: 'status' }}>
        <label>{trans.status}</label>
        <RadioList>
          <RadioInput
            value={status === 'all'}
            onChange={(value) => value && store.TransactionListFilterState.setStatus('all')}
            label="All"
          />
          <RadioInput
            value={status === 'completed'}
            onChange={(value) => value && store.TransactionListFilterState.setStatus('completed')}
            label="Completed"
          />
          <RadioInput
            value={status === 'pending'}
            onChange={(value) => value && store.TransactionListFilterState.setStatus('pending')}
            label="Pending"
          />
          <RadioInput
            value={status === 'expired'}
            onChange={(value) => value && store.TransactionListFilterState.setStatus('expired')}
            label="Expired"
          />
        </RadioList>
      </InputWrapper>
    )
  } else {
    return (
      <InputWrapper width={'120px'} style={{ gridArea: 'status' }}>
        <label>{trans.status}</label>
        <SelectInput
          options={statusOptions.map((status) => ({ label: status, value: status }))}
          value={{ label: status, value: status }}
          onChange={(e: SelectOption) =>
            store.TransactionListFilterState.setStatus(e.value as StatusValue)
          }
        />
      </InputWrapper>
    )
  }
})
