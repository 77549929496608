import { TransactionListFiltersPosition } from '@/store/Settings.store'
import { css, styled } from 'styled-components'
import { Container } from './Components/Pagination/Pagination.styles'

export const Page = styled.div`
  width: 100%;
  padding: 40px 48px;
  height: calc(100vh - 64px);
  container-type: inline-size;
  overflow-y: hidden;
`

export const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: 12px;

  color: ${({ theme }) => theme.transactionListPage.title.color};
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  span {
    color: ${({ theme }) => theme.transactionListPage.title.iconColor};
  }
`

export const VerticalFiltersWrapper = styled.div<{
  $filterPosition?: TransactionListFiltersPosition
}>`
  display: contents;

  ${({ $filterPosition }) =>
    $filterPosition === 'left' &&
    css`
      display: grid;
      grid-template-columns: 381px 1fr;
      grid-template-rows: 40px calc(100vh - 320px) 40px;
      gap: 40px 0;

      ${Title} {
        grid-column: 2;
        grid-row: 1;
      }

      ${Container} {
        grid-column: 2;
        grid-row: 3;
      }
    `}
`
