import { observer } from 'mobx-react'
import { FC, ReactNode, useContext, useEffect } from 'react'
import { useLocation } from 'wouter'
import { StoreContext } from './App'
import { DEV_MODE } from '@/constants/envs'

export const PATHS_WITHOUT_AUTH = ['/login', '/unauthorized', '/dev']

export const AuthHandler: FC<{ children?: ReactNode }> = observer(({ children }) => {
  const [location, setLocation] = useLocation()
  const store = useContext(StoreContext)
  const { isLogged, isAuthorized } = store.Auth
  const { singleTransactionPage: trans } = store.TranslationsState.translations

  useEffect(() => {
    if (DEV_MODE) return
    console.log('Auth provider: ', isLogged, isAuthorized, location)

    if (!isLogged && location !== '/login') {
      console.log('Auth provider - Redirecting to login')
      setLocation('/login')
    } else if (!isAuthorized && !PATHS_WITHOUT_AUTH.includes(location)) {
      console.log('Auth provider - Redirecting to unauthorized')
      setLocation(
        `/unauthorized?title=${trans.notAuthorized}&description=${trans.notAuthorizedMessage}`
      )
    }
  }, [isLogged, isAuthorized, location, trans])

  return <div>{children}</div>
})
