import { SelectOption } from '@/types/generic'

export const toSelectOptions = (value: string[] | SelectOption[]) => {
  if (value.length > 0 && typeof value[0] === 'string') {
    return (value as string[]).map((item) => ({ value: item, label: item }))
  }

  return value as SelectOption[]
}

export const tenantsSort = (a: string, b: string) => {
  const partsA = a.split('.')
  const partsB = b.split('.')

  // Check if either string contains a dot
  const hasDotA = partsA.length > 1
  const hasDotB = partsB.length > 1

  // First, sort by the part after the dot if both have dots
  if (hasDotA && hasDotB) {
    const compareAfterDot = partsA[1].localeCompare(partsB[1])
    if (compareAfterDot !== 0) {
      return compareAfterDot
    }
  } else if (hasDotA) {
    // If only A has dot, A should come after B
    return 1
  } else if (hasDotB) {
    // If only B has dot, B should come after A
    return -1
  }

  // If the parts after dot are the same, sort by the part before dot
  return partsA[0].localeCompare(partsB[0])
}
