import { observer } from 'mobx-react'
import { FC, useContext, useEffect } from 'react'
import { TableContainer, Table, ColumnManager } from './TransactionsTable.styles'
import { TableRow } from './Components/TableRow'
import { TableHeader } from './Components/TableHeader'
import { StoreContext } from '@/components/App'
import { ColumnVisibilityManager } from './Components/ColumnVisibilityManager'
import { LoadingSpinner } from '@/components/Generic/LoadingSpinner/LoadingSpinner'
import { DEV_MODE } from '@/constants/envs'
import { tableDataMock } from '../../mock'

export const TransactionsTable: FC = observer(() => {
  const store = useContext(StoreContext)
  const { columnsWidthsComputed, isFetching, transactions } = store.TransactionListState

  const { currentUserId } = store.Auth
  const { selectedTenant, isSideMenuOpen } = store.AppState
  const { transactionListFiltersPosition } = store.Settings

  useEffect(() => {
    if (DEV_MODE) {
      store.TransactionListState.setTransactions(tableDataMock)
      store.TransactionListState.setIsFetching(false)
      return
    }
  }, [selectedTenant, currentUserId])

  return (
    <TableContainer
      $filterPosition={transactionListFiltersPosition}
      $isSideMenuOpen={isSideMenuOpen}
    >
      {isFetching ? (
        <LoadingSpinner centered />
      ) : (
        <>
          <ColumnManager>
            <ColumnVisibilityManager />
          </ColumnManager>
          <Table $columns={7} $columnsWidths={columnsWidthsComputed}>
            <TableHeader />
            {transactions.map((row) => (
              <TableRow key={row.transactionId} {...row} />
            ))}
          </Table>
        </>
      )}
    </TableContainer>
  )
})
