import { FC, ReactNode, useContext, useEffect } from 'react'
import useSWR from 'swr'
import { observer } from 'mobx-react'
import { axiosInstance } from '@/axios/AxiosInstance'
import { StoreContext } from '@/components/App'

interface TransactionDataProviderProps {
  children: ReactNode
}

export const TransactionDataProvider: FC<TransactionDataProviderProps> = observer(
  ({ children }) => {
    const store = useContext(StoreContext)

    const { filters } = store.TransactionListFilterState
    const { selectedTenant } = store.AppState

    const nonNullFilters = new URLSearchParams(
      Object.entries(filters).filter(
        ([k, v]) => v !== undefined && v !== null && v !== '' && v?.length !== 0
      )
    ).toString()

    const fetcher = async (path) => {
      if (!selectedTenant) return

      console.log('fetcher', path)

      const response = await axiosInstance.get(path)
      return response.data
    }

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      `/transactions?${nonNullFilters}`,
      fetcher,
      {}
    )

    useEffect(() => {
      store.TransactionListState.setIsFetching(isLoading)
    }, [isLoading])

    useEffect(() => {
      store.TransactionListState.setTransactions(data?.items)
      store.TransactionListState.setPaginationData({
        currentPage: data?.currentPage,
        total: data?.total,
        limit: data?.limit,
      })
    }, [data])

    useEffect(() => {
      if (mutate) {
        store.TransactionListState.setRefetchFn(mutate)
      }
    }, [mutate])

    return <>{children}</>
  }
)
