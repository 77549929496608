export const FileDownload = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.625 4.58333C3.625 3.3637 4.6137 2.375 5.83333 2.375H10.9763C11.562 2.375 12.1237 2.60766 12.5378 3.02181L15.7282 6.21216C16.1423 6.6263 16.375 7.188 16.375 7.77369V12.0833C16.375 12.3825 16.1325 12.625 15.8333 12.625C15.5342 12.625 15.2917 12.3825 15.2917 12.0833V8.45833H12.5C11.2804 8.45833 10.2917 7.46963 10.2917 6.25V3.45833H5.83333C5.21201 3.45833 4.70833 3.96201 4.70833 4.58333V15.4167C4.70833 16.038 5.21201 16.5417 5.83333 16.5417H9.58333C9.88249 16.5417 10.125 16.7842 10.125 17.0833C10.125 17.3825 9.88249 17.625 9.58333 17.625H5.83333C4.6137 17.625 3.625 16.6363 3.625 15.4167V4.58333ZM11.375 3.53135V6.25C11.375 6.87132 11.8787 7.375 12.5 7.375H15.2187C15.1627 7.22746 15.0759 7.09191 14.9622 6.97819L11.7718 3.78784C11.6581 3.67412 11.5225 3.58726 11.375 3.53135ZM13.35 11.5417C13.6492 11.5417 13.8917 11.7842 13.8917 12.0833V15.7317L15.36 14.2075C15.5675 13.9921 15.9104 13.9857 16.1259 14.1932C16.3413 14.4008 16.3477 14.7437 16.1402 14.9591L13.7319 17.4591C13.6298 17.5651 13.4889 17.625 13.3418 17.625C13.1946 17.625 13.0538 17.5651 12.9517 17.4591L10.5433 14.9591C10.3358 14.7437 10.3422 14.4008 10.5576 14.1932C10.7731 13.9857 11.116 13.9921 11.3235 14.2075L12.8083 15.7489V12.0833C12.8083 11.7842 13.0509 11.5417 13.35 11.5417Z"
        fill="currentColor"
      />
    </svg>
  )
}
