import { Icon, IconTypes } from '../Icon/Icon'
import { StyledButton } from './IconButton.styles'

interface IconButtonProps {
  onClick?: () => void
  iconType?: IconTypes
  disabled?: boolean
}

export const IconButton = ({ onClick, iconType, disabled }: IconButtonProps) => {
  const handleOnClick = () => {
    onClick && onClick()
  }

  return (
    <StyledButton onClick={handleOnClick} disabled={disabled}>
      <Icon size="20px" type={iconType} />
    </StyledButton>
  )
}
