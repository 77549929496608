import { css, styled } from 'styled-components'

export const Container = styled.div<{ $isSideMenuOpen?: boolean }>`
  grid-area: sidebar;
  position: fixed;
  z-index: 2;
  display: flex;
  width: ${({ $isSideMenuOpen }) => ($isSideMenuOpen ? '264px' : '82px')};
  height: calc(100vh - 64px);
  margin: 64px 0 0 0;
  padding: 50px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: space-between;
  border-right: ${({ theme }) => theme.sidebar.border};
  background-color: ${({ theme }) => theme.sidebar.bgColor};
  transition: width 0.3s ease-in-out;

  @media (max-width: 980px) {
    width: ${({ $isSideMenuOpen }) => ($isSideMenuOpen ? '100%' : '0')};
  }
`

export const StyledToggleButton = styled.div<{ $isOpen?: boolean }>`
  position: absolute;
  top: 9px;
  right: -16px;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  cursor: pointer;
  color: ${({ theme }) => theme.sidebar.toggle.color};
  background: ${({ theme }) => theme.sidebar.toggle.bgColor};

  transition:
    transform 0.3s ease-in-out,
    filter 0.3s ease-in-out;

  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  padding: ${({ $isOpen }) => ($isOpen ? '0 0 0 0' : '0 0 0 2px')};

  &:hover {
    filter: ${({ theme }) => `brightness(${theme.effects.hover.brightness})`};
  }

  @media (max-width: 980px) {
    display: none;
  }
`

export const StyledMenu = styled.div`
  align-self: stretch;
`

export const StyledMenuItem = styled.a`
  display: flex;
  min-height: 40px;
  padding: 8px 8px 8px 30px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-decoration: none;

  span {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.sidebar.menuItem.color};
  }

  svg {
    fill: ${({ theme }) => theme.sidebar.menuItem.color};
  }

  &:hover,
  &.active {
    padding-left: 24px;
    border-left: 6px solid ${({ theme }) => theme.sidebar.menuItem.colorHover};
    background: ${({ theme }) => theme.sidebar.menuItem.bgColorHover};

    span {
      color: ${({ theme }) => theme.sidebar.menuItem.colorHover};
    }

    svg {
      fill: ${({ theme }) => theme.sidebar.menuItem.colorHover};
    }
  }
`

export const StyledMenuIcon = styled.div``
