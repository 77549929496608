import { FC, useState } from 'react'
import { styled } from 'styled-components'

export const Container = styled.button<{ $shouldAnimate?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 100px;
  outline: none;
  border: ${({ theme }) => theme.transactionListPage.title.refreshButton.border};
  background-color: ${({ theme }) => theme.transactionListPage.title.refreshButton.bgColor};
  color: ${({ theme }) => theme.transactionListPage.title.refreshButton.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: ${({ theme }) => `brightness(${theme.effects.hover.brightness})`};
  }

  svg {
    animation: ${({ $shouldAnimate }) => $shouldAnimate && 'spin 1s infinite'};
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(-180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export const RefreshButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const handleClick = () => {
    onClick && onClick()
    setShouldAnimate(true)

    setTimeout(() => {
      setShouldAnimate(false)
    }, 1000)
  }

  return (
    <Container onClick={handleClick} $shouldAnimate={shouldAnimate}>
      <svg width="20" height="20" viewBox="0 0 20 20">
        <path
          d="M3.58333 9.70833C3.58333 6.32559 6.32559 3.58333 9.70833 3.58333C12.3048 3.58333 14.5252 5.19931 15.4162 7.48169H11.9015C11.6024 7.48169 11.3607 7.7242 11.3618 8.02335C11.3629 8.3225 11.6063 8.56501 11.9055 8.56501H15.4613C16.5479 8.56501 17.4256 7.68408 17.4217 6.5974L17.4086 3.04167C17.4075 2.74251 17.1641 2.5 16.865 2.5C16.5658 2.5 16.3242 2.74251 16.3253 3.04167L16.3383 6.5974C16.3386 6.6724 16.3295 6.7452 16.3122 6.81474C15.1977 4.27487 12.6609 2.5 9.70833 2.5C5.72728 2.5 2.5 5.72728 2.5 9.70833C2.5 13.6894 5.72728 16.9167 9.70833 16.9167C12.806 16.9167 15.446 14.963 16.466 12.2224C16.5703 11.942 16.4276 11.6301 16.1473 11.5258C15.8669 11.4214 15.555 11.5641 15.4507 11.8445C14.5834 14.1748 12.3389 15.8333 9.70833 15.8333C6.32559 15.8333 3.58333 13.0911 3.58333 9.70833Z"
          fill="currentColor"
        />
      </svg>
    </Container>
  )
}
