export const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54048 6.54036C9.79432 6.28652 10.2059 6.28652 10.4597 6.54036L15.4597 11.5404C15.7136 11.7942 15.7136 12.2058 15.4597 12.4596L10.4597 17.4596C10.2059 17.7134 9.79432 17.7134 9.54048 17.4596C9.28664 17.2058 9.28664 16.7942 9.54048 16.5404L14.0809 12L9.54048 7.45959C9.28664 7.20575 9.28664 6.7942 9.54048 6.54036Z"
      />
    </svg>
  )
}
