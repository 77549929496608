import { axiosInstance } from '../axios/AxiosInstance'
import { action, makeAutoObservable } from 'mobx'
import { TenantsCapabilities } from './AppState.store'
import { RootStore } from './Root.store'
import { DEV_MODE } from '@/constants/envs'

const KEEP_SESSION_ALIVE_INTERVAL_MS = 300000

export class AuthStore {
  isCheckingAuth = !DEV_MODE

  isLogged = true
  isAuthorized = true
  shouldLogout = false
  currentUserId = ''
  keepSessionAliveInterval: ReturnType<typeof setTimeout> = null

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setIsLogged(flag: boolean): void {
    this.isLogged = flag
  }

  @action.bound setIsAuthorized(flag: boolean): void {
    this.isAuthorized = flag
  }

  @action.bound setShouldLogout(flag: boolean): void {
    this.shouldLogout = flag
  }

  @action.bound setCurrentUserId(userId: string): void {
    this.currentUserId = userId
  }

  @action.bound async checkAuth() {
    try {
      if (this.shouldLogout) {
        this.setIsLogged(false)
        this.setCurrentUserId('')
      } else if (!this.isLogged || !this.currentUserId) {
        const res = await axiosInstance.get<{ userId: string }>(`/users/me`)

        if (res.data?.userId) {
          const localCurrentUser = localStorage.getItem('currentUserId')
          if (localCurrentUser !== res.data.userId) {
            this.rootStore.AppState.setSelectedTenant('')
            localStorage.setItem('currentUserId', res.data.userId)
          }
          res && res.data && this.setIsLogged(true)
          res && res.data && this.setIsAuthorized(true)
          res && res.data && res.data.userId && this.setCurrentUserId(res.data.userId)
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      this.setIsLogged(false)
      this.setCurrentUserId('')
    }

    this.isCheckingAuth = false
  }

  @action async keepSessionAlive() {
    try {
      if (this.isLogged && !this.shouldLogout) {
        const res = await axiosInstance.get<{
          capabilitiesChanged: boolean
          tenants: TenantsCapabilities
        }>(`/keep-session-alive`)

        if (res.data.capabilitiesChanged) {
          // eslint-disable-next-line
          this.rootStore.AppState.setTenantsCapabilities(res.data.tenants)
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      this.setIsLogged(false)
      this.setCurrentUserId('')
      this.setShouldLogout(true)
    }
  }

  @action initKeepSessionAliveInterval() {
    if (!this.keepSessionAliveInterval) {
      this.keepSessionAliveInterval = setInterval(
        () => void this.keepSessionAlive(),
        KEEP_SESSION_ALIVE_INTERVAL_MS
      )
    }
  }

  @action disposeKeepSessionAliveInterval() {
    if (this.keepSessionAliveInterval) {
      clearTimeout(this.keepSessionAliveInterval)
      this.keepSessionAliveInterval = null
    }
  }
}
