import { observer } from 'mobx-react'
import { FC, useContext, useEffect, useState } from 'react'
import { Container, StyledMenu } from './Sidebar.styles'
import { StoreContext } from '../App'
import { ToggleButton } from './Components/ToggleButton'
import { MenuItem } from './Components/MenuItem'
import { useDarkMode } from '@/hooks/useDarkmode'
import { useWindowSize } from 'usehooks-ts'

export const Sidebar: FC = observer(() => {
  const { toggle: toggleTheme } = useDarkMode()
  const store = useContext(StoreContext)
  const { menu: trans } = store.TranslationsState.translations
  const { isSideMenuOpen } = store.AppState
  const [showMenuItems, setShowMenuItems] = useState(isSideMenuOpen)
  const [showMenuLabels, setShowMenuLabels] = useState(isSideMenuOpen)

  const { width = 0 } = useWindowSize()

  useEffect(() => {
    setTimeout(
      () => {
        setShowMenuItems(width > 980 ? true : isSideMenuOpen)
        setShowMenuLabels(isSideMenuOpen)
      },
      isSideMenuOpen ? 300 : 0
    )
  }, [isSideMenuOpen, width])

  return (
    <Container $isSideMenuOpen={isSideMenuOpen}>
      <ToggleButton
        onClick={() => store.AppState.setIsSideMenuOpen()}
        isSideMenuOpen={isSideMenuOpen}
      />
      <StyledMenu>
        <MenuItem
          isActive={true}
          isVisible={showMenuItems}
          isLabelVisible={showMenuLabels}
          icon="transactions"
          label={trans.transactions}
          href="/transactions"
        />
        <MenuItem
          isVisible={showMenuItems}
          isLabelVisible={showMenuLabels}
          icon="create_transaction"
          label={trans.createTransaction}
          href="/create_transaction"
        />
        {/* <MenuItem
          isVisible={showMenuItems}
          isLabelVisible={showMenuLabels}
          icon="jwt_encryption"
          label={'JWT Encryption'}
        /> */}
        <MenuItem
          isVisible={showMenuItems}
          isLabelVisible={showMenuLabels}
          icon="settings"
          label={trans.settings}
        />
      </StyledMenu>
      <StyledMenu>
        <span onClick={() => store.Settings.toggleTransactionListFiltersPosition()}>
          <MenuItem
            isVisible={showMenuItems}
            isLabelVisible={showMenuLabels}
            icon="🛠️"
            label={trans.filters}
          />
        </span>
        <span onClick={toggleTheme}>
          <MenuItem
            isVisible={showMenuItems}
            isLabelVisible={showMenuLabels}
            icon="🛠️"
            label={trans.theme}
          />
        </span>
        <MenuItem
          isVisible={showMenuItems}
          isLabelVisible={showMenuLabels}
          icon="logout"
          label={trans.logOut}
        />
      </StyledMenu>
    </Container>
  )
})
