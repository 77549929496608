import { styled } from 'styled-components'
import { StyledToggleButton } from '../Sidebar.styles'
import { FC } from 'react'

interface ToggleButtonProps {
  onClick: () => void
  isSideMenuOpen: boolean
}

export const ToggleButton: FC<ToggleButtonProps> = ({ onClick, isSideMenuOpen }) => {
  return (
    <StyledToggleButton onClick={onClick} $isOpen={isSideMenuOpen}>
      <svg width="12" height="12" viewBox="0 0 8 12">
        <path
          d="M0.967329 0.780097C1.21775 0.522877 1.62927 0.517361 1.88649 0.767777L6.95305 5.70032C7.21027 5.95073 7.21578 6.36225 6.96537 6.61947L2.03283 11.686C1.78241 11.9433 1.37089 11.9488 1.11367 11.6984C0.856453 11.4479 0.850937 11.0364 1.10135 10.7792L5.58047 6.17837L0.979649 1.69925C0.722429 1.44884 0.716913 1.03732 0.967329 0.780097Z"
          fill="currentColor"
        ></path>
      </svg>
    </StyledToggleButton>
  )
}
