import React, { FC, useCallback, useRef } from 'react'
import useClickOutside from '@hooks/useClickOutside'
import {
  Close,
  Container,
  FullscreenWrap,
  PopupHeader,
  PopupTitle,
  PopupBody,
  PopupFooter,
} from './Popup.styles'
import { Icon } from '@components/Icon/Icon'

interface PopupProps {
  closeIconHidden?: boolean
  clickOutsideEnabled?: boolean
  title?: string
  footer?: React.ReactNode
  children?: React.ReactNode
  handleVisibility?: (boolean) => void
  visibility: boolean
  closeIcon?: React.ReactNode
}

export const Popup: FC<PopupProps> = ({
  children,
  title,
  footer,
  closeIconHidden,
  clickOutsideEnabled,
  handleVisibility,
  visibility,
  closeIcon,
}) => {
  const popup = useRef()

  const close = useCallback(() => handleVisibility(false), [])
  useClickOutside(popup, clickOutsideEnabled ? close : undefined)

  return visibility ? (
    <FullscreenWrap>
      <Container ref={popup}>
        {!closeIconHidden && (
          <Close onClick={() => handleVisibility(false)}>
            {closeIcon ? closeIcon : <Icon size="24px" type="x-mark" />}
          </Close>
        )}
        {title && (
          <PopupHeader>
            <PopupTitle>{title}</PopupTitle>
          </PopupHeader>
        )}

        {children && <PopupBody>{children}</PopupBody>}

        {footer && <PopupFooter>{footer}</PopupFooter>}
      </Container>
    </FullscreenWrap>
  ) : (
    <></>
  )
}
