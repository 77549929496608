import { StoreContext } from '@/components/App'
import { SelectOption } from '@/types/generic'
import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { useTheme } from 'styled-components'
import { useLocation } from 'wouter'
import { Tenant } from '../TopMenu/TopMenu.styles'
import {
  SelectMenuPortalGlobalStyle,
  TenantDropdownWrapper,
  TenantSelector,
} from './TenantSwitcher.styles'
import Select, { IndicatorsContainerProps } from 'react-select'
import { tenantsSort, toSelectOptions } from '@/helpers/selectHelpers'

const renderIndicatorsContainer = (parent) => (props: IndicatorsContainerProps) => {
  return (
    <div>
      {/* <components.IndicatorsContainer {...props} /> */}
      {parent === 'topMenu' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path d="M5.83301 7.9165L9.99967 12.0832L14.1663 7.9165H5.83301Z" fill="white" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="8"
          viewBox="0 0 13 8"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.96 1.03999C12.2139 1.29383 12.2139 1.70539 11.96 1.95923L6.96001 6.95923C6.70617 7.21307 6.29461 7.21307 6.04077 6.95923L1.04077 1.95923C0.786931 1.70539 0.786931 1.29383 1.04077 1.03999C1.29461 0.786149 1.70617 0.786149 1.96001 1.03999L6.50039 5.58037L11.0408 1.03999C11.2946 0.786149 11.7062 0.786149 11.96 1.03999Z"
            fill="currentColor"
          />
        </svg>
      )}
    </div>
  )
}

interface TenantSwitcherProps {
  showTitle?: boolean
  parent?: string
}

export const TenantSwitcher: FC<TenantSwitcherProps> = observer(({ showTitle, parent }) => {
  const theme = useTheme()
  const [location, setLocation] = useLocation()
  const store = useContext(StoreContext)
  const { tenantsWithWriteRights, selectedTenant, tenants } = store.AppState
  const { menu: trans } = store.TranslationsState.translations

  const tenantsList = toSelectOptions(tenants.sort(tenantsSort))
  const selectedTenantIndex = tenantsList.findIndex((item) => item.value === selectedTenant)

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: theme[parent].tenantSwitcher.backgroundColor,
      border: theme[parent].tenantSwitcher.border,
    }),
  }

  return (
    <Tenant>
      <TenantSelector $parent={parent || 'topMenu'}>
        {showTitle && <span>{trans.clientID}:</span>}
        <TenantDropdownWrapper $parent={parent || 'topMenu'}>
          <Select
            options={tenantsList}
            value={tenantsList[selectedTenantIndex]}
            onChange={(e: SelectOption) => {
              void store.AppState.setSelectedTenant(e.value)
              if (location !== '/transactions') {
                setLocation('/transactions')
              }
            }}
            placeholder={trans.selectTenant}
            classNamePrefix={'select-tenant'}
            components={{ IndicatorsContainer: renderIndicatorsContainer(parent || 'topMenu') }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            styles={customStyles}
          />
          <SelectMenuPortalGlobalStyle $parent={parent || 'topMenu'} />
        </TenantDropdownWrapper>
      </TenantSelector>
    </Tenant>
  )
})
