import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '@/components/App'
import { TableHeaderCell } from '../TransactionsTable.styles'

interface HeaderCellProps {
  columnId: string
  columnName?: string
}

export const HeaderCell: FC<HeaderCellProps> = ({ columnId, columnName }) => {
  return <TableHeaderCell key={columnId}>{columnName || columnId}</TableHeaderCell>
}

export const TableHeader: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionListHeaders: trans } = store.TranslationsState.translations
  const { visibleColumns } = store.TransactionListState

  return visibleColumns.map((column) => (
    <HeaderCell key={column} columnId={column} columnName={trans[column] || column} />
  ))
})
