import { Json, Theme } from './theme'

export const commonTheme: Pick<Theme, 'breakpoints' | 'fonts' | 'animations'> = {
  breakpoints: {
    mobile: '768px',
    tablet: '1024px',
    desktop: '1280px',
    desktopLarge: '1440px',
  },
  fonts: {
    size: 14,
    family: 'Inter, Arial, sans-serif',
    familyVariant: 'Arial, sans-serif',
  },
  animations: {
    enabled: true,
    duration: '0.5s',
    function: 'ease-out',
  },
}

export const json: Json = {
  textColor: '#2A0760',
  textSecondaryColor: '#2A0760',
  headlineColor: '#2A0760',
  backgroundColor: '#F2EDFE',
  lineHeight: '22px',
  linePadding: '0px 3px 0px 4px',
  borderDefault: 'rgba(255, 255, 255, 0.23)',
  borderFocused: '#05aeca',
  borderInvalid: '#9d190f',
  selectionMatch: '#ae38a782',
  activeLineGutter: '#7D18FF11',
  activeLine: '#7D18FF11',
  activeSelection: '#1872b9',
  dividerColor: '#EEE',
  spacing: '4px',
  contentPadding: `calc(4px / 2)`,
  borderRadius: '8px',
}
