import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const StyledButton = styled.button`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: ${(props) => props.theme.button.secondary.bgColor};
  border: ${(props) => props.theme.button.secondary.border};
  color: ${(props) => props.theme.button.secondary.color};
  cursor: pointer;

  svg,
  path {
    fill: ${({ theme }) => theme.button.secondary.color} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.button.secondary.hover.bgColor};
    border: ${({ theme }) => theme.button.secondary.hover.border};
    box-shadow: ${({ theme }) => theme.button.secondary.hover.boxShadow};
  }

  &:active {
    background-color: ${({ theme }) => theme.button.secondary.active.bgColor};
    border: ${({ theme }) => theme.button.secondary.active.border};
    box-shadow: ${({ theme }) => theme.button.secondary.active.boxShadow};
  }

  &:disabled,
  &[disabled] {
    background-color: ${({ theme }) => theme.button.secondary.disabled.bgColor};
    border: ${({ theme }) => theme.button.secondary.disabled.border};

    svg,
    path {
      fill: ${({ theme }) => theme.button.secondary.disabled.color} !important;
    }
  }

  ${Animatable}
`
