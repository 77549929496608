export const History = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40">
      <path
        fill="currentColor"
        d="M32.5014 5.13337C31.9031 5.13173 31.4185 5.61543 31.4191 6.21374L31.4241 11.601C27.9751 7.14832 22.0726 4.95153 16.289 6.50125C8.59823 8.56199 4.03417 16.4672 6.09491 24.158C8.15566 31.8488 16.0608 36.4128 23.7516 34.3521C29.7359 32.7486 33.8247 27.6078 34.3765 21.7854C34.4329 21.1897 33.9958 20.6611 33.4002 20.6047C32.8045 20.5482 32.2759 20.9853 32.2195 21.581C31.7503 26.5316 28.2727 30.8976 23.1909 32.2593C16.6559 34.0103 9.93879 30.1322 8.18775 23.5972C6.43671 17.0623 10.3148 10.3451 16.8498 8.59409C21.9069 7.23904 27.0757 9.25503 29.9653 13.2678L24.3677 13.2526C23.7694 13.2509 23.2848 13.7346 23.2854 14.3329C23.2859 14.9312 23.7714 15.4176 24.3697 15.4192L30.1807 15.4351C32.066 15.4402 33.593 13.916 33.5912 12.0307L33.5857 6.21965C33.5852 5.62135 33.0997 5.135 32.5014 5.13337Z"
      />
      <path
        fill="currentColor"
        d="M24.1689 23.878C24.7672 23.878 25.2523 23.393 25.2523 22.7947C25.2523 22.1964 24.7672 21.7114 24.1689 21.7114L18.9432 21.7114V16.4856C18.9432 15.8873 18.4581 15.4023 17.8598 15.4023C17.2615 15.4023 16.7765 15.8873 16.7765 16.4856L16.7765 22.7947C16.7765 23.393 17.2615 23.878 17.8598 23.878H24.1689Z"
      />
    </svg>
  )
}
