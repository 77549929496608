import { Theme } from '@/themes/theme'
import { FC } from 'react'
import { styled, useTheme } from 'styled-components'

interface PillColors {
  text: string
  dot: string
  bg: string
}

const getPillColors = (status: string, theme: Theme): PillColors => {
  const parsedStatus = status.toLowerCase()

  switch (parsedStatus) {
    case 'success':
    case 'completed':
    case 'accepted':
      return theme.transactionDetailsPage.pillStatusResult.green
    case 'failure':
    case 'rejected':
      return theme.transactionDetailsPage.pillStatusResult.red
    case 'expired':
      return theme.transactionDetailsPage.pillStatusResult.yellow
    case 'pending':
      return theme.transactionDetailsPage.pillStatusResult.blue

    default:
      return theme.transactionDetailsPage.pillStatusResult.default
  }
}

const Container = styled.div<{ $colors: PillColors }>`
  display: flex;
  padding: 0px 12px 0px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  height: 32px;
  align-items: center;

  font-size: 14px;
  font-weight: 400;

  color: ${({ $colors }) => $colors.text};
  /* background-color: ${({ $colors }) => $colors.bg}; */

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ $colors }) => $colors.dot};
  }
`

interface PillProps {
  status?: string
}

export const Pill: FC<PillProps> = ({ status }) => {
  const theme = useTheme()
  const capitalizedStatus = status?.charAt(0).toUpperCase() + status?.slice(1)

  return (
    <Container $colors={getPillColors(status, theme)}>
      <div />
      {capitalizedStatus}
    </Container>
  )
}
