import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import {
  Container,
  Logo,
  Search,
  SearchInput,
  SearchInputButton,
  SearchInputIcon,
  StyledMenu,
} from './TopMenu.styles'
import { StoreContext } from '../App'
import { SignicatLogo } from './Components/SignicatLogo'
import { Notifications } from './Components/Notifications'
import { UserInfo } from './Components/UserInfo'
import { TenantSwitcher } from '../TenantSwitcher/TenantSwitcher'
import { Hamburger } from './Components/Hamburger'

export const TopMenu: FC = observer(() => {
  const store = useContext(StoreContext)

  return (
    <Container>
      <StyledMenu>
        <Logo>
          <SignicatLogo />
        </Logo>

        {/* <Search>
          <SearchInputIcon>
            <svg width="16" height="16" viewBox="0 0 16 16">
              <path
                d="M7.61691 3.53838C5.36943 3.53838 3.54749 5.34223 3.54749 7.56739C3.54749 9.79255 5.36943 11.5964 7.61691 11.5964C9.86438 11.5964 11.6863 9.79255 11.6863 7.56739C11.6863 5.34223 9.86438 3.53838 7.61691 3.53838ZM2.66699 7.56739C2.66699 4.86077 4.88314 2.66663 7.61691 2.66663C10.3507 2.66663 12.5668 4.86077 12.5668 7.56739C12.5668 8.79077 12.1141 9.90945 11.3654 10.7681L13.2047 12.5892C13.3766 12.7594 13.3766 13.0354 13.2047 13.2056C13.0328 13.3758 12.754 13.3758 12.5821 13.2056L10.7333 11.3752C9.88242 12.0586 8.79797 12.4682 7.61691 12.4682C4.88314 12.4682 2.66699 10.274 2.66699 7.56739Z"
                fill="currentColor"
              />
            </svg>
          </SearchInputIcon>
          <SearchInput placeholder="Search" />
          <SearchInputButton> / </SearchInputButton>
        </Search>
        <Notifications /> */}

        {/* <UserInfo /> */}

        <TenantSwitcher showTitle={true} parent="topMenu" />

        <Hamburger onClick={() => store.AppState.setIsSideMenuOpen()} />
      </StyledMenu>
    </Container>
  )
})
