export const Xmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53999 6.54036C6.79383 6.28652 7.20539 6.28652 7.45923 6.54036L11.9996 11.0807L16.54 6.54036C16.7938 6.28652 17.2054 6.28652 17.4592 6.54036C17.7131 6.7942 17.7131 7.20575 17.4592 7.45959L12.9188 12L17.4592 16.5404C17.7131 16.7942 17.7131 17.2058 17.4592 17.4596C17.2054 17.7134 16.7938 17.7134 16.54 17.4596L11.9996 12.9192L7.45923 17.4596C7.20539 17.7134 6.79383 17.7134 6.53999 17.4596C6.28615 17.2058 6.28615 16.7942 6.53999 16.5404L11.0804 12L6.53999 7.45959C6.28615 7.20575 6.28615 6.7942 6.53999 6.54036Z"
      />
    </svg>
  )
}
