import { styled, createGlobalStyle } from 'styled-components'

export const PopoverGlobalStyle = createGlobalStyle`
/* stylelint-disable */
*[class*='rs-'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*[class*='rs-']::before,
*[class*='rs-']::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.rs-input {
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.textInput.color};
  background-color: ${({ theme }) => theme.textInput.bgColor};
  background-image: none;
  border: ${({ theme }) => theme.textInput.border};
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  border-radius: 3px 0 0 3px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
}

textarea.rs-input {
  height: auto;
}

.rs-input::-webkit-input-placeholder {
  color: #717273;
}
.rs-input::-moz-placeholder {
  color: #717273;
}
.rs-input::-ms-input-placeholder {
  color: #717273;
}
.rs-input::placeholder {
  color: #717273;
}
.rs-input:focus,
.rs-input:hover:not(:disabled) {
  border-color: #3498ff;
}
.rs-input:focus {
  outline: 3px solid rgb(from #3498ff r g b / 25%);
}

.rs-input:disabled {
  background-color: #f7f7fa;
  color: #717273;
}
textarea.rs-input {
  overflow: auto;
  resize: vertical;
}

.rs-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none;
  color: ${({ theme }) => theme.dateInput.popover.button.color};
  background-color: ${({ theme }) => theme.dateInput.popover.button.bgColor};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}
.rs-btn.rs-btn-primary, .rs-btn.rs-btn-primary:hover, .rs-btn.rs-btn-primary:focus {
  width: 100%;
  color: ${({ theme }) => theme.dateInput.popover.button.bgColor};
  background-color: ${({ theme }) => theme.dateInput.popover.button.color};
}
.rs-btn-icon.rs-btn {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
}
.rs-btn:focus-visible {
  outline: 3px solid rgb(from #3498ff r g b / 25%);
}


.rs-btn:hover,
.rs-btn:focus {
  color: ${({ theme }) => theme.dateInput.popover.button.hover.color};
  background-color: ${({ theme }) => theme.dateInput.popover.button.hover.bgColor};
  text-decoration: none;
}
.rs-btn:active,
.rs-btn.rs-btn-active {
  color: ${({ theme }) => theme.dateInput.popover.button.color};
  background-color: ${({ theme }) => theme.dateInput.popover.button.bgColor};
}
.rs-btn:disabled,
.rs-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: #717273;
  background-color: #f7f7fa;
}


@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-btn-start-icon {
  line-height: 0;
  margin-right: 5px;
}
.rs-btn-end-icon {
  line-height: 0;
  margin-left: 5px;
}

.rs-btn-link {
  color: ${({ theme }) => theme.textInput.color};
  background-color: transparent;
  border: none;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
  color: #0a5dc2;
  background-color: transparent;
  text-decoration: underline;
}
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
  color: #004299;
  background-color: transparent;
}
.rs-btn-link:disabled,
.rs-btn-link.rs-btn-disabled {
  color: #0a5dc2;
  background-color: transparent;
  text-decoration: none;
  opacity: 0.3;
}

.rs-btn-block {
  width: 100%;
}
.rs-btn-block + .rs-btn-block {
  margin-top: 5px;
}
.rs-btn-loading {
  color: transparent !important;
  position: relative;
  cursor: default;
  pointer-events: none;
}
.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  z-index: 1;
}

.rs-btn-loading > .rs-btn-spin::before {
  border: 3px solid rgb(from #f7f7fa r g b / 80%);
}


.rs-btn-loading > .rs-btn-spin::after {
  border-width: 3px;
  border-color: #939393 transparent transparent;
  border-style: solid;
  -webkit-animation: buttonSpin 0.6s infinite linear;
          animation: buttonSpin 0.6s infinite linear;
}


@-webkit-keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.rs-input-group {
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  width: 100%;
  cursor: text;
  color: ${({ theme }) => theme.textInput.color};
  border: ${({ theme }) => theme.textInput.border};
}

.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border: ${({ theme }) => theme.textInput.hover.border};
}
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border: ${({ theme }) => theme.textInput.focus.border};
  box-shadow: ${({ theme }) => theme.textInput.focus.boxShadow};
}

.rs-input-group .rs-input-group {
  outline: none !important;
  border-color: transparent !important;
}
.rs-input-group .rs-input-number,
.rs-input-group .rs-input-group-addon,
.rs-input-group .rs-input-group-btn,
.rs-input-group .rs-picker-date .rs-picker-toggle {
  border: none;
  border-radius: 0;
  outline: none;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input {
  border: none;
  border-radius: 0;
  outline: none;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
  border-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
  border-top-right-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
  border-bottom-right-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
  border-radius: 0 !important;
}
.rs-input-group:not(.rs-input-group-inside) > :first-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.rs-input-group:not(.rs-input-group-inside) > :last-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-input-group > .rs-input,
.rs-input-group > .rs-form-control-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.rs-input-group .rs-form-control-wrapper > .rs-input {
  width: 100%;
}
.rs-input-group > .rs-input {
  position: relative;
}
.rs-input-group input.rs-input ~ .rs-input-group-addon,
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
  border-left: none;
  left: auto;
  right: 0;
}
.rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child),
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child) {
  border-right: 0;
}
.rs-input-group.rs-input-group-inside {
  width: 100%;
  background-color: #fff;
}
.rs-input-group.rs-input-group-inside .rs-input {
  display: block;
  width: 100%;
  border: none;
  outline: none;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn {
  color: #343434;
  background-color: transparent;
  border: none;
  color: inherit;
  height: 34px;
  padding: 7px 11px;
  border-radius: 0 6px 6px 0;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
  color: #343434;
  background-color: #e5e5ea;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:active,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active {
  color: yellow; //TODO
  background-color: #e5e5ea;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
  color: #b6b7b8;
  background: none;
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
  outline: none;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  top: 0;
  background: ${({ theme }) => theme.textInput.bgColor};
  border: none;
  padding: 10px 12px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-addon.rs-input-group-btn {
  padding: 8px 13px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input {
  padding-left: 0;
  padding-right: 12px;
}
.rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
  left: inherit;
  right: 0;
}
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
  left: inherit;
  right: 1px;
}
.rs-input-group.rs-input-group-disabled {
  background-color: #f7f7fa;
  color: #717273;
  cursor: not-allowed;
}
.rs-input-group.rs-input-group-disabled .rs-input,
.rs-input-group.rs-input-group-disabled .rs-input-group-btn,
.rs-input-group.rs-input-group-disabled .rs-input-group-addon {
  color: inherit;
}

.rs-input-group-addon:not(:first-child):not(:last-child),
.rs-input-group-btn:not(:first-child):not(:last-child),
.rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rs-input-group-addon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  color: ${({ theme }) => theme.textInput.color};
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  background-color: ${({ theme }) => theme.textInput.bgColor};
  border: ${({ theme }) => theme.textInput.border};
  border-radius: 0 3px 3px 0 !important;
}

.rs-input-group-addon input[type='radio'],
.rs-input-group-addon input[type='checkbox'] {
  margin-top: 0;
}
.rs-input-group-btn {
  position: relative;
  line-height: 1;
  white-space: nowrap;
  border-radius: 0;
}
.rs-input-group-btn > .rs-btn {
  position: relative;
}
.rs-input-group-btn > .rs-btn + .rs-btn {
  margin-left: -1px;
}
.rs-input-group-btn:first-child > .rs-btn,
.rs-input-group-btn:first-child > .rs-btn-group {
  margin-right: -1px;
  border-right: medium none;
}
.rs-input-group-btn:last-child > .rs-btn,
.rs-input-group-btn:last-child > .rs-btn-group {
  margin-left: -1px;
}

.rs-calendar {
  padding-top: 10px;
  position: relative;
  min-height: 266px;
  overflow: hidden;
}
.rs-calendar-bordered .rs-calendar-table {
  border: ${({ theme }) => theme.textInput.border};
  border-radius: 4px;
}
.rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-header-cell {
  border-bottom: 1px solid #f2f2f5;
}
.rs-calendar-bordered .rs-calendar-month-dropdown {
  border: ${({ theme }) => theme.textInput.border};
  border-radius: 4px;
}
.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 6px;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
  float: none !important;
}
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-backward {
  display: inline-block;
  visibility: hidden;
  pointer-events: none;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important;
}
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-month-toolbar {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-month-view {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-header-cell-content {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: inherit;
  color: inherit;
  -webkit-box-shadow: inset 0 0 0 1px #3498ff;
          box-shadow: inset 0 0 0 1px #3498ff;
}
.rs-calendar-panel .rs-calendar-table-cell-day {
  display: block;
  text-align: center;
}
.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  width: calc(100% - 20px);
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top;
}
.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 1px;
  line-height: 0;
}
.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  min-height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.rs-calendar-btn-close {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 14px;
  padding: 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: ${({ theme }) => theme.dateInput.popover.button.bgColor};
  z-index: 1;
}
.rs-calendar-btn-close:hover {
  background-color: ${({ theme }) => theme.dateInput.popover.button.hover.bgColor};
}
.rs-calendar-panel .rs-calendar-btn-close {
  bottom: 4px;
  margin: 0 11px;
  border-radius: 0 0 6px 6px;
}
.rs-calendar-panel.rs-calendar-bordered .rs-calendar-btn-close {
  bottom: 5px;
}
.rs-calendar-month-view .rs-calendar-header-month-toolbar {
  padding-left: 24px;
  padding-right: 24px;
}
.rs-calendar-month-view .rs-calendar-header-backward,
.rs-calendar-month-view .rs-calendar-header-forward {
  display: none;
}
.rs-calendar-month-view .rs-calendar-header-title-date {
  color: ${({ theme }) => theme.textInput.color};
  background: transparent;
}
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
  color: #f44336;
}
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #fff !important;
}
.rs-calendar-time-view .rs-calendar-header-title-time {
  color: ${({ theme }) => theme.textInput.color};
  background: transparent;
}
.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error {
  color: #f44336;
}
.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
  color: #fff;
}
.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}
.rs-calendar-table-cell-in-range::before {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
}
.rs-calendar-table-cell-in-range:hover::before {
  display: none;
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}
.rs-calendar-header {
  width: 280px;
  padding-left: 10px;
  padding-right: 10px;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block;
}
.rs-calendar-header-month-toolbar {
  float: left;
}
.rs-calendar-header-time-toolbar {
  float: right;
}
.rs-calendar-header-title {
  margin: 0 4px;
}
.rs-calendar-header-meridiem {
  font-size: 12px;
  margin-left: 4px;
}
.rs-calendar-header-error {
  color: #343434;
  background-color: transparent;
  border: none;
}
.rs-calendar-header-error:hover,
.rs-calendar-header-error:focus {
  color: #343434;
  background-color: #e5e5ea;
}
.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active {
  color: #121212;
  background-color: #e5e5ea;
}
.rs-calendar-header-error:disabled,
.rs-calendar-header-error.rs-btn-disabled {
  color: #b6b7b8;
  background: none;
}

.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}
.rs-calendar-header-btn-disabled::after {
  display: none !important;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: right;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block;
}
.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}
/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir='rtl'] .rs-calendar-header-backward .rs-icon,
[dir='rtl'] .rs-calendar-header-forward .rs-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
/* rtl:end:ignore */
.rs-calendar-body {
  padding: 4px 10px 10px;
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.rs-calendar-table-row {
  display: table-row;
}
.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  display: table-cell;
  width: 1%;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
  color: ${({ theme }) => theme.dateInput.popover.tableCell.color};
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
  color: #717273;
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  -webkit-box-shadow: none;
          box-shadow: none;
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content {
  opacity: 0.3;
}
.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}
.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.color};
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.bgColor};
  -webkit-box-shadow: none;
          box-shadow: none;
}

.rs-calendar-table-header-cell-content {
  display: inline-block;
  color: ${({ theme }) => theme.dateInput.popover.tableCell.color};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-content:focus,
.rs-calendar-table-header-cell-content:hover:focus {
  background: none;
  cursor: auto;
}
.rs-calendar-table-cell-week-number {
  display: table-cell;
  min-width: 30px;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  color: #717273;
  background-color: #f7f7fa;
  font-size: 12px;
}
.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  border-top: ${({ theme }) => theme.textInput.border};
  margin-top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.dateInput.popover.backgroundColor};
  overflow: hidden;
  z-index: 1;
  height: calc(100% - 48px);
}
.rs-calendar-month-view .rs-calendar-month-dropdown {
  display: block;
}
.rs-calendar-month-view .rs-calendar-month-dropdown-scroll {
  -webkit-animation: 0.3s linear slideDown;
          animation: 0.3s linear slideDown;
}
.rs-calendar-month-dropdown-scroll {
  height: 100%;
}
.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 48px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dashed #e5e5ea;
}
.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 10px;
}
.rs-calendar-month-dropdown-year-active {
  color: ${({ theme }) => theme.dateInput.popover.headerColor};
}
.rs-calendar-month-dropdown-list {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}
.rs-calendar-month-dropdown-cell {
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}

@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  color: ${({ theme }) => theme.dateInput.popover.tableCell.color};
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
}
.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  color: #fff;
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.bgColor};
  -webkit-box-shadow: none;
          box-shadow: none;
}

.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.textInput.bgColor};
  color: ${({ theme }) => theme.textInput.color};
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-time-view .rs-calendar-time-dropdown {
  display: block;
}
.rs-calendar-time-view .rs-calendar-time-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
          animation: 0.3s linear slideDown;
}
.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}
.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;
}
.rs-calendar-time-dropdown-column ::after {
  content: '';
  display: block;
  height: calc(100% - 30px);
}
.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-calendar-time-dropdown-column > ul {
  height: 230px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.rs-calendar-time-dropdown-column > ul > li {
  height: fit-content;
}

.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.textInput.color};
  text-align: center;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 2px;
}
.rs-calendar-only-time .rs-calendar-time-dropdown-column-title {
  border-top: none;
}
.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  padding: 5px;
  display: block;
  text-align: center;
  cursor: pointer;
  margin: 1px;
}
.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none;
}
.rs-calendar-time-dropdown-cell:hover {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
}


.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.color};
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
  color: #717273;
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-calendar-only-time {
  padding-top: 10px;
  display: block;
}
.rs-calendar-only-time .rs-calendar-time-dropdown {
  position: relative;
  top: 2px;
}
.rs-calendar-only-time .rs-calendar-time-dropdown-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-calendar-only-time .rs-calendar-time-dropdown-column {
  min-width: 60px;
}
@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.rs-btn-close {
  border: none;
  outline: none !important;
  background: transparent;
  line-height: 1;
  cursor: pointer;
  color: ${({ theme }) => theme.textInput.iconColor};
}
.rs-btn-close .rs-icon {
  vertical-align: bottom;
}
.rs-btn-close:hover,
.rs-btn-close:focus {
  color: #f44336;
}
.rs-btn-close:hover svg path,
.rs-btn-close:focus svg path {
  stroke: #f44336;
  stroke-width: 1;
}
.rs-loader {
  display: inline-block;
}
.rs-loader::before,
.rs-loader::after {
  content: ' ';
  display: table;
}
.rs-loader::after {
  clear: both;
}
.rs-loader .rs-loader-spin {
  width: 18px;
  height: 18px;
}
.rs-loader .rs-loader-spin::before,
.rs-loader .rs-loader-spin::after {
  width: 18px;
  height: 18px;
}
.rs-loader .rs-loader-content {
  font-size: 12px;
  line-height: 1.5;
}
.rs-loader .rs-loader-vertical .rs-loader .rs-loader {
  height: 48px;
}
.rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
  line-height: 1.66666667;
}
.rs-loader-spin {
  position: relative;
  display: inline-block;
  float: left;
}
.rs-loader-spin::before,
.rs-loader-spin::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}
.rs-loader-spin::before {
  border: 3px solid rgb(from #f7f7fa r g b / 80%);
}
.rs-loader-spin::after {
  border-width: 3px;
  border-style: solid;
  border-color: #939393 transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
          animation: loaderSpin 0.6s infinite linear;
}
.rs-loader-content {
  float: left;
  display: inline-block;
}
.rs-loader-spin + .rs-loader-content {
  margin-left: 12px;
}

.rs-loader-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(from #fff r g b / 90%);
}
.rs-loader-vertical {
  width: auto;
  display: inline-block;
}
.rs-loader-vertical .rs-loader {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.rs-loader-vertical .rs-loader-spin {
  margin: 0 auto;
}
.rs-loader-vertical .rs-loader-content {
  line-height: 1.66666667;
  text-align: center;
}
.rs-loader-vertical .rs-loader-spin,
.rs-loader-vertical .rs-loader-content {
  display: block;
  float: none;
}
.rs-loader-vertical .rs-loader-spin + .rs-loader-content {
  margin-left: 0;
  margin-top: 10px;
}
.rs-loader-inverse .rs-loader-content {
  color: #f7f7fa;
}
.rs-loader-inverse .rs-loader-backdrop {
  background: rgb(from #121212 r g b / 83%);
}
.rs-loader-inverse .rs-loader-spin::before {
  border-color: rgb(from #f7f7fa r g b / 30%);
}
.rs-loader-inverse .rs-loader-spin::after {
  border-top-color: #fff;
}
.rs-loader-speed-fast .rs-loader-spin::after {
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}
.rs-loader-speed-normal .rs-loader-spin::after {
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
}
.rs-loader-speed-slow .rs-loader-spin::after {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
}
.rs-loader-speed-paused .rs-loader-spin::after {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rs-loader-center,
.rs-loader-backdrop-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}
.rs-loader-center .rs-loader,
.rs-loader-backdrop-wrapper .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
}
.rs-loader-center:not(.rs-loader-vertical) .rs-loader,
.rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-loader-center.rs-loader-vertical .rs-loader,
.rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
}
.rs-loader-center .rs-loader-content,
.rs-loader-backdrop-wrapper .rs-loader-content {
  z-index: 1;
}

.rs-loader-wrapper {
  display: inline-block;
  width: auto;
  line-height: 0;
}
@-webkit-keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.rs-highlight-mark {
  background-color: #fff6c9;
  padding: 0 0.2em;
  font-weight: bolder;
  border-radius: 4px;
}
.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-picker-block {
  display: block;
}
.rs-picker-disabled {
  cursor: not-allowed;
}
.rs-picker-disabled .rs-picker-toggle-value,
.rs-picker-disabled .rs-picker-toggle-indicator,
.rs-picker-disabled .rs-picker-tag-list .rs-tag {
  color: #717273 !important;
}
.rs-picker-disabled .rs-picker-value-count {
  opacity: 0.5;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #717273;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: ${({ theme }) => theme.textInput.color};
}
.rs-picker-none {
  padding: 6px 12px 12px;
  color: #717273;
  cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-picker-value-list {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rs-picker-value-count {
  margin: 0 4px;
  background-color: #3498ff;
  color: #fff;
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}
.rs-picker-value-separator {
  margin: 0 4px 0 0;
}
.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: #3498ff;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff r g b / 25%);
          box-shadow: 0 0 0 3px rgb(from #3498ff r g b / 25%);
}
.rs-picker-label.rs-input-group-addon {
  color: #343434;
  padding-right: 4px !important;
}
.rs-picker-loader {
  width: 14px;
  height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rs-picker-error > .rs-picker-input-group {
  border-color: #f44336;
}
.rs-picker-error > .rs-picker-input-group:hover {
  border-color: #f44336;
}
.rs-picker-error > .rs-picker-input-group:focus-within {
  border-color: #f44336;
  outline: 3px solid rgb(from #f44336 r g b / 25%);
}
.rs-picker-toggle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none;
  color: papayawhip; //TODO
  background-color: #f7f7fa;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}

.rs-btn-icon.rs-picker-toggle {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
}
.rs-picker-toggle:focus-visible {
  outline: 3px solid rgb(from #3498ff r g b / 25%);
}

.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: green;//TODO
  background-color: #e5e5ea;
  text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  color: rebeccapurple; //TODO
  background-color: #d9d9d9;
}
.rs-picker-toggle:disabled,
.rs-picker-toggle.rs-btn-disabled {
  cursor: not-allowed;
  color: #717273;
  background-color: #f7f7fa;
}

@media not all and (min-resolution: 0.001dpcm) {
  .rs-picker-toggle {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}
.rs-picker-toggle-textbox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #0000;
  padding-left: 10px;
  padding-right: 32px;
  color: #343434;
  background-color: #fff;
  outline: none;
}

.rs-picker-toggle-read-only {
  opacity: 0;
}
.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #3498ff;
}

/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
  top: 8px;
  right: 12px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon {
  top: 8px;
  right: 12px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* stylelint-disable-next-line */
.rs-picker-toggle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  color: #343434;
  border: ${({ theme }) => theme.textInput.border};
}
.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: #3498ff;
}

.rs-picker-toggle.rs-btn {
  width: 100%;
  text-align: left;
}
.rs-picker-toggle-label {
  color: #343434;
}
.rs-picker-toggle-label::after {
  content: ':';
  margin: 0 4px 0 2px;
}
.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px;
}
.rs-picker-toggle-indicator .rs-picker-clean {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: #717273;
  -webkit-transition: 0.2s color linear;
  transition: 0.2s color linear;
  cursor: pointer;
}
.rs-picker-toggle-indicator .rs-picker-clean.rs-btn-close {
  padding: 4px 0;
}
.rs-picker-date .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle-indicator .rs-picker-clean {
  right: 38px;
}
.rs-picker-toggle-indicator .rs-picker-caret-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
  font-size: 16px;
  color: #717273;
}
.rs-picker-toggle-indicator .rs-picker-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
}
.rs-picker-popup {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.textInput.bgColor};
  border: ${({ theme }) => theme.dateInput.popover.border};
  box-shadow: ${({ theme }) => theme.dateInput.popover.boxShadow};
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 6px 0;
  margin-top: 5px;
}

.rs-modal-open .rs-picker-popup {
  z-index: 1055;
}
.rs-drawer-open .rs-picker-popup {
  z-index: 1055;
}
.rs-picker-popup.rs-picker-inline {
  position: relative;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.rs-picker-popup .rs-search-box {
  padding: 6px 12px 6px;
}
.rs-picker-popup .rs-highlight .rs-highlight-mark {
  padding: 0;
}
.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: #fff !important;
}
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background-color: #f7f7fa !important;
}
.rs-picker-default.rs-picker-disabled .rs-input-group,
.rs-picker-input.rs-picker-disabled .rs-input-group {
  pointer-events: none;
}
.rs-check-item {
  display: block;
}
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item:focus,
.rs-check-item.rs-check-item-focus {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
}


.rs-check-item .rs-checkbox-checker {
  padding: 0;
}
.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 38px;
  line-height: 20px;
}

.rs-check-item .rs-checkbox-checker > label::before {
  background-color: #fff;
}

.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed;
}
.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 52px;
}
.rs-check-item .rs-checkbox-checker .rs-checkbox-control {
  left: 12px;
}
.grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-control,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker .rs-checkbox-control {
  left: 26px;
}
.rs-picker-menu-group {
  background-color: #fff;
  position: sticky;
  top: -1px;
  z-index: 1;
}
.rs-virt-list .rs-picker-select-menu-item,
.rs-virt-list .rs-check-item label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-tree-virt-list .rs-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-stack-item {
  width: 100%;
}
.rs-picker-toolbar {
  padding: 10px;
  border-top: ${({ theme }) => theme.textInput.border};
}
.rs-picker-date-inline {
  height: 299px;
}
.rs-picker-date-inline .rs-calendar {
  height: 286px;
}
.rs-picker-date .rs-input-group-addon {
  color: ${({ theme }) => theme.textInput.color};
  cursor: pointer;
}
.rs-picker-date .rs-input-group-addon .rs-btn-close {
  padding: 0;
}

.rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input {
  padding-right: 0;
}
.rs-picker-date-predefined {
  height: 325px;
  border-right: ${({ theme }) => theme.textInput.border};
  padding: 4px 0;
}
.rs-picker-date-predefined .rs-btn {
  display: block;
}
.rs-picker-popup.rs-picker-popup-date {
  padding: 0;
}
.rs-picker-popup .rs-picker-toolbar {
  flex-direction: column;
  max-width: 100%;
}
.rs-picker-popup .rs-picker-toolbar-ranges {
  max-width: 400px;
}
.rs-picker-popup .rs-calendar {
  min-width: 264px;
  display: block;
  margin: 0 auto;
}
.rs-picker-popup .rs-calendar-show-week-numbers {
  min-width: 278px;
}
.rs-picker-popup .rs-calendar-show-week-numbers .rs-calendar-body {
  padding-left: 12px;
  padding-right: 12px;
}
.rs-picker-popup .rs-calendar-header {
  width: 100%;
}
.rs-picker-popup .rs-calendar-body {
  padding-left: 15px;
  padding-right: 15px;
}
.rs-picker-popup .rs-calendar-table {
  width: unset;
}
.rs-picker-popup .rs-calendar-month-dropdown-cell:focus-visible .rs-calendar-month-dropdown-cell-content {
  outline: 3px solid rgb(from #3498ff r g b / 25%);
}
.rs-picker-popup .rs-calendar-table-cell:focus-visible {
  outline: none;
}
.rs-picker-popup .rs-calendar-table-cell:focus-visible .rs-calendar-table-cell-content {
  outline: 3px solid rgb(from #3498ff r g b / 25%);
}
.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
}
.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
}

.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
  background-color: ${({ theme }) => theme.textInput.color};
  color: #fff;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0;
}
.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}
.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.hover.color};
}


.rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  background-color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.bgColor};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.selected.color};
}

.rs-picker-popup .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 198px;
}
.rs-picker-popup .rs-calendar.rs-calendar-only-time {
  min-width: auto;
}
.rs-picker-popup .rs-calendar.rs-calendar-only-time .rs-calendar-time-dropdown-column > ul {
  height: 240px;
}

/*# sourceMappingURL=index.css.map */

`

export const Container = styled.div`
  width: 100%;
  height: 40px;
`
