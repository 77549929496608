export const Transactions = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86677 12.5574C6.86677 12.2043 7.15495 11.918 7.51045 11.918H8.63278C8.98828 11.918 9.27647 12.2043 9.27647 12.5574C9.27647 12.9105 8.98828 13.1967 8.63278 13.1967H7.51045C7.15495 13.1967 6.86677 12.9105 6.86677 12.5574Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86677 15.9016C6.86677 15.5485 7.15495 15.2623 7.51045 15.2623H8.63278C8.98828 15.2623 9.27647 15.5485 9.27647 15.9016C9.27647 16.2547 8.98828 16.541 8.63278 16.541H7.51045C7.15495 16.541 6.86677 16.2547 6.86677 15.9016Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2338 12.5574C10.2338 12.2043 10.5219 11.918 10.8774 11.918H16.4891C16.8446 11.918 17.1328 12.2043 17.1328 12.5574C17.1328 12.9105 16.8446 13.1967 16.4891 13.1967H10.8774C10.5219 13.1967 10.2338 12.9105 10.2338 12.5574Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2338 15.9016C10.2338 15.5485 10.5219 15.2623 10.8774 15.2623H16.4891C16.8446 15.2623 17.1328 15.5485 17.1328 15.9016C17.1328 16.2547 16.8446 16.541 16.4891 16.541H10.8774C10.5219 16.541 10.2338 16.2547 10.2338 15.9016Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 8.09836C3.5 5.28261 5.79813 3 8.63301 3H15.367C18.2019 3 20.5 5.28261 20.5 8.09836V15.9016C20.5 18.7174 18.2019 21 15.367 21H8.63301C5.79813 21 3.5 18.7174 3.5 15.9016V8.09836ZM8.63301 4.27869C6.50913 4.27869 4.78738 5.98881 4.78738 8.09836V15.9016C4.78738 18.0112 6.50913 19.7213 8.63301 19.7213H15.367C17.4909 19.7213 19.2126 18.0112 19.2126 15.9016V8.73771H16.4891C15.5137 8.73771 14.7231 7.95237 14.7231 6.98361L14.7233 4.27869H8.63301ZM16.0106 4.33195L16.0105 6.98361C16.0105 7.24615 16.2248 7.45902 16.4891 7.45902H19.159C18.888 5.86172 17.6188 4.6011 16.0106 4.33195Z"
      />
    </svg>
  )
}
