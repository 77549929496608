import { FC } from 'react'
import { Number, ObjectSubtree, String, SubTreeObject, SubTreeRow } from '../Components.styles'
import { StringValue } from './ValueTypes/StringValue'

interface RecursiveObjectRendererProps {
  data: { [key: string]: {} | string | number | boolean }
  propertyKey?: string
  isFirstLoop?: boolean
}

const ResolveValueType: FC<{ value: any }> = ({ value }) => {
  const type = typeof value

  switch (type) {
    case 'string':
      return <StringValue value={value.toString()} />
    case 'number':
      return <Number>{value.toString()}</Number>
    case 'boolean':
      return <Boolean>{value.toString()}</Boolean>

    default:
      return <></>
  }
}

export const RecursiveObjectRenderer: FC<RecursiveObjectRendererProps> = ({
  data,
  propertyKey,
  isFirstLoop,
}) => {
  propertyKey && console.log('propertyKey', propertyKey)

  try {
    const type = typeof data

    if (type === 'object' && data !== null) {
      return (
        <ObjectSubtree>
          <span>{propertyKey}</span>

          {Object.entries(data).map(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
              return (
                <SubTreeObject key={key}>
                  <span>{key}</span>
                  <RecursiveObjectRenderer data={value as {}} />
                </SubTreeObject>
              )
            } else {
              return (
                <SubTreeRow>
                  <span>
                    {key}
                    {':'}
                  </span>
                  <ResolveValueType value={value} />
                </SubTreeRow>
              )
            }
          })}
        </ObjectSubtree>
      )
    } else {
      return (
        <SubTreeRow $isFirstLoop={isFirstLoop}>
          <span>
            {propertyKey}
            {':'}
          </span>
          <ResolveValueType value={data} />
        </SubTreeRow>
      )
    }
  } catch (error) {
    return <></>
  }
}
