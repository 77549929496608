import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const StyledTextInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  --gap: 8px;
  gap: var(--gap);
  margin-bottom: calc(-1 * var(--gap));
`

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.textInput.labelColor};
`

export const StyledInput = styled.input`
  width: 100%;
  min-width: 114px;
  height: 40px;
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 4px;
  border: ${({ theme }) => theme.textInput.border};
  background-color: ${({ theme }) => theme.textInput.bgColor};
  color: ${({ theme }) => theme.textInput.color};

  &:hover {
    border: ${({ theme }) => theme.textInput.hover.border};
  }

  &:focus {
    border: ${({ theme }) => theme.textInput.focus.border};
    box-shadow: ${({ theme }) => theme.textInput.focus.boxShadow};
  }

  &.error {
    border: ${({ theme }) => theme.textInput.error.border};
    box-shadow: ${({ theme }) => theme.textInput.error.boxShadow};
  }

  &:disabled {
    border: ${({ theme }) => theme.textInput.disabled.border};
    background-color: ${({ theme }) => theme.textInput.disabled.bgColor};
    color: ${({ theme }) => theme.textInput.disabled.color};
  }

  &:read-only {
    border: ${({ theme }) => theme.textInput.readOnly.border};
    background-color: ${({ theme }) => theme.textInput.readOnly.bgColor};
  }

  ${Animatable}
`
