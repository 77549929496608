import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import useClickOutside from '@hooks/useClickOutside'
import {
  Close,
  Container,
  FullscreenWrap,
  PopupHeader,
  PopupBody,
  TRANSITION_DURATION,
} from './SidebarPopup.styles'
import { Icon } from '@components/Icon/Icon'

interface SidebarPopupProps {
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
  handleVisibility?: () => void
  visibility: boolean
  width?: string
}

export const SidebarPopup: FC<SidebarPopupProps> = ({
  children,
  header,
  handleVisibility,
  visibility,
  width,
}) => {
  const popup = useRef()

  const closeHandler = useCallback(() => handleVisibility(), [])
  useClickOutside(popup, closeHandler)

  // Transition workaround
  const [currentWidth, setCurrentWidth] = useState('0')
  const [currentOpacity, setCurrentOpacity] = useState(0)
  const [currentVisibility, setCurrentVisibility] = useState(visibility)

  useEffect(() => {
    if (visibility) {
      setCurrentVisibility(visibility)
      setTimeout(() => setCurrentWidth(width), 10)
      setTimeout(() => setCurrentOpacity(1), 10)
    } else {
      setCurrentWidth('0')
      setCurrentOpacity(0)
      setTimeout(() => setCurrentVisibility(visibility), TRANSITION_DURATION)
    }
  }, [width, visibility])

  return currentVisibility ? (
    <FullscreenWrap opacity={currentOpacity}>
      <Container ref={popup} width={currentWidth}>
        <Close onClick={closeHandler}>
          <Icon size="24px" type="x-mark" />
        </Close>

        <PopupHeader>{header}</PopupHeader>

        <PopupBody>{children}</PopupBody>
      </Container>
    </FullscreenWrap>
  ) : (
    <></>
  )
}
