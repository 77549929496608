import { CSSProperties, FC, PropsWithChildren } from 'react'
import { StyledTextInput, StyledLabel, StyledInput } from './TextInput.styles'

type Props = {
  type?: 'text' | 'url' | 'email'
  defaultValue?: string
  value?: string
  name?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  onChange?: (number) => void
  placeholder?: string
  label?: string
  style?: CSSProperties
}

export const TextInput: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledTextInput>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}
      <StyledInput
        type={props?.type || 'text'}
        defaultValue={props.defaultValue}
        value={props.value}
        name={props.name}
        disabled={!!props.disabled}
        required={!!props.required}
        readOnly={!!props.readOnly}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        style={props.style}
      >
        {props.children}
      </StyledInput>
    </StyledTextInput>
  )
}
