import { FC, useContext, useEffect, useState } from 'react'
import { Container, DynamicFlexWrap, InputWrapper, ResetButton } from './Filters.styles'
import { DatePickerInput } from '@/components/Generic/DatePicker/DatePicker'
import { SelectInput, TextInput } from '@/components/Generic'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'
import { SelectOption } from '@/types/generic'
import { statusOptions, StatusValue } from '@/store/TransactionListFilter.store'
import { RadioInput } from '@/components/Generic/Radio/Radio'
import { RadioList } from '@/components/Generic/Radio/Radio.styles'
import { StatusInput } from './StatusInput'
import { DateValue } from 'react-aria-components'
import { TagsInput } from './TagsInput'

export const Filters: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionsPage: trans } = store.TranslationsState.translations
  const {
    transactionId,
    configurationId,
    userRef,
    status,
    transactionStart,
    transactionEnd,
    fetchedTags,
    tags,
  } = store.TransactionListFilterState
  const { transactionListFiltersPosition } = store.Settings

  const [debugDate, setDebugDate] = useState<number>()

  useEffect(() => {
    console.log('debugDate', debugDate)
  }, [debugDate])

  return (
    <Container $filterPosition={transactionListFiltersPosition}>
      <DynamicFlexWrap $filterPosition={transactionListFiltersPosition}>
        <TextInput
          style={{ gridArea: 'transactionId' }}
          label={trans.transactionId}
          name="transactionId"
          value={transactionId}
          onChange={(value) => store.TransactionListFilterState.setTransactionId(value)}
        />
        <TextInput
          style={{ gridArea: 'configurationId' }}
          label={trans.configurationId}
          name="configurationId"
          value={configurationId}
          onChange={(value) => store.TransactionListFilterState.setConfigurationId(value)}
        />
        <TextInput
          style={{ gridArea: 'userRef' }}
          label={trans.externalReferenceId}
          name="userRef"
          value={userRef}
          onChange={(value) => store.TransactionListFilterState.setUserRef(value)}
        />
        <ResetButton title="reset" style={{ gridArea: 'reset' }}>
          <svg width="20" height="20" viewBox="0 0 20 20">
            <path
              d="M5.45015 5.4504C5.66169 5.23886 6.00465 5.23886 6.21619 5.4504L9.99984 9.23405L13.7835 5.4504C13.995 5.23886 14.338 5.23886 14.5495 5.4504C14.7611 5.66193 14.7611 6.0049 14.5495 6.21643L10.7659 10.0001L14.5495 13.7837C14.7611 13.9953 14.7611 14.3382 14.5495 14.5498C14.338 14.7613 13.995 14.7613 13.7835 14.5498L9.99984 10.7661L6.21619 14.5498C6.00465 14.7613 5.66169 14.7613 5.45015 14.5498C5.23862 14.3382 5.23862 13.9953 5.45015 13.7837L9.2338 10.0001L5.45015 6.21643C5.23862 6.0049 5.23862 5.66193 5.45015 5.4504Z"
              fill="currentColor"
            />
          </svg>
        </ResetButton>
      </DynamicFlexWrap>

      <DynamicFlexWrap $filterPosition={transactionListFiltersPosition}>
        <StatusInput />

        <TagsInput />

        <InputWrapper style={{ gridArea: 'startDate' }}>
          <label>{trans.startDate}</label>
          <DatePickerInput
            name="transactionStart"
            isTimeIncluded
            // onChange={(e) => {
            //   setDebugDate(e)
            // }}
            value={transactionStart}
            onChange={(date) => store.TransactionListFilterState.setTransactionStart(date)}
          />
        </InputWrapper>

        <InputWrapper style={{ gridArea: 'endDate' }}>
          <label>{trans.endDate}</label>
          <DatePickerInput
            name="transactionEnd"
            isTimeIncluded
            value={transactionEnd}
            onChange={(date) => store.TransactionListFilterState.setTransactionEnd(date)}
          />
        </InputWrapper>
      </DynamicFlexWrap>
    </Container>
  )
})
