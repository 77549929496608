import { StyledInput } from '@/components/Generic/TextInput/TextInput.styles'
import { styled } from 'styled-components'

export const Content = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`

export const StyledLogoSection = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  background-color: ${({ theme }) => theme.loginPage.bgColor};
  padding: 84px 0 0 100px;
`

export const StyledIlustration = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  background-color: ${({ theme }) => theme.loginPage.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 90%;
  }
`

export const StyledFormSection = styled.div`
  grid-area: 1 / 2 / 3 / 3;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 35px;
  width: 100%;
  max-width: 600px;
  margin: 178px auto;
  padding: 0 64px;
`

export const StyledSignicatLogo = styled.div`
  margin-bottom: 61px;
`

export const StyledTitleSection = styled.div<{ $gap?: string; width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ width }) => width && width};
  gap: ${({ $gap }) => $gap && $gap};
  position: relative;

  svg {
    position: absolute;
    top: 49px;
    right: 74px;
    z-index: 0;
  }

  h2 {
    color: ${({ theme }) => theme.loginPage.header.secondaryColor};
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    z-index: 1;
  }

  h3 {
    color: ${({ theme }) => theme.loginPage.header.primaryColor};
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  h4 {
    color: ${({ theme }) => theme.loginPage.text.secondaryColor};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }

  p {
    color: ${({ theme }) => theme.loginPage.text.secondaryColor};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    height: 35px !important;
  }
`

export const WarningMessage = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  color: #25292c;
  background-color: #ff7070;
  text-align: center;
  border-radius: 4px;

  svg {
    fill: #941616;
  }
`
