import { FC } from 'react'
import { ValueButton } from '../../ValueButton'
import { URL, String } from '../../Components.styles'

interface StringValueProps {
  value: string
}

const getTypeOfString = (value: string) => {
  if (value.startsWith('http')) return 'url'
  return 'string'
}

export const StringValue: FC<StringValueProps> = ({ value }) => {
  const type = getTypeOfString(value)

  switch (type) {
    case 'url':
      return (
        <URL>
          <ValueButton action="open" actionData={value} />
          <ValueButton action="copy" actionData={value} />
        </URL>
      )

    default:
      return <String>{value}</String>
  }
}
