import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from '@components/App'
import { replaceConsoleLogMethod } from './methods/consoleLog'

const container = document.getElementById('root')
const root = createRoot(container!)

replaceConsoleLogMethod()

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
