import { TopMenu } from '@/components/TopMenu/TopMenu'
import { Sidebar } from '@/components/Sidebar/Sidebar'
import { FC, ReactNode, useContext } from 'react'
import { styled } from 'styled-components'
import { StoreContext } from '@/components/App'
import { observer } from 'mobx-react'

const LayoutContainer = styled.div<{ $isSideMenuOpen?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isSideMenuOpen }) => ($isSideMenuOpen ? '264px' : '82px')} 1fr;
  grid-template-rows: 64px 1fr;
  grid-template-areas:
    'top-menu top-menu'
    'sidebar content';
  flex-direction: column-reverse;
  align-self: flex-start;
  font-family: 'Inter';

  transition: grid-template-columns 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    display: block;
    position: relative;
  }
`

export const PageWrapper = styled.div`
  grid-area: content;
  width: 100%;
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
`

interface DefaultLayoutProps {
  children: ReactNode
}

export const DefaultLayout: FC<DefaultLayoutProps> = observer(({ children }) => {
  const store = useContext(StoreContext)
  const { isSideMenuOpen } = store.AppState

  return (
    <LayoutContainer className="default-layout" $isSideMenuOpen={isSideMenuOpen}>
      <TopMenu />
      <Sidebar />

      <PageWrapper>{children}</PageWrapper>

      {/* Mobile Menu placement: */}
    </LayoutContainer>
  )
})
