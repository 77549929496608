import { FC, useEffect, useRef, useState } from 'react'
import {
  Container,
  FlagEmoji,
  IconWrapper,
  PhoneNumberInput,
  PrefixButton,
  PrefixLabel,
} from './PhoneInput.styles'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import { countryPrefixes } from './countryPrefixes'
import { CountryPrefixSelect } from './CountryPrefixSelect'
import { createPortal } from 'react-dom'
import { PhoneInputProps, CountryPrefixItem } from './phoneInput.d'

const isNumberValid = (value?: string) => {
  if (!value) return false

  return value?.length > 6
}

const parsePhoneNumber = (value: string) => {
  return value.replace(/\D/g, '')
}

export const PhoneInput: FC<PhoneInputProps> = ({ value, onChange }) => {
  polyfillCountryFlagEmojis()
  const containerRef = useRef<HTMLDivElement>()
  const [containerBonds, setContainerBonds] = useState({ width: 0, height: 0, x: 0, y: 0 })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const openDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
      return
    }

    if (!containerRef?.current) return
    const { width, height, x, y } = containerRef.current.getBoundingClientRect()
    setContainerBonds({ width, height, x, y })

    console.log('openDropdown', containerBonds)
    setIsDropdownOpen(true)
  }

  const [prefixObject, setPrefixObject] = useState<CountryPrefixItem>(
    value?.prefix
      ? countryPrefixes.find((prefix) => prefix.dial_code === value?.prefix)
      : countryPrefixes[0]
  )
  const [number, setNumber] = useState(value?.number || '')
  const [isValid, setIsValid] = useState(isNumberValid(value?.number))

  useEffect(() => {
    onChange &&
      onChange({
        prefix: prefixObject?.dial_code || '',
        number,
        isValid,
        fullNumber: `${prefixObject?.dial_code}${number}`,
      })
  }, [prefixObject, number, isValid])

  const handlePhoneNumberChange = (value: string) => {
    const parsedNumber = parsePhoneNumber(value)
    setNumber(parsedNumber)
    const isValid = isNumberValid(parsedNumber)
    setIsValid(isValid)
  }

  return (
    <Container ref={containerRef}>
      <PrefixButton onClick={openDropdown}>
        <FlagEmoji>{prefixObject?.flag || ''}</FlagEmoji>
        <IconWrapper>
          <svg width="20" height="20" viewBox="0 0 20 20">
            <path
              d="M9.9998 4.85833L12.6415 7.5L13.8165 6.325L9.9998 2.5L6.1748 6.325L7.35814 7.5L9.9998 4.85833ZM9.9998 15.1417L7.35814 12.5L6.18314 13.675L9.9998 17.5L13.8248 13.675L12.6415 12.5L9.9998 15.1417Z"
              fill="currentColor"
            />
          </svg>
        </IconWrapper>
      </PrefixButton>
      <PrefixLabel>{prefixObject?.dial_code || ''}</PrefixLabel>
      <PhoneNumberInput onChange={(e) => handlePhoneNumberChange(e.target.value)} value={number} />

      {createPortal(
        <CountryPrefixSelect
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          inputContainerRef={containerRef}
          setPrefixValue={setPrefixObject}
          {...containerBonds}
        />,
        document.body
      )}
    </Container>
  )
}
