import { FC, useState } from 'react'
import { Container, InputContainer, Label } from './Checkbox.styles'

interface CheckboxProps {
  value: boolean
  onChange?: (value: boolean) => void
  label?: string
  disabled?: boolean
  readOnly?: boolean
}

export const Checkbox: FC<CheckboxProps> = ({ value, onChange, label, disabled, readOnly }) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Container
      onClick={() => !disabled && !readOnly && onChange && onChange(!value)}
      onMouseEnter={() => !disabled && !readOnly && setIsHovering(true)}
      onMouseLeave={() => !disabled && !readOnly && setIsHovering(false)}
    >
      <InputContainer
        $checked={value}
        $disabled={disabled}
        $readOnly={readOnly}
        $isHovering={isHovering}
        $focused={isFocused}
      >
        <input
          type="checkbox"
          disabled={disabled}
          readOnly={readOnly}
          checked={value === true}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <svg width="20" height="20" viewBox="0 0 21 20">
          <path
            d="M14.9738 7.05541C15.2193 7.3173 15.2061 7.72864 14.9442 7.97417L9.61104 12.9742C9.36102 13.2086 8.97196 13.2086 8.72192 12.9742L6.05507 10.4742C5.79316 10.2287 5.77988 9.81733 6.0254 9.55543C6.27091 9.29353 6.68225 9.28025 6.94416 9.52576L9.16644 11.609L14.055 7.02579C14.3169 6.78026 14.7283 6.79352 14.9738 7.05541Z"
            fill="currentColor"
          />
        </svg>
      </InputContainer>

      {label && <Label $disabled={disabled}>{label}</Label>}
    </Container>
  )
}
