import { styled } from 'styled-components'
import { StyledMenuItem, StyledMenuIcon } from '../Sidebar.styles'
import { FC } from 'react'
import { Transactions } from '../Icons/transactions'
import { CreateTransaction } from '../Icons/create_transaction'
import { JwtEncryption } from '../Icons/jwt_encryption'
import { Settings } from '../Icons/settings'
import { Logout } from '../Icons/logout'

interface MenuItemProps {
  isActive?: boolean
  isVisible?: boolean
  isLabelVisible?: boolean
  icon: string
  label: string
  href?: string
}

export const MenuItem: FC<MenuItemProps> = ({
  isActive,
  isVisible,
  isLabelVisible,
  icon,
  label,
  href,
}) => {
  const renderIcon = () => {
    switch (icon) {
      case 'transactions':
        return <Transactions />
      case 'create_transaction':
        return <CreateTransaction />
      case 'jwt_encryption':
        return <JwtEncryption />
      case 'settings':
        return <Settings />
      case 'logout':
        return <Logout />
      default:
        return icon
    }
  }

  return (
    isVisible && (
      <StyledMenuItem className={isActive && 'active'} href={href} title={label}>
        <StyledMenuIcon>{renderIcon()}</StyledMenuIcon>
        {isLabelVisible && <span>{label}</span>}
      </StyledMenuItem>
    )
  )
}
