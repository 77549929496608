import { css, styled } from 'styled-components'

export const Animatable = css`
  ${({ theme }) =>
    theme.animations.enabled &&
    css`
      transition: all ${({ theme }) => theme.animations.duration}
        ${({ theme }) => theme.animations.function};
    `}
`

export const VerticalSpacer = styled.div<{ $size: number }>`
  width: 100%;
  height: ${({ $size }) => $size}px;
`
