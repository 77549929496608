import { FC, useContext } from 'react'
import { StoreContext } from '@components/App'
import { useDarkMode } from '@hooks/useDarkmode'
import { StyledToasts } from './Toasts.styles'
import { ToastContainer } from 'react-toastify'

export const Toasts: FC = () => {
  const store = useContext(StoreContext)
  const { isLogged } = store.Auth
  const { isDarkMode } = useDarkMode()

  return (
    isLogged && (
      <StyledToasts>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={isDarkMode ? 'dark' : 'light'}
        />
      </StyledToasts>
    )
  )
}
