import { FC, useEffect, useRef, useState } from 'react'
import fuzzysort from 'fuzzysort'
import { countryPrefixes } from './countryPrefixes'
import {
  CountryPrefixContainer,
  List,
  ListItem,
  SearchIcon,
  SearchInput,
} from './PhoneInput.styles'
import { useClickOutside } from './useClickOutside'
import { RowProps, CountryPrefixSelectProps } from './phoneInput.d'

export const CountryPrefixSelect: FC<CountryPrefixSelectProps> = ({
  isOpen,
  setIsOpen,
  setPrefixValue,
  height,
  width,
  x,
  y,
  inputContainerRef,
}) => {
  const containerRef = useRef()
  useClickOutside([containerRef, inputContainerRef], () => isOpen && setIsOpen(false))

  const [query, setQuery] = useState('')
  const [filteredCountryPrefixes, setFilteredCountryPrefixes] = useState(countryPrefixes)

  useEffect(() => {
    if (!query) {
      setFilteredCountryPrefixes(countryPrefixes)
      return
    }

    const results = fuzzysort.go(query, countryPrefixes, { keys: ['name', 'dial_code', 'code'] })

    setFilteredCountryPrefixes(results.map((result) => result.obj))
  }, [query])

  const Row: FC<RowProps> = ({ index, style }) => {
    const countryPrefix = filteredCountryPrefixes[index]

    return (
      <ListItem
        style={style}
        onClick={() => {
          setPrefixValue(countryPrefix)
          setIsOpen(false)
        }}
      >
        <i>{countryPrefix.flag}</i>
        <label>{countryPrefix.name.split(',')[0]}</label>
        <span>{countryPrefix.dial_code}</span>
      </ListItem>
    )
  }

  return (
    <CountryPrefixContainer
      ref={containerRef}
      width={width}
      height={height}
      $x={x}
      $y={y}
      $isOpen={isOpen}
    >
      <SearchInput placeholder="Search" onChange={(e) => setQuery(e.target.value)} value={query} />
      <SearchIcon>
        <svg width="20" height="20" viewBox="0 0 20 20">
          <path
            d="M9.52089 4.42307C6.71154 4.42307 4.43412 6.67788 4.43412 9.45933C4.43412 12.2408 6.71154 14.4956 9.52089 14.4956C12.3302 14.4956 14.6077 12.2408 14.6077 9.45933C14.6077 6.67788 12.3302 4.42307 9.52089 4.42307ZM3.3335 9.45933C3.3335 6.07606 6.10369 3.33337 9.52089 3.33337C12.9381 3.33337 15.7083 6.07606 15.7083 9.45933C15.7083 10.9886 15.1423 12.3869 14.2065 13.4603L16.5056 15.7366C16.7206 15.9494 16.7206 16.2943 16.5056 16.5071C16.2907 16.7199 15.9423 16.7199 15.7274 16.5071L13.4164 14.2191C12.3528 15.0734 10.9972 15.5853 9.52089 15.5853C6.10369 15.5853 3.3335 12.8426 3.3335 9.45933Z"
            fill="currentColor"
          />
        </svg>
      </SearchIcon>

      <List height={240} itemCount={filteredCountryPrefixes.length} itemSize={35} width={width}>
        {Row}
      </List>
    </CountryPrefixContainer>
  )
}
