export const FileLookup = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.625 4.58339C3.625 3.36377 4.6137 2.37506 5.83333 2.37506H10.9763C11.562 2.37506 12.1237 2.60772 12.5378 3.02187L15.7282 6.21222C16.1423 6.62637 16.375 7.18806 16.375 7.77375V15.4167C16.375 16.6364 15.3863 17.6251 14.1667 17.6251H7.5C7.20085 17.6251 6.95833 17.3825 6.95833 17.0834C6.95833 16.7842 7.20085 16.5417 7.5 16.5417H14.1667C14.788 16.5417 15.2917 16.038 15.2917 15.4167V8.45831H12.5C11.2804 8.45831 10.2917 7.46961 10.2917 6.24998V3.45839H5.83333C5.21201 3.45839 4.70833 3.96207 4.70833 4.58339V13.3334C4.70833 13.6325 4.46582 13.8751 4.16667 13.8751C3.86751 13.8751 3.625 13.6325 3.625 13.3334V4.58339ZM14.9622 6.97826C15.0759 7.09195 15.1627 7.22748 15.2186 7.37498H12.5C11.8787 7.37498 11.375 6.8713 11.375 6.24998V3.53141C11.5225 3.58733 11.6581 3.67418 11.7718 3.7879L14.9622 6.97826Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17487 8.45823C7.13149 8.45823 5.47501 10.1147 5.47501 12.1581C5.47501 12.9651 5.73338 13.7118 6.17193 14.3199L5.02144 15.4704C4.80991 15.6819 4.80991 16.0249 5.02144 16.2364C5.23298 16.4479 5.57594 16.4479 5.78748 16.2364L6.9269 15.097C7.55 15.5743 8.32931 15.858 9.17487 15.858C11.2182 15.858 12.8747 14.2015 12.8747 12.1581C12.8747 10.1147 11.2182 8.45823 9.17487 8.45823ZM6.55834 12.1581C6.55834 10.713 7.7298 9.54156 9.17487 9.54156C10.6199 9.54156 11.7914 10.713 11.7914 12.1581C11.7914 13.6032 10.6199 14.7746 9.17487 14.7746C7.7298 14.7746 6.55834 13.6032 6.55834 12.1581Z"
        fill="currentColor"
      />
    </svg>
  )
}
