import { action, makeAutoObservable } from 'mobx'
import * as translations from '../translations/index'
import { Language, TranslationsInterface } from '../types/translations'

const getInitialLanguage = () => {
  // const localLanguage: Language = localStorage.getItem('language') as Language

  // if (localLanguage)
  //   return localLanguage

  // const navigatorLanguage: Language = ((navigator.languages && navigator.languages[0]) || navigator.language)
  //   .split('-')[0] as Language

  // if (navigatorLanguage)
  //   return navigatorLanguage

  return 'en' as Language
}

export class TranslationsStore {
  rootStore
  language: Language = getInitialLanguage()
  translations: TranslationsInterface = translations[this.language] ?? translations.en

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setTranslations(language: Language): void {
    this.language = language
    this.translations = translations[language] ?? translations.en
    localStorage.setItem('language', language)
  }
}
