import { styled } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(10px);
  color: ${({ theme }) => theme.transactionListPage.title.color};
  justify-content: space-between;
  gap: 32px;

  & > *:not(:last-child) {
    margin-right: 0;
    margin-left: 0;
  }

  /* set the second to last-child */
  & > :nth-last-child(3) {
    margin-right: auto;
    margin-left: 0;
  }
`

export const LimitContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .select-input {
    &__control {
      padding: 0px 8px 0px 12px !important;
      border: ${({ theme }) => theme.transactionListPage.pagination.selectInput.border} !important;
      background: ${({ theme }) =>
        theme.transactionListPage.pagination.selectInput.bgColor} !important;
      gap: 2px !important;
    }

    &__menu-list {
      background: ${({ theme }) =>
        theme.transactionListPage.pagination.selectInput.bgColor} !important;
    }
  }
`

export const LinksContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  span {
    color: #25292c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  button {
    border-radius: 4px !important;
    width: 24px;
    height: 24px;
    border: ${({ theme }) => theme.transactionListPage.pagination.iconButton.border};
    background: ${({ theme }) => theme.transactionListPage.pagination.iconButton.bgColor};

    & > div {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: ${({ theme }) => theme.transactionListPage.pagination.iconButton.color} !important;
    }
  }
`
