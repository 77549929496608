import styled, { css } from 'styled-components'

export const Container = styled.div`
  min-width: 20px;
  height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

export const Label = styled.label<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.checkbox.label.colorDisabled : theme.checkbox.label.color};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`

export const InputDot = styled.div<{
  $checked?: boolean
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: opacity 0.3s;
  opacity: ${({ $checked }) => ($checked ? 1 : 0)};

  ${({ $checked, theme }) =>
    $checked &&
    css`
      background-color: ${theme.checkbox.checked.backgroundColor};
    `}
`

export const InputContainer = styled.div<{
  $checked?: boolean
  $disabled?: boolean
  $focused?: boolean
  $readOnly?: boolean
  $isHovering?: boolean
}>`
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  transition:
    border 0.3s,
    background-color 0.3s,
    color 0.3s;

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  /* === Default === */
  /* unchecked */
  background-color: ${({ theme }) => theme.checkbox.unchecked.backgroundColor};
  border: ${({ theme }) => theme.checkbox.unchecked.border};

  /* checked */
  ${({ $checked, theme }) =>
    $checked &&
    css`
      color: ${theme.checkbox.checked.color};
      border: ${`1px solid ${theme.checkbox.checked.backgroundColor}`};
    `}

  /* === Read Only === */
    /* === Disabled === */
    ${({ $readOnly, $disabled, $checked, theme }) =>
    ($readOnly || $disabled) &&
    css`
      background-color: ${$checked
        ? theme.checkbox.checked.disabled.backgroundColor
        : theme.checkbox.unchecked.disabled.backgroundColor};
      color: ${$checked ? theme.checkbox.checked.disabled.color : 'unset'};
      border: ${$checked
        ? `1px solid ${theme.checkbox.checked.disabled.backgroundColor}`
        : theme.checkbox.unchecked.disabled.border};
    `}

    /* === Focused === */
    ${({ $focused, theme }) =>
    $focused &&
    css`
      outline: ${theme.checkbox.checked.focused.outline};
    `}

    /* === Hover === */
    ${({ $isHovering, $checked, theme }) =>
    $isHovering &&
    css`
      border: ${$checked
        ? `1px solid ${theme.checkbox.checked.hover.backgroundColor}`
        : theme.checkbox.unchecked.hover.border};
      color: ${$checked ? theme.checkbox.checked.hover.color : 'unset'};
    `}
`

export const RadioList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`
