import { styled } from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 64px;
  background: ${({ theme }) => theme.topMenu.backgroundColor};

  grid-area: top-menu;
`

export const StyledMenu = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 24px;

  display: grid;
  grid-template-columns: 60px 1fr 120px 32px auto auto;
  grid-template-rows: 100%;
  gap: 24px;
  grid-template-areas: 'logo ____ search notifications user tenant';

  align-items: center;
  justify-content: center;

  @media (max-width: 980px) {
    grid-template-columns: 60px auto auto;
    grid-template-areas: 'logo tenant hamburger';
    justify-content: space-between;
  }
`

export const Logo = styled.div`
  grid-area: logo;
`

export const Search = styled.div`
  grid-area: search;
  position: relative;
`

export const SearchInput = styled.input`
  width: 112px;
  height: 28px;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.topMenu.search.backgroundColor};
  border: ${({ theme }) => theme.topMenu.search.border};
  color: ${({ theme }) => theme.topMenu.search.color};
  border-radius: 4px;
  outline: none;
`

export const SearchInputIcon = styled.div`
  position: absolute;
  left: 6px;
  top: 6px;
  color: ${({ theme }) => theme.topMenu.search.iconColor};
`

export const SearchInputButton = styled.button`
  outline: none;
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 14px;
  top: 5px;
  background-color: ${({ theme }) => theme.topMenu.search.buttonBgColor};
  color: ${({ theme }) => theme.topMenu.search.buttonColor};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`

export const StyledNotifications = styled.div`
  grid-area: notifications;
  width: 32px;
  height: 32px;
  position: relative;
  color: ${({ theme }) => theme.topMenu.notifications.iconColor};
`

export const StyledUser = styled.div`
  grid-area: user;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  color: ${({ theme }) => theme.topMenu.userInfo.color};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 980px) {
    display: none;
  }
`

export const Tenant = styled.div`
  grid-area: tenant;
  width: 100%;
  min-width: 230px;
`

export const StyledHamburger = styled.div`
  grid-area: hamburger;
  color: white;
  display: none;
  @media (max-width: 980px) {
    display: flex;
    width: 32px;
    height: 32px;
    position: relative;
  }
`
