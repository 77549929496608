import { axiosInstance } from '../axios/AxiosInstance'
import { action, autorun, computed, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'
import { tableDataMock } from '@/pages/TransactionListPage/mock'
import { DEV_MODE } from '@/constants/envs'
import { fromPromise } from 'mobx-utils'
import { TransactionListItem } from '@/types/transactions'
import { parseQueryParams } from '@/methods/paramsComposer'

interface FetchTransactions {
  params: {
    tenantId: string
    transactionId?: string
    configurationId?: string
    externalUserRefId?: string
    transactionCreatedAtStart?: string
    transactionCreatedAtEnd?: string
    status?: string
    tag?: string
    tagNotPresent?: string
    nextPageToken?: string
  }
}

interface PaginationProps {
  currentPage: number
  total: number
  limit: number
}

interface PaginationData extends PaginationProps {
  currentListLength: number
  pagesNumber: number
}

export const COLUMN_NAME_MAP = {
  transactionId: 'Transaction ID',
  configurationId: 'Configuration ID',
  externalReferenceId: 'External User ID',
  creationUnixTimeSeconds: 'Creation Time',
  completionUnixTimeSeconds: 'Completion Time',
  status: 'Status',
  tags: 'Tags',
}

export const COLUMNS = [
  'transactionId',
  'configurationId',
  'externalUserRefId',
  'creationUnixTimeSeconds',
  'completionUnixTimeSeconds',
  'status',
  'tags',
]

export class TransactionListStore {
  rootStore: RootStore

  isFetching = true
  refetchFn = () => {}

  transactions: TransactionListItem[] = []

  currentPageIndex: number = 0
  pageTokenArray: Array<string> = []
  pagesTotal: number = 0
  pagesLimit: number = 10

  get pagination(): PaginationData {
    return {
      currentPage: this.currentPageIndex,
      currentListLength: this.transactions?.length,
      pagesNumber: this.pageTokenArray.length,
      total: this.pagesTotal,
      limit: this.pagesLimit,
    }
  }

  visibleColumns = [
    'transactionId',
    'configurationId',
    'externalUserRefId',
    'creationUnixTimeSeconds',
    'completionUnixTimeSeconds',
    'status',
    'tags',
  ]

  columnsWidthsAbsolute = {
    transactionId: 320,
    configurationId: 200,
    externalReferenceId: 320,
    creationUnixTimeSeconds: 160,
    completionUnixTimeSeconds: 160,
    status: 120,
    tags: 300,
  }

  get columnsWidthsComputed() {
    const widths = []
    this.visibleColumns.forEach((column) => {
      widths.push(this.columnsWidthsAbsolute[column] || 150)
    })

    return widths
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      columnsWidthsComputed: computed,
      pagination: computed,
    })
    this.rootStore = rootStore
  }

  @action.bound toggleColumn(column: string): void {
    if (this.visibleColumns.includes(column)) {
      this.visibleColumns = this.visibleColumns.filter((c) => c !== column)
    } else {
      //  preserve original order
      this.visibleColumns.splice(COLUMNS.indexOf(column), 0, column)
    }
  }

  @action.bound setCurrentPageIndex(index: number): void {
    this.currentPageIndex = index
  }

  @action.bound setPagesTotal(total: number): void {
    this.pagesTotal = total
  }

  @action.bound setPagesLimit(limit: number): void {
    this.pagesLimit = limit
  }

  @action.bound setIsFetching(isFetching: boolean): void {
    this.isFetching = isFetching
  }

  @action.bound setTransactions(transactions: TransactionListItem[]): void {
    console.log('setTransactions', transactions)
    if (typeof transactions?.length !== 'number') return

    if (transactions?.length < this.pagesLimit) {
      // grid row height fix
      const arr = new Array(this.pagesLimit - transactions.length).fill(null)
      this.transactions = [...transactions, ...arr]
    } else {
      this.transactions = transactions
    }
  }

  @action.bound setRefetchFn(fn: () => void): void {
    this.refetchFn = fn
  }

  @action.bound refetch(): void {
    this.refetchFn()
  }

  @action.bound setPaginationData(data: PaginationProps): void {
    this.currentPageIndex = data.currentPage
    this.pagesTotal = data.total
    this.pagesLimit = data.limit
  }

  // fetchTransactions = async ({ params }: FetchTransactions) => {
  //   this.isFetching = true

  //   const response = await axiosInstance.get<{
  //     items: TransactionListItem[]
  //     total: number
  //     limit: number
  //     nextPageToken?: string
  //   }>('/transactions', {
  //     params: parseQueryParams({
  //       // tenantId: this.rootStore.AppState.selectedTenant,
  //       ...params,
  //       // transactionId: this.rootStore.Filters.filter || undefined,
  //       // configurationId: this.rootStore.Filters.configurationId || undefined,
  //       // externalUserRefId: this.rootStore.Filters.userRef || undefined,
  //       // transactionCreatedAtStart: formatDate(this.rootStore.Filters.transactionStart),
  //       // transactionCreatedAtEnd: formatDate(this.rootStore.Filters.transactionEnd),
  //       // status:
  //       //   this.rootStore.Filters.selectedStatus && this.rootStore.Filters.selectedStatus !== 'all'
  //       //     ? this.rootStore.Filters.selectedStatus
  //       //     : undefined,
  //       // tag: parseTagFilter('include', this.rootStore.Filters.tagFilterArr),
  //       // tagNotPresent: parseTagFilter('exclude', this.rootStore.Filters.tagFilterArr),
  //       // nextPageToken: this.rootStore.Filters.pageTokenArray[this.rootStore.Filters.currentPageIndex],
  //     }),
  //     // paramsSerializer: (params) => {
  //     //   return qs.stringify(params, { arrayFormat: 'repeat' })
  //     // },
  //   })

  //   this.setPagesTotal(response.data.total)
  //   this.setPagesLimit(response.data.limit)

  //   if (response.data?.nextPageToken?.length) {
  //     // this.rootStore.Filters.setPageTokenByIndex(
  //     //   this.rootStore.Filters.currentPageIndex + 1,
  //     //   response.data.nextPageToken
  //     // )
  //   }

  //   this.transactions = response.data.items
  //   this.isFetching = false
  //   this.shouldRefetch = false
  // }
}
