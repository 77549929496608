import { format } from 'date-fns'

export const camelToTitle = (camel?: string) => {
  if (!camel) return ''

  return camel.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
}

export const formatUnixDate = (unixTimeSeconds: any): string => {
  if (typeof unixTimeSeconds !== 'number') return ''
  const date = new Date(unixTimeSeconds * 1000)

  return format(date, 'yyyy-MM-dd HH:mm:ss') + ' UTC'
}
