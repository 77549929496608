import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './Root.store'
import { ContainerStates, Provider, Transaction } from '@/pages/TransactionDetailsPage/types'
import { dataMock } from '@/pages/TransactionDetailsPage/mock'
import { axiosInstance } from '@/axios/AxiosInstance'
import { DEV_MODE } from '@/constants/envs'

interface InitTransactionPreviewParams {
  transactionId: string
  isV3: boolean
  selectedTenant: string
}

export class TransactionDetailsStore {
  rootStore: RootStore
  transactionId: string = ''
  transaction: Transaction | undefined = undefined

  isTransactionLoading = true
  isAuditLogsLoading = true
  isHistoryLoading = true

  isV3 = false
  selectedTenant = ''

  get providers(): Provider[] {
    if (!this.transaction) return []

    return Object.keys(this.transaction.providers).map((key) => {
      return {
        providerName: key,
        ...this.transaction.providers[key],
      }
    })
  }

  containerStates: ContainerStates = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      providers: computed,
    })
    this.rootStore = rootStore
  }

  @action.bound initTransactionPreview = ({
    transactionId,
    isV3,
    selectedTenant,
  }: InitTransactionPreviewParams): void => {
    runInAction(() => {
      this.transactionId = transactionId
      this.isV3 = isV3
      this.selectedTenant = localStorage.getItem('selectedTenant') || selectedTenant

      this.fetchTransaction()
      this.fetchAuditLogs()
      this.fetchHistory()
    })
  }

  @action.bound fetchTransaction = async (): Promise<void> => {
    this.isTransactionLoading = true
    try {
      if (DEV_MODE) {
        this.transaction = dataMock as Transaction
        this.isTransactionLoading = false
        return
      }

      const { data } = await axiosInstance.get(`/transactions/${this.transactionId}`, {
        params: {
          tenantId: this.selectedTenant,
          isV3: this.isV3,
        },
      })

      runInAction(() => {
        this.transaction = data as Transaction
        this.isTransactionLoading = false
      })
    } catch (error) {
      console.error('Error fetching transaction', error)
      this.isTransactionLoading = false
    }
  }

  @action.bound fetchAuditLogs = async (): Promise<void> => {}

  @action.bound fetchHistory = async (): Promise<void> => {}

  @action.bound setTransactionDetails = (transaction: Transaction): void => {
    this.transaction = transaction
  }

  @action.bound setContainerStates = (containerStates: ContainerStates): void => {
    this.containerStates = containerStates
  }

  @action.bound setContainerCollapsedState = (containerId: string, isCollapsed: boolean): void => {
    this.setAllContainersCollapsed()
    console.log('setContainerCollapsedState', containerId, isCollapsed)

    if (!this.containerStates[containerId]) {
      this.containerStates[containerId] = {
        providerName: containerId,
        isCollapsed: isCollapsed,
        isHighlighted: false,
      }
    } else {
      this.containerStates[containerId].isCollapsed = isCollapsed
    }
  }

  @action.bound setAllContainersCollapsed = (): void => {
    Object.keys(this.containerStates).forEach((key) => {
      this.containerStates[key].isCollapsed = true
    })
  }

  @action.bound setContainerHighlighted = async (containerId: string) => {
    this.containerStates[containerId].isHighlighted = true

    setTimeout(() => {
      this.containerStates[containerId].isHighlighted = false
    }, 2000)
  }
}
