import styled from 'styled-components'

export const TRANSITION_DURATION = 300

interface ContainerProps {
  width?: string
}

export const FullscreenWrap = styled.div<{ opacity?: number }>`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 64px;
  left: 0;
  width: 100vw;
  height: calc(100% - 64px);
  z-index: 1;
  background: ${({ theme }) => theme.sidebarPopup.overlay.bgColor};
  opacity: ${({ opacity }) => opacity};
  transition: opacity ${TRANSITION_DURATION - 100 + 'ms'} cubic-bezier(0.42, 0, 1, 1);

  @media (max-width: 980px) {
    height: 100vh;
    position: fixed;
  }
`

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '800px')};
  transition: width ${TRANSITION_DURATION + 'ms'} cubic-bezier(0, 0, 0.58, 1);
  background-color: ${({ theme }) => theme.sidebarPopup.bgColor};
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  font-weight: 400;
  box-shadow: ${({ theme }) => theme.sidebarPopup.boxShadow};

  @media (max-width: 980px) {
    height: 100%;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  svg {
    fill: ${({ theme }) => theme.sidebarPopup.closeIcon.color};
  }
`

export const PopupHeader = styled.header`
  color: ${({ theme }) => theme.sidebarPopup.header.color};
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
  line-height: 36px;
  font-style: normal;
  font-weight: 600;

  svg,
  path {
    fill: ${({ theme }) => theme.sidebarPopup.header.color} !important;
  }
`

export const PopupBody = styled.section``
