import React from 'react'

export const ChevronLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4595 17.4596C15.2057 17.7135 14.7941 17.7135 14.5403 17.4596L9.54028 12.4596C9.28644 12.2058 9.28644 11.7942 9.54028 11.5404L14.5403 6.54041C14.7941 6.28656 15.2057 6.28656 15.4595 6.54041C15.7134 6.79425 15.7134 7.2058 15.4595 7.45964L10.9191 12L15.4595 16.5404C15.7134 16.7942 15.7134 17.2058 15.4595 17.4596Z"
      />
    </svg>
  )
}
