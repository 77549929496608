import { FC, ReactNode } from 'react'
import { styled } from 'styled-components'

const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

interface UnauthorizedLayoutProps {
  children: ReactNode
}

export const UnauthorizedLayout: FC<UnauthorizedLayoutProps> = ({ children }) => (
  <LayoutContainer className="unauthorized-layout">{children}</LayoutContainer>
)
