import { FC, useContext } from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import { StoreContext } from '@/components/App'
import { COLUMNS } from '@/store/TransactionList.store'
import { styled } from 'styled-components'
import { Animatable } from '@/styles/generic.styles'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/zoom.css'
import { Checkbox } from '@/components/Generic'

const StyledColumnVisibilityManager = styled.div`
  position: relative;
  height: 32px;
  .szh-menu-container {
    z-index: 99999;

    ul {
      top: 5px !important;
      background-color: ${({ theme }) => theme.table.columnVisibilityManager.bgColor};
      border-radius: 4px;
      border: ${({ theme }) => theme.table.columnVisibilityManager.border};
      min-width: 100%;
    }
  }

  .szh-menu {
    &--state-open {
      li {
        padding: 0 !important;
      }
    }

    &__item--hover {
      background-color: transparent !important;
    }
  }

  ${Animatable}
`

const ColumnRow = styled.div`
  min-width: 100px;
  max-width: 230px;
  background-color: transparent;
  padding: 10px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }
`

const ColumnRowTitle = styled(ColumnRow)`
  color: ${({ theme }) => theme.table.columnVisibilityManager.titleColor};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

const ColumnName = styled.div`
  cursor: pointer;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.table.columnVisibilityManager.color};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
const OpenButton = styled(MenuButton)<{ $isOpen?: boolean }>`
  appearance: none;
  outline: none;
  width: fit-content;
  height: 32px;
  padding: 0 8px 0 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.table.columnVisibilityManager.openButton.bgColor};
  border: ${({ theme }) => theme.table.columnVisibilityManager.openButton.border};
  color: ${({ theme }) => theme.table.columnVisibilityManager.openButton.color};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
    filter: ${({ theme }) => theme.effects.hover.brightness};
  }

  span {
    transition: transform 0.3s;
    color: ${({ theme }) => theme.table.columnVisibilityManager.openButton.iconColor};
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`

export const ColumnVisibilityManager: FC = () => {
  const store = useContext(StoreContext)
  const { transactionListHeaders: trans } = store.TranslationsState.translations
  const { visibleColumns } = store.TransactionListState

  const renderMenuItems = () =>
    COLUMNS.map((column) => (
      <MenuItem
        key={column}
        value={column}
        onClick={(e) => {
          e.stopPropagation = true
          e.keepOpen = true

          store.TransactionListState.toggleColumn(column)
        }}
      >
        <ColumnRow>
          <Checkbox value={visibleColumns.includes(column)} label={trans[column] || column} />
        </ColumnRow>
      </MenuItem>
    ))

  return (
    <StyledColumnVisibilityManager>
      <Menu
        menuButton={({ open }) => (
          <OpenButton $isOpen={open}>
            {trans.editColumns}
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                  d="M5.86715 12.4663C5.65561 12.2548 5.65561 11.9119 5.86715 11.7003L10.0338 7.53365C10.2453 7.32212 10.5883 7.32212 10.7998 7.53365L14.9665 11.7003C15.178 11.9119 15.178 12.2548 14.9665 12.4663C14.755 12.6779 14.412 12.6779 14.2005 12.4663L10.4168 8.6827L6.63318 12.4663C6.42164 12.6779 6.07868 12.6779 5.86715 12.4663Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </OpenButton>
        )}
      >
        <ColumnRowTitle>{trans.presentedData}</ColumnRowTitle>
        {renderMenuItems()}
      </Menu>
    </StyledColumnVisibilityManager>
  )
}
