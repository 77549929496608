import { TransactionListItem } from '@/types/transactions'

export const tableDataMock: TransactionListItem[] = [
  {
    transactionId: '31ba6427-adc2-4019-91d0-d9ad3f5198fd',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727103934,
  },
  {
    transactionId: 'f5eaada2-ba9b-4c81-a118-efc7a0d022c8',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727103430,
  },
  {
    transactionId: '6355fb39-4fd1-40f7-89e1-fae7bc97d1c4',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727103144,
  },
  {
    transactionId: 'f29dbdf7-97f0-4089-b003-cccd5bc4ddf9',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727102407,
  },
  {
    transactionId: 'a6430df6-6d19-438d-99d4-670900625497',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727101913,
  },
  {
    transactionId: '3478df53-3ed2-4737-a903-b4a610ed16c9',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727101041,
  },
  {
    transactionId: 'e92de0cc-7b5f-4268-9d11-f68d43fbb88f',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727100745,
  },
  {
    transactionId: 'bc11321f-de67-4790-98dc-6571ed255bca',
    clientId: 'dev.pink',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727100657,
  },
  {
    transactionId: '0d15b868-5603-4ac0-99ea-b0173bea000a',
    clientId: 'dev.pink',
    configurationId: 'bmw.de',
    isMocked: false,
    status: 'completed',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727098128,
    completionUnixTimeSeconds: 1727099972,
    tags: [],
  },
  {
    transactionId: 'fc11a5e7-f7a1-4401-87f8-51a0b602f8af',
    clientId: 'dev.pink',
    configurationId: 'bmw.it',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727098076,
    completionUnixTimeSeconds: 1727100776,
    tags: [],
  },
  {
    transactionId: 'df839735-e9a7-45f3-a2f5-fa4daf03fdf9',
    clientId: 'dev.pink',
    configurationId: 'ikano.de',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727098072,
    tags: ['proceeded'],
  },
  {
    transactionId: 'ff8d41c2-d52c-4973-9434-f4e2e502676e',
    clientId: 'dev.pink',
    configurationId: 'bmw.it',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727098018,
    completionUnixTimeSeconds: 1727100718,
    tags: [],
  },
  {
    transactionId: '2931188c-b245-496c-970f-7456643ed792',
    clientId: 'dev.pink',
    configurationId: 'ikano.de',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727098015,
    tags: ['proceeded', 'deviceSwitched'],
  },
  {
    transactionId: 'cd4be393-52c0-42b5-a4da-085de200d663',
    clientId: 'dev.pink',
    configurationId: 'bmw.it',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727097965,
    completionUnixTimeSeconds: 1727100666,
    tags: [],
  },
  {
    transactionId: '8dd7850d-d206-448b-8801-bcd3f3fc9067',
    clientId: 'dev.pink',
    configurationId: 'ikano.de',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727097957,
    tags: ['proceeded', 'deviceSwitched'],
  },
  {
    transactionId: 'cab8f711-9745-44b0-b399-d9e2e2867318',
    clientId: 'dev.pink',
    configurationId: 'bmw.pl',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727097957,
    completionUnixTimeSeconds: 1727100658,
    tags: [],
  },
  {
    transactionId: '2df75162-271e-460b-86d6-335d92a9cbff',
    clientId: 'dev.pink',
    configurationId: 'jitpay.eu.micropayment',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727097946,
    completionUnixTimeSeconds: 1727099746,
    tags: ['success'],
  },
  {
    transactionId: 'd67b831b-63f3-4e9d-b7d8-8c8137d409b6',
    clientId: 'dev.pink',
    configurationId: 'bmw.it',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727097903,
    completionUnixTimeSeconds: 1727100604,
    tags: [],
  },
  {
    transactionId: 'c0654167-a40d-48fe-bb9d-ea148ec51b89',
    clientId: 'dev.pink',
    configurationId: 'bmw.pl',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727097903,
    completionUnixTimeSeconds: 1727100604,
    tags: [],
  },
  {
    transactionId: '2e28e7f6-9bec-46cf-9d53-38d5cc2eacb1',
    clientId: 'dev.pink',
    configurationId: 'ikano.de',
    isMocked: false,
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727097900,
    tags: ['proceeded'],
  },
  {
    transactionId: 'a3f4c4df-a9ec-4733-92f3-756ca53fbe33',
    clientId: 'dev.pink',
    configurationId: 'jitpay.eu',
    isMocked: false,
    status: 'expired',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727097885,
    completionUnixTimeSeconds: 1727099687,
    tags: [],
  },
  {
    transactionId: '0f33870b-3af5-421b-a932-2179c9c359e2',
    clientId: 'dev.pink',
    configurationId: 'saltEdgeAis.test',
    status: 'completed',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727096588,
    completionUnixTimeSeconds: 1727096656,
    tags: ['proceeded', 'success'],
    isV3: true,
  },
  {
    transactionId: 'd5bab141-95b4-4dbf-872f-c4d400d5e774',
    clientId: 'dev.pink',
    configurationId: 'saltEdgeAis.test',
    status: 'pending',
    transactionCompleted: false,
    creationUnixTimeSeconds: 1727096583,
    isV3: true,
  },
  {
    transactionId: '55afa2a7-0f9e-4804-b717-4af3634266b1',
    clientId: 'dev.pink',
    configurationId: 'saltEdgeAis.test',
    status: 'completed',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727096420,
    completionUnixTimeSeconds: 1727096569,
    tags: ['proceeded'],
    isV3: true,
  },
  {
    transactionId: '62d56043-07dd-442f-8eb4-ea153807df61',
    clientId: 'dev.pink',
    configurationId: 'saltEdgeAis.test',
    status: 'completed',
    transactionCompleted: true,
    creationUnixTimeSeconds: 1727096212,
    completionUnixTimeSeconds: 1727096357,
    tags: ['proceeded', 'success'],
    isV3: true,
  },
]
