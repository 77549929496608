export const CreateTransaction = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0392 4.5C10.3912 4.5 10.6765 4.78531 10.6765 5.13725V8.42157L13.9608 8.42157C14.3127 8.42157 14.598 8.70688 14.598 9.05882C14.598 9.41077 14.3127 9.69608 13.9608 9.69608H10.6765V12.9804C10.6765 13.3323 10.3912 13.6176 10.0392 13.6176C9.68727 13.6176 9.40196 13.3323 9.40196 12.9804V9.69608H6.11765C5.7657 9.69608 5.48039 9.41077 5.48039 9.05882C5.48039 8.70688 5.7657 8.42157 6.11765 8.42157H9.40196V5.13725C9.40196 4.78531 9.68727 4.5 10.0392 4.5ZM14.2549 5.13725C14.2549 4.78531 14.5402 4.5 14.8922 4.5H14.9412C17.4589 4.5 19.5 6.54106 19.5 9.05882V14.9412C19.5 17.4589 17.4589 19.5 14.9412 19.5H9.05882C6.54105 19.5 4.5 17.4589 4.5 14.9412V13.1438C4.5 12.7918 4.78531 12.5065 5.13725 12.5065C5.4892 12.5065 5.77451 12.7918 5.77451 13.1438V14.9412C5.77451 16.7551 7.24495 18.2255 9.05882 18.2255H14.9412C16.7551 18.2255 18.2255 16.7551 18.2255 14.9412V9.05882C18.2255 7.24495 16.7551 5.77451 14.9412 5.77451H14.8922C14.5402 5.77451 14.2549 5.4892 14.2549 5.13725Z"
      />
    </svg>
  )
}
