import { DatePickerInput } from '@/components/Generic/DatePicker/DatePicker'
import { PhoneInput, PhoneValue } from '@/components/Generic/PhoneInput'
import { FC, useEffect, useState } from 'react'
import { DateValue } from 'react-aria-components'
import { styled } from 'styled-components'

export const PaddedContainer = styled.div`
  padding: 64px;
`

export const TestInputWrapper = styled.div`
  margin-top: 16px;
  width: 320px;
`

export const DevPage: FC = () => {
  const [date, setDate] = useState<DateValue | null>(null)

  const [phoneNum, setPhoneNum] = useState<PhoneValue>()

  useEffect(() => {
    console.log(date)
  }, [date])

  useEffect(() => {
    console.log(phoneNum)
  }, [phoneNum])

  return (
    <PaddedContainer>
      <TestInputWrapper>
        {/* <DatePickerInput value={date} onChange={setDate} name="date" aria-label="Pick date" /> */}
      </TestInputWrapper>
      <TestInputWrapper>
        <PhoneInput value={phoneNum} onChange={setPhoneNum} />
      </TestInputWrapper>
    </PaddedContainer>
  )
}
