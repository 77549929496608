import { FC, useState } from 'react'
import { Container, PopoverGlobalStyle } from './DatePicker.styles'
import DatePicker from 'rsuite/DatePicker'

interface DatePickerInputProps {
  value?: number
  onChange?: (value: number) => void
  name?: string
  isTimeIncluded?: boolean
}

export const DatePickerInput: FC<DatePickerInputProps> = (props) => {
  const convertUnixToDateValue = (unixTimestamp: number) => {
    if (!unixTimestamp) return undefined

    // convert to date format
    return new Date(unixTimestamp * 1000)
  }

  const [date, setDate] = useState(convertUnixToDateValue(props.value))

  const onChangeHandler = (value) => {
    setDate(value)

    // return unixTimestamp
    if (value) props.onChange(Math.floor(value.getTime() / 1000))
  }

  return (
    <Container>
      <PopoverGlobalStyle />
      <DatePicker format="MM/dd/yyyy HH:mm" value={date} onChange={onChangeHandler} />
    </Container>
  )
}
