import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { Page, Title, VerticalFiltersWrapper } from './TransactionListPage.styles'
import { StoreContext } from '@/components/App'
import { Filters } from './Components/Filters/Filters'
import { TransactionsTable } from './Components/Table/TransactionsTable'
import { Pagination } from './Components/Pagination/Pagination'
import { RefreshButton } from './Components/RefreshButton'
import { TransactionDataProvider } from './TransactionDataProvider'

export const TransactionListPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionsPage: trans } = store.TranslationsState.translations
  const { transactionListFiltersPosition } = store.Settings

  return (
    <Page>
      <VerticalFiltersWrapper $filterPosition={transactionListFiltersPosition}>
        <Title>
          <span>
            <svg width="40" height="40" viewBox="0 0 40 40">
              <path
                d="M11.4448 20.929C11.4448 20.3405 11.9251 19.8634 12.5176 19.8634H14.3881C14.9806 19.8634 15.461 20.3405 15.461 20.929C15.461 21.5175 14.9806 21.9945 14.3881 21.9945H12.5176C11.9251 21.9945 11.4448 21.5175 11.4448 20.929Z"
                fill="currentColor"
              />
              <path
                d="M11.4448 26.5027C11.4448 25.9142 11.9251 25.4372 12.5176 25.4372H14.3881C14.9806 25.4372 15.461 25.9142 15.461 26.5027C15.461 27.0912 14.9806 27.5683 14.3881 27.5683H12.5176C11.9251 27.5683 11.4448 27.0912 11.4448 26.5027Z"
                fill="currentColor"
              />
              <path
                d="M17.0564 20.929C17.0564 20.3405 17.5367 19.8634 18.1292 19.8634H27.482C28.0745 19.8634 28.5548 20.3405 28.5548 20.929C28.5548 21.5175 28.0745 21.9945 27.482 21.9945H18.1292C17.5367 21.9945 17.0564 21.5175 17.0564 20.929Z"
                fill="currentColor"
              />
              <path
                d="M17.0564 26.5027C17.0564 25.9142 17.5367 25.4372 18.1292 25.4372H27.482C28.0745 25.4372 28.5548 25.9142 28.5548 26.5027C28.5548 27.0912 28.0745 27.5683 27.482 27.5683H18.1292C17.5367 27.5683 17.0564 27.0912 17.0564 26.5027Z"
                fill="currentColor"
              />
              <path
                d="M5.8335 13.4973C5.8335 8.80436 9.66371 5 14.3885 5H25.6118C30.3366 5 34.1668 8.80436 34.1668 13.4973V26.5027C34.1668 31.1956 30.3366 35 25.6118 35H14.3885C9.66371 35 5.8335 31.1956 5.8335 26.5027V13.4973ZM14.3885 7.13115C10.8487 7.13115 7.97913 9.98136 7.97913 13.4973V26.5027C7.97913 30.0186 10.8487 32.8689 14.3885 32.8689H25.6118C29.1516 32.8689 32.0212 30.0186 32.0212 26.5027V14.5628H27.482C25.8564 14.5628 24.5386 13.2539 24.5386 11.6393L24.5389 7.13115H14.3885ZM26.6846 7.21992L26.6843 11.6393C26.6843 12.0769 27.0414 12.4317 27.482 12.4317H31.9318C31.4801 9.76953 29.3648 7.66851 26.6846 7.21992Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {trans.pageTitle}
          <RefreshButton onClick={() => store.TransactionListState.refetch()} />
        </Title>

        <Filters />

        <TransactionDataProvider>
          <TransactionsTable />
        </TransactionDataProvider>

        <Pagination />
      </VerticalFiltersWrapper>
    </Page>
  )
})
