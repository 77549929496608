export const Warning = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99967 3.04183C6.15669 3.04183 3.04134 6.15718 3.04134 10.0002C3.04134 13.8431 6.15669 16.9585 9.99967 16.9585C13.8427 16.9585 16.958 13.8431 16.958 10.0002C16.958 6.15718 13.8427 3.04183 9.99967 3.04183ZM1.95801 10.0002C1.95801 5.55887 5.55838 1.9585 9.99967 1.9585C14.441 1.9585 18.0413 5.55887 18.0413 10.0002C18.0413 14.4415 14.441 18.0418 9.99967 18.0418C5.55838 18.0418 1.95801 14.4415 1.95801 10.0002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99967 5.29183C10.2988 5.29183 10.5413 5.53434 10.5413 5.8335V10.8335C10.5413 11.1326 10.2988 11.3752 9.99967 11.3752C9.70052 11.3752 9.45801 11.1327 9.45801 10.8335V5.8335C9.45801 5.53434 9.70052 5.29183 9.99967 5.29183Z"
      />
      <path d="M10.6247 13.5418C10.6247 13.887 10.3449 14.1668 9.99967 14.1668C9.6545 14.1668 9.37467 13.887 9.37467 13.5418C9.37467 13.1967 9.6545 12.9168 9.99967 12.9168C10.3449 12.9168 10.6247 13.1967 10.6247 13.5418Z" />
    </svg>
  )
}
