export const JwtEncryption = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0191 7.26924C17.8955 5.69307 16.5177 4.51549 14.9415 4.63904C13.3653 4.76259 12.1877 6.14048 12.3113 7.71664C12.4348 9.29281 13.8127 10.4704 15.3889 10.3468C16.9651 10.2233 18.1426 8.8454 18.0191 7.26924ZM14.8399 3.34302C17.1318 3.16336 19.1355 4.87571 19.3151 7.16765C19.4948 9.45959 17.7824 11.4632 15.4905 11.6429C14.324 11.7343 13.2322 11.3357 12.4176 10.62L9.24564 13.8252L11.3805 15.8988C11.638 16.1489 11.644 16.5604 11.3938 16.8179C11.1437 17.0754 10.7322 17.0814 10.4747 16.8313L8.33117 14.7493L7.76083 15.3256L9.38046 16.8988C9.63797 17.1489 9.64396 17.5604 9.39384 17.8179C9.14372 18.0754 8.7322 18.0814 8.4747 17.8313L6.84636 16.2497L6.07115 17.033C5.93422 17.1714 5.9365 17.3949 6.07623 17.5304L8.15019 19.5422C8.40786 19.7922 8.41412 20.2037 8.16417 20.4614C7.91422 20.719 7.50271 20.7253 7.24504 20.4753L5.17108 18.4635C4.51236 17.8246 4.5016 16.7709 5.14712 16.1186L11.583 9.6151C11.2681 9.08435 11.0668 8.47509 11.0153 7.81823C10.8356 5.52629 12.548 3.52267 14.8399 3.34302Z"
      />
    </svg>
  )
}
