import { FC, useState } from 'react'
import { Container, InputContainer, InputDot, Label } from './Radio.styles'

interface CheckboxProps {
  value: boolean
  onChange?: (value: boolean) => void
  label?: string
  disabled?: boolean
  readOnly?: boolean
  id?: string
  name?: string
}

export const RadioInput: FC<CheckboxProps> = ({ value, onChange, label, disabled, readOnly }) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Container
      onClick={() => !disabled && !readOnly && onChange && onChange(!value)}
      onMouseEnter={() => !disabled && !readOnly && setIsHovering(true)}
      onMouseLeave={() => !disabled && !readOnly && setIsHovering(false)}
    >
      <InputContainer
        $checked={value}
        $disabled={disabled}
        $readOnly={readOnly}
        $isHovering={isHovering}
        $focused={isFocused}
      >
        <input
          type="radio"
          disabled={disabled}
          readOnly={readOnly}
          defaultChecked={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        <InputDot $checked={value} />
      </InputContainer>

      {label && <Label $disabled={disabled}>{label}</Label>}
    </Container>
  )
}
