import { FC } from 'react'
import { useTheme } from 'styled-components'

export const SignicatLogo: FC = () => {
  const theme = useTheme()

  const bgColor = theme.signicatLogo.backgroundColor
  const textColor = theme.signicatLogo.color

  return (
    <svg width="60" height="32" viewBox="0 0 60 32">
      <path
        d="M29.8348 1.35779V7.61137C26.9995 3.13494 21.9686 0.15625 16.2245 0.15625C7.36798 0.15625 0.186523 7.2537 0.186523 15.9998C0.186523 24.7459 7.36798 31.8433 16.2302 31.8433C21.9686 31.8433 27.0052 28.8646 29.8404 24.3882V30.6418H59.4944V1.35779H29.8348Z"
        fill={bgColor}
      />
      <path
        d="M10.69 19.3194C12.6367 19.3194 13.9327 18.1849 13.9327 16.6536C13.9327 15.195 12.9423 14.4126 11.0861 14.0494C9.98259 13.8426 8.8734 13.4793 8.8734 12.5907C8.8734 12.0095 9.41668 11.5122 10.3957 11.5122C11.3747 11.5122 12.2406 11.9648 12.2406 12.6634C12.2406 12.8366 12.1953 12.954 12.15 13.0993L13.4743 13.552C13.6214 13.2893 13.7289 12.9708 13.7289 12.6019C13.7289 11.1601 12.3877 10.1709 10.5315 10.1709C8.68665 10.1709 7.48125 11.3054 7.48125 12.5852C7.48125 14.217 8.70362 14.9882 10.6051 15.2956C12.0199 15.5471 12.4613 16.0221 12.4613 16.6927C12.4613 17.4192 11.7992 17.9613 10.707 17.9613C9.69397 17.9613 8.76021 17.5254 8.76021 16.6369C8.76021 16.4915 8.78851 16.3295 8.83378 16.1562L7.50954 15.6197C7.37372 15.8712 7.25488 16.2289 7.25488 16.5977C7.2662 18.2408 8.5678 19.3194 10.69 19.3194Z"
        fill={textColor}
      />
      <path
        d="M14.9854 11.4285C14.9854 11.9091 15.3532 12.2724 15.8399 12.2724C16.3266 12.2724 16.7114 11.9091 16.7114 11.4285C16.7114 10.9479 16.3322 10.5679 15.8399 10.5679C15.3589 10.5679 14.9854 10.9479 14.9854 11.4285Z"
        fill={textColor}
      />
      <path d="M16.519 13.1719H15.1777V19.1852H16.519V13.1719Z" fill={textColor} />
      <path
        d="M20.6954 18.0396C19.7051 18.0396 18.9411 17.3578 18.9411 16.2066C18.9411 14.938 19.7107 14.1835 20.6954 14.1835C21.6971 14.1835 22.4498 14.9994 22.4498 16.1172C22.4441 17.2405 21.6801 18.0396 20.6954 18.0396ZM22.3875 13.1608V13.9767C22.0083 13.4067 21.4255 13.0435 20.4577 13.0435C18.8505 13.0435 17.5659 14.2953 17.5659 16.201C17.5659 18.034 18.7883 19.1853 20.3219 19.1853C21.3519 19.1853 22.0593 18.7494 22.3705 18.1067V18.7494C22.3705 19.8838 21.9291 20.683 20.7633 20.683C19.9201 20.683 19.2863 20.2639 19.0939 19.4479L17.8715 19.9565C18.3129 21.281 19.3485 21.8342 20.7633 21.8342C22.6931 21.8342 23.7118 20.5265 23.7118 18.6879V13.1552H22.3875V13.1608Z"
        fill={textColor}
      />
      <path
        d="M26.4561 15.8266C26.4561 14.7647 27.1069 14.1779 27.8992 14.1779C28.6519 14.1779 29.1499 14.7144 29.1499 15.7092V19.1909H30.4911V15.2565C30.4911 13.8426 29.5913 13.0435 28.352 13.0435C27.5993 13.0435 26.8919 13.3229 26.4335 14.0438V13.1664H25.0923V19.1797H26.4335V15.8154L26.4561 15.8266Z"
        fill={textColor}
      />
      <path
        d="M35.5563 16.1337C35.5563 14.8092 36.5184 14.1832 37.3559 14.1832C38.2557 14.1832 38.9065 14.7365 39.0254 15.6251L40.2477 15.2507C39.9931 13.8535 38.833 13.0376 37.3559 13.0376C35.6865 13.0376 34.2773 14.2894 34.2773 16.1392C34.2773 18.0449 35.562 19.3135 37.5483 19.3135C39.0254 19.3135 40.2477 18.4808 40.5703 16.8993L39.3479 16.4634C39.2121 17.352 38.6971 18.1791 37.4917 18.1791C36.5297 18.1735 35.5563 17.3967 35.5563 16.1337Z"
        fill={textColor}
      />
      <path
        d="M44.3279 18.1236C43.3659 18.1236 42.517 17.3803 42.517 16.2011C42.517 15.022 43.3602 14.234 44.3279 14.234C45.2277 14.234 46.1558 14.9773 46.1558 16.2011C46.1389 17.425 45.2164 18.1236 44.3279 18.1236ZM46.0992 13.1554V14.1334C45.7484 13.4516 45.024 13.0269 44.0506 13.0269C42.3585 13.0269 41.1758 14.3513 41.1758 16.2011C41.1758 17.9783 42.3868 19.3028 44.0506 19.3028C44.9787 19.3028 45.6861 18.9284 46.1728 18.1683V19.1854H47.4574V13.1554H46.0992Z"
        fill={textColor}
      />
      <path
        d="M51.7017 19.3084C52.8505 19.3084 53.6315 18.6098 53.7503 17.4027L52.4657 17.1512C52.4204 17.8497 52.1375 18.1683 51.6225 18.1683C51.2433 18.1683 50.8529 17.9168 50.8529 17.3356V14.3066H53.1674V13.1554H50.8529V11.2329H49.5173V13.1554H48.2949V14.2898H49.5173V17.2685C49.506 18.4757 50.2134 19.3084 51.7017 19.3084Z"
        fill={textColor}
      />
      <path
        d="M31.6855 11.4285C31.6855 11.9091 32.0534 12.2724 32.5401 12.2724C33.0268 12.2724 33.4116 11.9091 33.4116 11.4285C33.4116 10.9479 33.0324 10.5679 32.5401 10.5679C32.0534 10.5679 31.6855 10.9479 31.6855 11.4285Z"
        fill={textColor}
      />
      <path d="M33.2191 13.1719H31.8779V19.1852H33.2191V13.1719Z" fill={textColor} />
    </svg>
  )
}
