import { FC, useContext } from 'react'
import { Menu, MenuItem } from '@szhsin/react-menu'
import { StoreContext } from '@/components/App'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/zoom.css'

import { svgs } from '@/components/Icon/components/TagPillIcons'
import {
  InputContainer,
  InputWrapper,
  InputItems,
  InputItemRow,
  InputItemIcon,
  InputItemName,
  ColumnRow,
  ColumnIcon,
  ColumnName,
  StyledTagsInput,
  OpenButton,
} from './Filters.styles'

export const TagsInput: FC = () => {
  const store = useContext(StoreContext)
  const { transactionListHeaders: trans } = store.TranslationsState.translations
  const { transactionListFiltersPosition } = store.Settings
  const { tags } = store.TransactionListFilterState

  const renderTagState = (state: string) => {
    switch (state) {
      case 'include':
        return (
          <InputContainer style={{ color: 'white' }} $checked>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
          </InputContainer>
        )
      case 'exclude':
        return (
          <InputContainer style={{ color: 'white' }} $checked>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </InputContainer>
        )
      default:
        return (
          <InputContainer style={{ color: 'gray' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
            ></svg>
          </InputContainer>
        )
    }
  }

  const renderMenuItems = () =>
    tags.map((column) => (
      <MenuItem
        key={column.name}
        value={column.name}
        onClick={(e) => {
          e.stopPropagation = true
          e.keepOpen = true

          const newTags = [...tags]
          const tagIndex = tags.findIndex((t) => t.name === column.name)
          switch (column.state) {
            case '':
              newTags[tagIndex].state = 'include'
              break
            case 'include':
              newTags[tagIndex].state = 'exclude'
              break
            case 'exclude':
              newTags[tagIndex].state = ''
              break
          }
          store.TransactionListFilterState.setTags(newTags)
        }}
      >
        <ColumnRow>
          {renderTagState(column.state)}
          <ColumnIcon>{svgs[column.name]}</ColumnIcon>
          <ColumnName>{column.name}</ColumnName>
        </ColumnRow>
      </MenuItem>
    ))

  const renderInputItems = () => {
    const filteredTags = tags.filter((column) => ['include', 'exclude'].includes(column.state))

    if (filteredTags.length) {
      return (
        <InputItems>
          {filteredTags.map((column) => (
            <InputItemRow key={column.name} $state={column.state}>
              <InputItemIcon $state={column.state}>{svgs[column.name]}</InputItemIcon>
              <InputItemName $state={column.state}>{column.name}</InputItemName>
              <InputItemIcon
                $state={column.state}
                $cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const newTags = [...tags]
                  const tagIndex = tags.findIndex((t) => t.name === column.name)
                  newTags[tagIndex].state = ''
                  store.TransactionListFilterState.setTags(newTags)
                }}
              >
                {svgs['removeTag']}
              </InputItemIcon>
            </InputItemRow>
          ))}
        </InputItems>
      )
    }
    return trans.select
  }

  return (
    <InputWrapper style={{ gridArea: 'tags' }}>
      <label>{trans.tags}</label>
      <StyledTagsInput>
        <Menu
          menuButton={({ open }) => (
            <OpenButton $isOpen={open} disabled={!tags.length}>
              {transactionListFiltersPosition === 'left' ? renderInputItems() : trans.select}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.96 9.04023C18.2139 9.29407 18.2139 9.70563 17.96 9.95947L12.96 14.9595C12.7062 15.2133 12.2946 15.2133 12.0408 14.9595L7.04077 9.95947C6.78693 9.70563 6.78693 9.29408 7.04077 9.04023C7.29461 8.78639 7.70617 8.78639 7.96001 9.04023L12.5004 13.5806L17.0408 9.04023C17.2946 8.78639 17.7062 8.78639 17.96 9.04023Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </OpenButton>
          )}
        >
          {renderMenuItems()}
        </Menu>
      </StyledTagsInput>
    </InputWrapper>
  )
}
