import { styled } from 'styled-components'
import { FixedSizeList } from 'react-window'

export const Container = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 6px 16px 6px 8px;
  background-color: ${({ theme }) => theme.phoneInput.backgroundColor};
  border: ${({ theme }) => theme.phoneInput.border};
  display: flex;
  align-items: center;
  font-family: Inter;
  cursor: pointer;
`

export const PrefixButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  width: 54px;
  height: 28px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.phoneInput.hover.prefixButtonBackgroundColor};
  }
`

export const FlagEmoji = styled.div`
  width: 20px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: 'Twemoji Country Flags', Inter, Arial, sans-serif;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.phoneInput.iconColor};
  cursor: pointer;
`

export const PrefixLabel = styled.div`
  color: ${({ theme }) => theme.phoneInput.color};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
  cursor: default;
  user-select: none;
`

export const PhoneNumberInput = styled.input`
  border: none;
  height: 32px;
  margin-left: 6px;
  font-size: 14px;
  display: flex;
  padding-bottom: 2px;

  background-color: ${({ theme }) => theme.phoneInput.backgroundColor};
  color: ${({ theme }) => theme.phoneInput.color};
`

interface CountryPrefixContainerProps {
  width?: number
  height?: number
  $x?: number
  $y?: number
  $isOpen?: boolean
}

const PREFIX_CONTAINER_OFFSET = 8
const PREFIX_CONTAINER_HEIGHT = 304

export const CountryPrefixContainer = styled.div<CountryPrefixContainerProps>`
  width: ${({ width }) => width}px;
  height: ${PREFIX_CONTAINER_HEIGHT}px;
  position: absolute;
  top: ${({ $y, height }) => $y + height + PREFIX_CONTAINER_OFFSET}px;
  left: ${({ $x }) => $x}px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.phoneInput.backgroundColor};
  border-radius: 4px;
  border: ${({ theme }) => theme.phoneInput.border};
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.phoneInput.dropdownBoxShadow};
  flex-direction: column;
  gap: 8px;
  padding: 8px 0px;

  // #TODO fix scrollbar
  *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
`

export const SearchInput = styled.input`
  height: 48px;
  width: 100%;
  border: none;
  border-bottom: ${({ theme }) => theme.phoneInput.border};
  background-color: ${({ theme }) => theme.phoneInput.backgroundColor};
  padding: 10px 16px 10px 44px;
`

export const SearchIcon = styled.div`
  position: absolute;
  top: ${PREFIX_CONTAINER_OFFSET + 24}px;
  left: 16px;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.phoneInput.iconColor};
`

export const List = styled(FixedSizeList)`
  width: 100%;
  height: 240px;
`

export const ListItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;

  i,
  label,
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.phoneInput.hover.itemBgColor};
    cursor: pointer;
  }

  & > i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Twemoji Country Flags', Inter, Arial, sans-serif;
  }

  & > label {
    color: ${({ theme }) => theme.phoneInput.color};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > span {
    color: ${({ theme }) => theme.phoneInput.colorSecondary};
    margin-left: auto;
  }
`
