import { FC, ReactNode } from 'react'
import { StyledButton } from './Button.styles'

interface ButtonProps {
  children: ReactNode
  className?: any
  styleName?: 'primary' | 'secondary'
  width?: string
  widthMobile?: string
  disabled?: boolean
  onClick?: () => void
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  styleName,
  width,
  widthMobile,
  disabled,
  onClick,
}) => {
  const handleOnClick = () => {
    onClick && onClick()
  }

  return (
    <StyledButton
      width={width}
      $widthMobile={widthMobile}
      disabled={disabled}
      onClick={handleOnClick}
      className={className ? className : ''}
      $styleName={styleName ? styleName : 'primary'}
    >
      {children}
    </StyledButton>
  )
}
