import { css, styled } from 'styled-components'
import { FILTER_CONTAINER_HEIGHT, FILTER_CONTAINER_HEIGHT_MOBILE } from '../Filters/Filters.styles'
import { TransactionListFiltersPosition } from '@/store/Settings.store'

const SPACINGS_SUM = 64 + 80 + 40 + 70
// const SPACINGS_SUM = 64 + 80
const computeWidths = (columnsWidths: number[]) => {
  const widthsArr = []

  Object.keys(columnsWidths).forEach((column) => {
    widthsArr.push(columnsWidths[column] + 'px')
  })

  // widthsArr[widthsArr.length - 1] = 'auto'
  widthsArr[widthsArr.length - 1] = `minmax(${widthsArr[widthsArr.length - 1]}, auto)`

  return widthsArr.join(' ')
}

export const TableContainer = styled.div<{
  $filterPosition?: TransactionListFiltersPosition
  $isSideMenuOpen?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1px;
  transition: width 0.4s;

  ${({ $filterPosition }) =>
    $filterPosition === 'top' &&
    css`
      min-height: ${`calc(100vh - ${SPACINGS_SUM}px - ${FILTER_CONTAINER_HEIGHT}px)`};
      @container (width < 1024px) {
        min-height: ${`calc(100vh - ${SPACINGS_SUM}px - ${FILTER_CONTAINER_HEIGHT_MOBILE}px)`};
      }
    `}

  ${({ $filterPosition, $isSideMenuOpen }) =>
    $filterPosition === 'left' &&
    $isSideMenuOpen &&
    css`
      min-height: calc(100vh - ${SPACINGS_SUM}px);
      grid-column: 2 / 3;
      width: calc(100vw - 743px);
    `}

    ${({ $filterPosition, $isSideMenuOpen }) =>
    $filterPosition === 'left' &&
    !$isSideMenuOpen &&
    css`
      min-height: calc(100vh - ${SPACINGS_SUM}px);
      grid-column: 2 / 3;
      width: calc(100vw - 560px);
    `}
`

interface TableProps {
  $columns: number
  $columnsWidths: number[]
}

export const Table = styled.div<TableProps>`
  display: grid;
  grid-template-columns: ${({ $columnsWidths }) => computeWidths($columnsWidths)};
  grid-template-rows: 40px;
  grid-gap: 0;
  border-radius: 4px;
  overflow-x: auto;
  position: relative;
  width: 100%;
  height: calc(100vh - 290px);
  /* height: 95%; */
`

export const TableCell = styled.div<{ $isHovering?: boolean }>`
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.table.row.color};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  a {
    color: ${({ theme }) => theme.table.row.linkColor};
  }

  ${({ $isHovering, theme }) =>
    $isHovering &&
    css`
      background-color: ${theme.table.row.bgColorHover};
      cursor: pointer;
    `}
`

export const TableHeaderCell = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  background-color: ${({ theme }) => theme.table.header.bgColor};
  border-bottom: ${({ theme }) => theme.table.border};
  color: ${({ theme }) => theme.table.header.color} !important;
  &:hover {
    color: ${({ theme }) => theme.table.header.color} !important;
  }
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  position: sticky;
  top: 0;

  &:first-child {
    border-left: 0;
  }

  span {
    color: ${({ theme }) => theme.table.header.colorSecondary};
  }
`

export const ColumnManager = styled.div`
  color: white;
  display: flex;
  height: 32px;
  margin-bottom: -36px;
  padding-right: 8px;
  z-index: 1;
  margin-left: auto;
`

export const IDWrap = styled.span`
  font-family: 'Commit Mono', monospace;
`
