import { Animatable } from '@/styles/generic.styles'
import { createGlobalStyle, styled } from 'styled-components'

export const SelectMenuPortalGlobalStyle = createGlobalStyle<{
  $parent: string
}>`
  .select-tenant__menu-portal {
    .select-tenant {
      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
      }

      &__menu {
        right: 0;
        width: ${({ $parent }) => ($parent === 'topMenu' ? 'max-content' : '100%')};
        min-width: 100%;
        color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.color};
        background-color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.bgColor};
        border: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.border};
      }

      &__option {
        &--is-selected {
          width: 100%;
          outline: none;
          background-color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.option.bgColorSelected} !important;
          color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.option.colorSelected} !important;
        }

        &--is-focused,
        &--is-disabled {
          width: 100%;
          outline: none;
          color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.option.colorHover};
          background-color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.menu.option.bgColorHover};
        }

        ${Animatable}
      }
      }

      &__single-value {
        color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.color};
      }
  }
`

export const TenantSelector = styled.div<{
  $hideOnMobile?: boolean
  $parent: string
}>`
  height: 28px;
  padding: 0 4px 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.border};

  span {
    width: 76px;
    height: 20px;
    display: block;
    overflow: hidden;
    word-break: keep-all;
    color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.secondaryColor};
    font-family: Inter;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 980px) {
    border: 0;
    span {
      display: none;
    }
  }
`

export const TenantDropdownWrapper = styled.div<{
  $parent: string
}>`
  height: 20px;
  width: 100%;

  & > div {
    .select-tenant {
      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
      }

      &__control {
        min-height: 20px;
        height: 20px;
        color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.color};
        background-color: transparent;

        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        padding: 0px 4px 0px 0px;
        box-shadow: none;
        border: 0;

        :hover {
          box-shadow: none;
        }

        &--is-focused {
          box-shadow: none;
        }

        &--is-disabled {
          box-shadow: none;
        }

        ${Animatable}
      }

      &__single-value {
        color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.color};
      }

      &__value-container {
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        display: flex;
        align-items: center;

        color: ${({ $parent, theme }) => theme[$parent].tenantSwitcher.color};

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }
`
