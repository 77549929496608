import { FC, useContext } from 'react'
import { LoadingSpinner, Popup } from '@components/Generic'
import { observer } from 'mobx-react'
import { StoreContext } from './App'
import { useTheme } from 'styled-components'
import { DEV_MODE } from '@/constants/envs'
import { TenantSwitcher } from './TenantSwitcher/TenantSwitcher'

export const TenantPromptPopup: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { menu: trans } = store.TranslationsState.translations
  const { isLogged, shouldLogout } = store.Auth
  const { tenants, selectedTenant } = store.AppState

  const title = tenants?.length ? `${trans.selectTenant} (${trans.clientID})` : ''

  if (!DEV_MODE) {
    return (
      <Popup
        visibility={
          (!localStorage.getItem('selectedTenant')?.length ||
            selectedTenant === 'null' ||
            selectedTenant === 'undefined' ||
            !selectedTenant?.length) &&
          isLogged &&
          !shouldLogout
        }
        {...theme.popup}
        title={title}
        closeIconHidden={true}
      >
        {tenants?.length ? (
          <>
            <p>{trans.setActiveTenant}</p>
            <TenantSwitcher parent="popup" />
          </>
        ) : (
          <LoadingSpinner centered height="128px" width="40px" />
        )}
      </Popup>
    )
  } else {
    return <></>
  }
})
