import styled, { createGlobalStyle } from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const SelectMenuPortalGlobalStyle = createGlobalStyle`
  .select-input__menu-portal {
    .select-input {
      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
      }

      &__menu {
        right: 0;
        width: max-content;
        min-width: 100%;
        color: ${({ theme }) => theme.selectInput.menu.color};
        background-color: ${({ theme }) => theme.selectInput.menu.bgColor};
        border: ${({ theme }) => theme.selectInput.menu.border};
      }

      &__option {
        &--is-selected {
          width: 100%;
          outline: none;
          background-color: ${({ theme }) => theme.selectInput.menu.option.bgColorSelected} !important;
          color: ${({ theme }) => theme.selectInput.menu.option.colorSelected} !important;
        }

        &--is-focused,
        &--is-disabled {
          width: 100%;
          outline: none;
          color: ${({ theme }) => theme.selectInput.menu.option.colorHover};
          background-color: ${({ theme }) => theme.selectInput.menu.option.bgColorHover};
        }

        ${Animatable}
      }
      }

      &__single-value {
        color: ${({ theme }) => theme.selectInput.color};
      }
  }
`

export const StyledSelectWrapper = styled.div`
  width: 100%;

  & > div {
    .select-input {
      &__control {
        display: flex;
        padding: 6px 16px 6px 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: ${({ theme }) => theme.selectInput.border};
        color: ${({ theme }) => theme.selectInput.color};
        background: ${({ theme }) => theme.selectInput.bgColor};

        :hover {
          box-shadow: none;
          border: ${({ theme }) => theme.selectInput.hover.border};
        }

        &--is-focused,
        &--is-disabled {
          box-shadow: none;
        }

        &--menu-is-open {
          border-radius: 4px;
          border: ${({ theme }) => theme.selectInput.focus.border};
          box-shadow: ${({ theme }) => theme.selectInput.focus.boxShadow};
        }

        &--is-disabled {
          color: ${({ theme }) => theme.selectInput.disabled.color};
          border: ${({ theme }) => theme.selectInput.disabled.border};
          background: ${({ theme }) => theme.selectInput.disabled.bgColor};
        }

        ${Animatable}
      }

      &__single-value {
        color: ${({ theme }) => theme.selectInput.color};
      }

      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.selectInput.color};
        font-style: normal;
        font-weight: 400;
      }

      &__indicator-separator {
        display: none;
      }

      &__indicator {
        padding: 0 !important;
      }

      &__menu {
        margin-top: 4px;
        z-index: 9999;
      }
    }
  }
`
