import { RefObject, useEffect } from 'react'

// Modified version of hook to handle multiple refs
export const useClickOutside = (refs: Array<RefObject<HTMLElement>>, handler: any) => {
  useEffect(() => {
    let startedInside = false
    let startedWhenMounted = false

    const listener = (event: { target: any }) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return
      // Do nothing if clicking refs's element or descendent elements
      refs.forEach((ref) => {
        if (!ref.current || ref.current.contains(event.target)) return
      })

      handler(event)
    }

    const validateEventStart = (event: { target: any }) => {
      startedWhenMounted = refs.map((ref) => ref.current).some((ref) => ref)
      startedInside = refs
        .map((ref) => ref.current)
        .some((ref) => ref && ref.contains(event.target))
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.removeEventListener('click', listener)
    }
  }, [refs, handler])
}
