import { observer } from 'mobx-react'
import { FC, useContext, useEffect, useState } from 'react'
import {
  BackButton,
  Content,
  DataColumn,
  DataGrid,
  SearchInput,
  SearchInputContainer,
  Buttons,
  Title,
  TopContent,
} from './TransactionDetailsPage.styles'

import { SearchIcon } from './Icons/SearchIcon'
import { ProviderData } from './Components/ProviderData'
import { BasicInfo } from './Components/BasicInfo/BasicInfo'
import { StoreContext } from '@/components/App'
import { SidebarPopup, LoadingSpinner } from '@components/Generic'
import { useTheme } from 'styled-components'
import { Icon } from '@/components/Icon/Icon'
import { IconButton } from '@/components/IconButton/IconButton'
import { useParams } from 'wouter'
import { VerticalSpacer } from '@/styles/generic.styles'

export const TransactionDetailsPage: FC = observer(() => {
  const theme = useTheme()
  const params = useParams()
  const queryParams = new URL(document.location.href).searchParams

  const transactionId = params[0]
  const isV3 = queryParams.get('isV3') === 'on'
  const selectedTenant = queryParams.get('tenantId')

  const store = useContext(StoreContext)
  const {
    singleTransactionPage: trans,
    singleTransactionHistory: transHistory,
    singleTransactionAuditLog: transAuditLog,
  } = store.TranslationsState.translations
  const { transaction, providers, isTransactionLoading } = store.TransactionDetailsState

  const [isHistoryPopupVisible, setIsHistoryPopupVisible] = useState(false)
  const [isAuditlogPopupVisible, setIsAuditlogPopupVisible] = useState(false)

  const oddProviders = providers.filter((_, i) => i % 2 === 0)
  const evenProviders = providers.filter((_, i) => i % 2 !== 0)

  useEffect(() => {
    if (!transactionId) return
    void store.TransactionDetailsState.initTransactionPreview({
      transactionId,
      isV3,
      selectedTenant,
    })
  }, [])

  return (
    <Content>
      {isTransactionLoading || !transaction?.transactionId ? (
        <LoadingSpinner centered height="calc(100vh - 220px)" />
      ) : (
        <>
          <TopContent>
            <BackButton to={'/transactions'}>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                  d="M9.20032 14.5497C9.41185 14.7612 9.75481 14.7612 9.96635 14.5497C10.1779 14.3381 10.1779 13.9952 9.96635 13.7836L6.72437 10.5417L14.5833 10.5417C14.8824 10.5417 15.125 10.2991 15.125 9.99999C15.125 9.70084 14.8824 9.45832 14.5833 9.45832L6.72437 9.45832L9.96635 6.21634C10.1779 6.00481 10.1779 5.66184 9.96635 5.45031C9.75482 5.23877 9.41185 5.23877 9.20032 5.45031L5.03365 9.61697C4.82212 9.82851 4.82212 10.1715 5.03365 10.383L9.20032 14.5497Z"
                  fill="currentColor"
                />
              </svg>
              {trans.goBack}
            </BackButton>

            <Title>
              {trans.pageTitle}:<span> {transaction.transactionId}</span>
            </Title>
            <Buttons>
              <IconButton onClick={() => setIsHistoryPopupVisible(true)} iconType="file-download" />
              <IconButton onClick={() => setIsHistoryPopupVisible(true)} iconType="history" />
              <IconButton onClick={() => setIsAuditlogPopupVisible(true)} iconType="file-lookup" />
              <IconButton
                onClick={() => setIsHistoryPopupVisible(true)}
                iconType="transaction-delete"
              />
            </Buttons>
          </TopContent>

          {/* <VerticalSpacer $size={32} />

          <SearchInputContainer>
            <SearchInput placeholder={trans.search} />
            <SearchIcon />
          </SearchInputContainer> */}

          <VerticalSpacer $size={32} />

          <BasicInfo transaction={transaction} providers={providers} />

          <VerticalSpacer $size={32} />

          <DataGrid>
            <DataColumn>
              {evenProviders.map((provider, v) => (
                <ProviderData key={v} provider={provider} />
              ))}
            </DataColumn>
            <DataColumn>
              {oddProviders.map((provider, v) => (
                <ProviderData key={v} provider={provider} />
              ))}
            </DataColumn>
          </DataGrid>

          <SidebarPopup
            visibility={isHistoryPopupVisible}
            {...theme.popup}
            header={
              <>
                <Icon size="40px" type="history" />
                <h3>{transHistory.pageTitle}</h3>
                <IconButton onClick={() => console.log('refresh')} iconType="refresh" />
              </>
            }
            handleVisibility={() => setIsHistoryPopupVisible(false)}
            width="670px"
          >
            {/* CONTENT */}
          </SidebarPopup>

          <SidebarPopup
            visibility={isAuditlogPopupVisible}
            {...theme.popup}
            header={
              <>
                <Icon size="40px" type="file-lookup" />
                <h3>{transAuditLog.pageTitle}</h3>
                <IconButton onClick={() => console.log('refresh')} iconType="refresh" />
              </>
            }
            handleVisibility={() => setIsAuditlogPopupVisible(false)}
            width="670px"
          >
            {/* CONTENT */}
          </SidebarPopup>
        </>
      )}
    </Content>
  )
})
