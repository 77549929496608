export const TransactionDelete = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path
        d="M9.478 4.01292H10.522C10.9398 4.01292 11.3217 4.25654 11.5085 4.64221L11.6939 5.02482H8.30615L8.4915 4.64221C8.67833 4.25654 9.06023 4.01292 9.478 4.01292ZM12.8813 5.02482L12.4585 4.15196C12.0917 3.3949 11.3421 2.91669 10.522 2.91669H9.478C8.65794 2.91669 7.90827 3.3949 7.54153 4.15196L7.11869 5.02482H4.28105C3.98776 5.02482 3.75 5.27022 3.75 5.57294C3.75 5.87565 3.98776 6.12105 4.28105 6.12105H5.00588C4.98597 6.21618 4.97549 6.31492 4.97549 6.41619V13.1622C4.97549 15.3278 6.67637 17.0834 8.77451 17.0834H11.2255C13.3236 17.0834 15.0245 15.3278 15.0245 13.1622V6.41619C15.0245 6.31492 15.014 6.21618 14.9941 6.12105H15.719C16.0122 6.12105 16.25 5.87565 16.25 5.57294C16.25 5.27022 16.0122 5.02482 15.719 5.02482H12.8813ZM13.6765 6.12105C13.8344 6.12105 13.9624 6.25319 13.9624 6.41619V13.1622C13.9624 14.7224 12.7371 15.9871 11.2255 15.9871H8.77451C7.26295 15.9871 6.03758 14.7224 6.03758 13.1622V6.41619C6.03758 6.25319 6.16561 6.12105 6.32353 6.12105H13.6765ZM8.57026 8.39784C8.86355 8.39784 9.10131 8.64324 9.10131 8.94595V13.1622C9.10131 13.4649 8.86355 13.7103 8.57026 13.7103C8.27697 13.7103 8.03922 13.4649 8.03922 13.1622V8.94595C8.03922 8.64324 8.27697 8.39784 8.57026 8.39784ZM11.4297 8.39784C11.1365 8.39784 10.8987 8.64324 10.8987 8.94595V13.1622C10.8987 13.4649 11.1365 13.7103 11.4297 13.7103C11.723 13.7103 11.9608 13.4649 11.9608 13.1622V8.94595C11.9608 8.64324 11.723 8.39784 11.4297 8.39784Z"
        fill="transactionDelete"
      />
    </svg>
  )
}
