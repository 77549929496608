import { createContext, FC, useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { observer } from 'mobx-react'
import { Helmet } from './Helmet'
import { HelmetProvider } from 'react-helmet-async'
import { ContentRouter } from './ContentRouter'
import { rootStore } from '@store/Root.store'
import { AppInitLoading, GlobalStyles } from '@styles/App.styles'
import { themes } from '@/themes/themes'
import { AxiosErrorHandler } from '@/axios/AxiosErrorHandler'
import { Toasts } from './Generic/Toasts/Toasts'
import { useDarkMode } from '@hooks/useDarkmode'
import { TenantPromptPopup } from './TenantPromptPopup'
import { DEV_MODE } from '@/constants/envs'
import { AuthHandler } from './AuthHandler'
import { I18nProvider } from 'react-aria-components'
import { LoadingSpinner } from './Generic'

export const StoreContext = createContext(rootStore)

export const App: FC = observer(() => {
  const { isDarkMode } = useDarkMode()
  const theme = isDarkMode ? themes.darkTheme : themes.lightTheme
  const store = useContext(StoreContext)
  const { currentUserId, isLogged, shouldLogout, isCheckingAuth } = store.Auth

  useEffect(() => {
    if (isLogged && !shouldLogout && !DEV_MODE) {
      void store.AppState.fetchTenants()
      store.Auth.initKeepSessionAliveInterval()
      return
    }

    return () => {
      store.Auth.disposeKeepSessionAliveInterval()
    }
  }, [isLogged, shouldLogout])

  useEffect(() => {
    console.log('DEV_MODE: ', DEV_MODE, !DEV_MODE && 'Checking auth...')
    if (!DEV_MODE) {
      void store.Auth.checkAuth()
    }
  }, [currentUserId, isLogged, shouldLogout])

  return (
    <HelmetProvider>
      <StoreContext.Provider value={rootStore}>
        <Helmet
          title="Signicat Onboarding Dashboard"
          description="Onboarding Dashboard"
          themeColor="#320822"
        />
        <I18nProvider locale="en-US">
          <ThemeProvider theme={theme}>
            <AxiosErrorHandler>
              <AuthHandler>
                <GlobalStyles />
                <ContentRouter />
                <Toasts />

                {isCheckingAuth ? (
                  <AppInitLoading>
                    <LoadingSpinner centered />
                  </AppInitLoading>
                ) : (
                  <TenantPromptPopup />
                )}
              </AuthHandler>
            </AxiosErrorHandler>
          </ThemeProvider>
        </I18nProvider>
      </StoreContext.Provider>
    </HelmetProvider>
  )
})
