import { FC } from 'react'
import { useTheme } from 'styled-components'

export const SignicatLogo: FC = () => {
  const theme = useTheme()

  const logoBgColor = theme.loginPage.logo.bgColor
  const textColor = theme.loginPage.logo.color

  return (
    <svg
      width="105"
      height="57"
      viewBox="0 0 105 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_233_6305)">
        <path
          d="M52.4951 2.41905V13.5582C47.5064 5.58461 38.6544 0.278809 28.5477 0.278809C12.9645 0.278809 0.328613 12.9211 0.328613 28.5001C0.328613 44.079 12.9645 56.7214 28.5576 56.7214C38.6544 56.7214 47.5164 51.4156 52.505 43.4419V54.5811H104.681V2.41905H52.4951Z"
          fill={logoBgColor}
        />
        <path
          d="M18.8092 34.4134C22.2346 34.4134 24.5148 32.3926 24.5148 29.665C24.5148 27.0669 22.7722 25.6732 19.5062 25.0262C17.5646 24.6578 15.6129 24.0108 15.6129 22.428C15.6129 21.3927 16.5688 20.5068 18.2915 20.5068C20.0141 20.5068 21.5375 21.3131 21.5375 22.5574C21.5375 22.866 21.4579 23.0751 21.3782 23.3339L23.7082 24.1402C23.9671 23.6723 24.1563 23.1049 24.1563 22.4479C24.1563 19.8796 21.7964 18.1177 18.5304 18.1177C15.2843 18.1177 13.1634 20.1385 13.1634 22.4181C13.1634 25.3248 15.3142 26.6985 18.6599 27.246C21.1492 27.694 21.9259 28.5401 21.9259 29.7347C21.9259 31.0288 20.7609 31.9944 18.8391 31.9944C17.0567 31.9944 15.4138 31.2179 15.4138 29.6351C15.4138 29.3763 15.4636 29.0876 15.5432 28.7791L13.2132 27.8234C12.9742 28.2714 12.7651 28.9085 12.7651 29.5655C12.7851 32.4921 15.0752 34.4134 18.8092 34.4134Z"
          fill={textColor}
        />
        <path
          d="M26.3667 20.3572C26.3667 21.2133 27.0139 21.8604 27.8703 21.8604C28.7266 21.8604 29.4037 21.2133 29.4037 20.3572C29.4037 19.5011 28.7365 18.8242 27.8703 18.8242C27.0239 18.8242 26.3667 19.5011 26.3667 20.3572Z"
          fill={textColor}
        />
        <path d="M29.0655 23.4634H26.7056V34.1745H29.0655V23.4634Z" fill={textColor} />
        <path
          d="M36.4136 32.1338C34.6711 32.1338 33.3269 30.9193 33.3269 28.8687C33.3269 26.609 34.6811 25.2651 36.4136 25.2651C38.1761 25.2651 39.5004 26.7185 39.5004 28.7094C39.4904 30.7103 38.1462 32.1338 36.4136 32.1338ZM39.3909 23.4434V24.8968C38.7237 23.8814 37.6981 23.2344 35.9954 23.2344C33.1675 23.2344 30.9072 25.4642 30.9072 28.8587C30.9072 32.1238 33.058 34.1745 35.7564 34.1745C37.5687 34.1745 38.8133 33.398 39.361 32.2532V33.398C39.361 35.4188 38.5843 36.8423 36.5331 36.8423C35.0495 36.8423 33.9343 36.0957 33.5957 34.6423L31.4449 35.5482C32.2216 37.9074 34.0438 38.8929 36.5331 38.8929C39.9286 38.8929 41.7209 36.5636 41.7209 33.2885V23.4335H39.3909V23.4434Z"
          fill={textColor}
        />
        <path
          d="M46.5501 28.1918C46.5501 26.3004 47.6952 25.2552 49.0892 25.2552C50.4136 25.2552 51.2898 26.2108 51.2898 27.9827V34.1844H53.6497V27.1764C53.6497 24.6579 52.0665 23.2344 49.8858 23.2344C48.5615 23.2344 47.3168 23.7321 46.5103 25.0162V23.4534H44.1504V34.1645H46.5103V28.1719L46.5501 28.1918Z"
          fill={textColor}
        />
        <path
          d="M62.5619 28.7395C62.5619 26.3802 64.2546 25.2653 65.7283 25.2653C67.3115 25.2653 68.4566 26.2508 68.6657 27.8336L70.8165 27.1666C70.3684 24.678 68.3272 23.2246 65.7283 23.2246C62.7909 23.2246 60.3115 25.4544 60.3115 28.7494C60.3115 32.1439 62.5718 34.4036 66.0669 34.4036C68.6657 34.4036 70.8165 32.9204 71.3841 30.1032L69.2333 29.3268C68.9943 30.9096 68.0882 32.3828 65.9673 32.3828C64.2745 32.3729 62.5619 30.9892 62.5619 28.7395Z"
          fill={textColor}
        />
        <path
          d="M77.9955 32.2832C76.3027 32.2832 74.8091 30.9592 74.8091 28.8588C74.8091 26.7584 76.2927 25.3548 77.9955 25.3548C79.5787 25.3548 81.2117 26.6787 81.2117 28.8588C81.1818 31.0389 79.5588 32.2832 77.9955 32.2832ZM81.1121 23.4335V25.1756C80.4947 23.9611 79.2202 23.2046 77.5075 23.2046C74.5303 23.2046 72.4492 25.5638 72.4492 28.8588C72.4492 32.0244 74.5801 34.3836 77.5075 34.3836C79.1405 34.3836 80.3852 33.7166 81.2415 32.3628V34.1745H83.5019V23.4335H81.1121Z"
          fill={textColor}
        />
        <path
          d="M90.9699 34.3937C92.9912 34.3937 94.3654 33.1493 94.5745 30.9991L92.3141 30.5512C92.2345 31.7955 91.7366 32.3629 90.8305 32.3629C90.1634 32.3629 89.4763 31.915 89.4763 30.8797V25.4843H93.5489V23.4337H89.4763V20.0093H87.1264V23.4337H84.9756V25.4544H87.1264V30.7602C87.1065 32.9104 88.3511 34.3937 90.9699 34.3937Z"
          fill={textColor}
        />
        <path
          d="M55.751 20.3572C55.751 21.2133 56.3982 21.8604 57.2545 21.8604C58.1109 21.8604 58.788 21.2133 58.788 20.3572C58.788 19.5011 58.1208 18.8242 57.2545 18.8242C56.3982 18.8242 55.751 19.5011 55.751 20.3572Z"
          fill={textColor}
        />
        <path d="M58.4492 23.4634H56.0894V34.1745H58.4492V23.4634Z" fill={textColor} />
      </g>
      <defs>
        <clipPath id="clip0_233_6305">
          <rect width="105" height="57" fill={textColor} />
        </clipPath>
      </defs>
    </svg>
  )
}
