import { action, makeAutoObservable, runInAction } from 'mobx'
import { tagsOptions } from '../constants/settings'
import { makeLocalStorage } from '../helpers/localStorageHelpers'
import { RootStore } from './Root.store'

export type TransactionListFiltersPosition = 'left' | 'top'

export class SettingsStore {
  tagsSettings = tagsOptions[0].value
  transactionListFiltersPosition: TransactionListFiltersPosition = 'top'

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore

    makeLocalStorage(this, 'settingsStore', ['tagsSettings', 'transactionListFiltersPosition'])
  }

  resetSettings(): void {
    runInAction(() => {
      this.tagsSettings = tagsOptions[0].value
      this.transactionListFiltersPosition = 'top'
    })
  }

  @action.bound setTagsSettings = (tagsSettings: string): void => {
    this.tagsSettings = tagsSettings
  }

  @action.bound setTransactionListFiltersPosition = (
    position: TransactionListFiltersPosition
  ): void => {
    this.transactionListFiltersPosition = position
  }

  @action.bound toggleTransactionListFiltersPosition = (): void => {
    const isTop = this.transactionListFiltersPosition === 'top'
    this.transactionListFiltersPosition = isTop ? 'left' : 'top'
  }
}
