import styled from 'styled-components'

export const FullscreenWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.popup.wrapBgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  @media (max-width: 980px) {
    height: 100vh !important;
    position: fixed !important;
  }
`

export const Container = styled.div`
  display: flex;
  width: 664px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 8px;
  border: ${({ theme }) => theme.popup.border};
  background: ${({ theme }) => theme.popup.bgColor};
  box-shadow: ${({ theme }) => theme.popup.boxShadow};

  @media (max-width: 980px) {
    height: 100%;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

export const PopupHeader = styled.header`
  display: flex;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
`

export const PopupTitle = styled.h1`
  color: ${({ theme }) => theme.popup.color};
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
`

export const PopupBody = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;

  p {
    color: ${({ theme }) => theme.popup.color};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`

export const PopupFooter = styled.footer`
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  position: relative;
`
