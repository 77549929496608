import { TransactionListFiltersPosition } from '@/store/Settings.store'
import { Animatable } from '@/styles/generic.styles'
import { MenuButton } from '@szhsin/react-menu'
import { css, styled } from 'styled-components'

export const FILTER_CONTAINER_HEIGHT = 100 + 64
export const FILTER_CONTAINER_HEIGHT_MOBILE = 174 + 64

export const ResetButton = styled.button`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: ${({ theme }) => theme.transactionListPage.filters.resetButton.border};
  background: ${({ theme }) => theme.transactionListPage.filters.resetButton.bgColor};
  color: ${({ theme }) => theme.transactionListPage.filters.resetButton.color};
  margin-bottom: 4px;
  align-self: end;

  &:hover {
    filter: ${({ theme }) => theme.effects.hover.brightness};
  }
`

export const Container = styled.div<{
  $filterPosition?: TransactionListFiltersPosition
}>`
  position: relative;
  z-index: 1;
  width: 100%;
  backdrop-filter: blur(10px);
  color: ${({ theme }) => theme.transactionListPage.title.color};

  ${({ $filterPosition }) =>
    $filterPosition === 'top' &&
    css`
      margin: 32px 0;
      padding: 16px;
      display: grid;
      gap: 8px;
      grid-template-columns:
        minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr)
        120px 120px
        minmax(120px, 180px) minmax(120px, 180px)
        32px;
      grid-template-areas: 'transactionId configurationId userRef status tags startDate endDate reset';

      @container (width < 1120px) {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    `}

  ${({ $filterPosition }) =>
    $filterPosition === 'left' &&
    css`
      top: 0px;
      position: sticky;
      height: calc(100vh - 64px);
      border-right: ${({ theme }) => theme.transactionListPage.filters.inputBorder};
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin: -40px 0 -56px -40px;
      padding: 40px;

      overflow-y: auto;

      & > div > div {
        width: 100%;
      }

      ${ResetButton} {
        display: none;
      }
    `}

  label {
    color: ${({ theme }) => theme.transactionListPage.filters.labelColor};
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const DynamicFlexWrap = styled.div<{
  $filterPosition?: TransactionListFiltersPosition
}>`
  display: contents;

  ${({ $filterPosition }) =>
    $filterPosition === 'top' &&
    css`
      @container (width < 1120px) {
        height: 65px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        & > div {
          flex: 1;
        }
      }
    `}
`

export const InputWrapper = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
`

export const StyledTagsInput = styled.div`
  position: relative;
  width: 100%;

  .szh-menu-container {
    z-index: 99999;
    min-width: 100%;

    ul {
      top: 5px !important;
      background-color: ${({ theme }) => theme.table.columnVisibilityManager.bgColor};
      border-radius: 4px;
      border: ${({ theme }) => theme.table.columnVisibilityManager.border};
      min-width: 100%;
    }
  }

  .szh-menu {
    &--state-open {
      li {
        padding: 0 !important;
      }
    }

    &__item--hover {
      background-color: transparent !important;
    }
  }

  ${Animatable}
`

export const InputItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const InputItemRow = styled.div<{ $state: string }>`
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: ${({ $state, theme }) => theme.selectInput.tags[$state].border};
  background: ${({ $state, theme }) => theme.selectInput.tags[$state].bgColor};
`

export const InputItemIcon = styled.div<{ $state: string; $cursor?: string }>`
  width: 20px;
  color: ${({ $state, theme }) => theme.selectInput.tags[$state].color};
  cursor: ${({ $cursor }) => $cursor || 'default'};

  svg {
    width: 20px;
    fill: ${({ $state, theme }) => theme.selectInput.tags[$state].color};
  }
`

export const InputItemName = styled.div<{ $state: string }>`
  cursor: pointer;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ $state, theme }) => theme.selectInput.tags[$state].color};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const ColumnRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  padding: 10px 16px;

  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }
`

export const ColumnIcon = styled.div`
  width: 20px;
  color: ${({ theme }) => theme.selectInput.color};

  svg {
    width: 20px;
    fill: ${({ theme }) => theme.selectInput.color};
  }
`

export const ColumnName = styled.div`
  cursor: pointer;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.table.columnVisibilityManager.color};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const OpenButton = styled(MenuButton)<{ $isOpen?: boolean }>`
  min-height: 40px;
  width: 100%;
  display: flex;
  padding: 6px 16px 6px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 4px;
  font-size: inherit;
  border: ${({ $isOpen, theme }) =>
    $isOpen ? theme.selectInput.focus.border : theme.selectInput.border};
  box-shadow: ${({ $isOpen, theme }) => ($isOpen ? theme.selectInput.focus.boxShadow : 'none')};
  color: ${({ theme }) => theme.selectInput.color};
  background: ${({ theme }) => theme.selectInput.bgColor};

  :hover {
    box-shadow: none;
    border: ${({ theme }) => theme.selectInput.hover.border};
  }

  span {
    color: ${({ theme }) => theme.selectInput.color};
  }

  ${Animatable}
`

export const InputContainer = styled.div<{
  $checked?: boolean
}>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  transition:
    border 0.3s,
    background-color 0.3s,
    color 0.3s;

  svg {
    width: 20px;
    height: 20px;
    transition: opacity 0.3s;
    opacity: ${({ $checked }) => ($checked ? 1 : 0)};
  }

  /* === Default === */
  /* unchecked */
  background-color: ${({ theme }) => theme.checkbox.unchecked.backgroundColor};
  border: ${({ theme }) => theme.checkbox.unchecked.border};

  /* checked */
  ${({ $checked, theme }) =>
    $checked &&
    css`
      color: ${theme.checkbox.checked.color};
      border: ${`1px solid ${theme.checkbox.checked.backgroundColor}`};
      background-color: ${theme.checkbox.checked.backgroundColor};
    `}
`
