import { styled } from 'styled-components'
import { StyledHamburger, StyledUser } from '../TopMenu.styles'
import { FC } from 'react'

interface HamburgerProps {
  onClick: () => void
}

const StyledAvatar = styled.div`
  background-color: ${({ theme }) => theme.topMenu.userInfo.iconBgColor};
  color: ${({ theme }) => theme.topMenu.userInfo.iconColor};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Hamburger: FC<HamburgerProps> = ({ onClick }) => {
  return (
    <StyledHamburger onClick={onClick}>
      <StyledAvatar>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 7.13274C3.5 6.78329 3.78597 6.5 4.13873 6.5L19.8613 6.5C20.214 6.5 20.5 6.78329 20.5 7.13274C20.5 7.4822 20.214 7.76549 19.8613 7.76549L4.13873 7.76549C3.78597 7.76549 3.5 7.4822 3.5 7.13274ZM3.5 12C3.5 11.6505 3.78597 11.3673 4.13873 11.3673L19.8613 11.3673C20.214 11.3673 20.5 11.6505 20.5 12C20.5 12.3495 20.214 12.6327 19.8613 12.6327L4.13873 12.6327C3.78597 12.6327 3.5 12.3495 3.5 12ZM3.5 16.8673C3.5 16.5178 3.78597 16.2345 4.13873 16.2345L19.8613 16.2345C20.214 16.2345 20.5 16.5178 20.5 16.8673C20.5 17.2167 20.214 17.5 19.8613 17.5L4.13873 17.5C3.78597 17.5 3.5 17.2167 3.5 16.8673Z"
            fill="currentColor"
          />
        </svg>
      </StyledAvatar>
    </StyledHamburger>
  )
}
