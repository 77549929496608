export const Ilustration = ({ isDarkMode }) => {
  return isDarkMode ? (
    <svg
      width="648"
      height="382"
      viewBox="0 0 648 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_233_6355)">
        <path
          d="M560.967 167.825H343.154C334.123 167.825 326.801 175.149 326.801 184.183V312.39C326.801 321.424 334.123 328.748 343.154 328.748H560.967C569.999 328.748 577.32 321.424 577.32 312.39V184.183C577.32 175.149 569.999 167.825 560.967 167.825Z"
          fill="#F8F5FF"
        />
        <line
          x1="327.094"
          y1="202.445"
          x2="578.161"
          y2="202.445"
          stroke="#5F36BC"
          strokeWidth="1.76808"
        />
        <circle cx="342.565" cy="183.438" r="3.97817" fill="#5F36BC" />
        <rect
          x="282.008"
          y="227.198"
          width="161.779"
          height="43.3179"
          rx="21.6589"
          stroke="#9D98A9"
          strokeWidth="1.76808"
        />
        <rect x="507.438" y="271.4" width="123.765" height="37.1296" rx="7.07231" fill="#DFD9E7" />
        <circle cx="524.235" cy="289.965" r="8.84038" fill="#9D98A9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M271.273 34.5732C267.094 29.6658 264.265 23.6542 264.731 16.9228C266.088 -2.48606 293.096 1.78341 298.346 11.5819C299.858 14.3955 300.881 18.4523 301.412 22.8445C301.641 24.7584 304.887 28.5698 305.296 30.5082C305.852 33.1501 301.829 32.2013 301.723 33.8862C301.249 41.0674 299.417 47.1281 296.187 47.9623C293.481 48.6657 287.708 46.9563 281.845 43.4148L285.525 69.4896H263.774L271.273 34.5732Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288.795 13.0288C285.843 14.6646 283.742 17.7809 281.207 21.8377C280.574 21.5906 279.9 21.463 279.22 21.4614C278.138 21.4604 277.081 21.7821 276.183 22.3853C275.285 22.9885 274.588 23.8459 274.18 24.8478C273.771 25.8497 273.671 26.9506 273.892 28.0098C274.112 29.0689 274.644 30.0382 275.418 30.7937C274.167 32.5686 272.752 34.4252 271.133 36.3473C265.107 32.6586 260.642 22.1403 266.366 11.9574C271.313 -8.44119 299.392 4.21999 304.797 0.784785C305.991 8.18683 302.18 14.1494 288.795 13.0288Z"
          fill="#9D98A9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.804 139.477L276.342 245.992L278.942 324.732L292.933 326.801L305.394 145.922L261.804 139.477Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M245.172 145.668C247.257 199.298 246.439 228.554 245.989 233.429C245.539 238.304 242.866 270.93 224.771 328.061H239.489C263.455 273.261 271.82 240.619 274.567 233.429C277.314 226.24 285.434 196.975 297.282 145.668H245.172Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.166 139.387C263.679 175.669 268.719 233.74 276.285 313.601L298.804 311.564C312.001 232.702 311.208 184.789 305.566 145.995L261.166 139.387Z"
          fill="#2B253A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M244.691 146C246.735 199.63 241.42 252.475 225.001 316.942H248.714C272.721 262.96 288.289 210.377 300.055 146H244.691Z"
          fill="#2B253A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222.653 338.694L223.471 325.607C228.224 326.998 234.177 326.453 241.329 323.972C249.015 329.424 258.694 333.143 270.365 335.128C270.792 335.202 271.172 335.442 271.422 335.797C271.672 336.151 271.771 336.59 271.698 337.017C271.698 337.075 271.698 337.132 271.698 337.189L270.569 341.148H224.28L222.653 338.694Z"
          fill="#DCCDFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.049 331.888L276.867 318.802C281.614 320.181 287.564 319.636 294.716 317.166C302.403 322.618 312.081 326.337 323.752 328.322C323.964 328.358 324.167 328.435 324.35 328.549C324.532 328.664 324.69 328.813 324.815 328.988C324.939 329.164 325.028 329.362 325.076 329.572C325.124 329.782 325.129 329.999 325.093 330.211C325.083 330.269 325.07 330.327 325.052 330.383L323.932 334.342H277.676L276.049 331.888Z"
          fill="#DCCDFF"
        />
        <path
          d="M277.116 239.115L268.416 274.694"
          stroke="#1D2127"
          strokeWidth="1.54707"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M340.374 130.383L365.566 154.961C372.005 157.578 378.058 161.062 383.555 165.316C384.602 166.592 385.755 169.021 380.799 167.933C375.844 166.845 370.587 165.921 369.654 167.532C368.722 169.144 371.47 171.622 369.744 173.724C368.6 175.131 364.743 170.66 358.174 160.31L332.009 144.86L340.374 130.383ZM222.669 106.165L242.138 106.23C227.305 154.291 219.434 179.455 218.523 181.723C216.463 186.827 220.821 194.589 222.677 198.768C216.618 201.484 217.264 191.407 209.594 194.99C202.611 198.261 197.329 204.126 189.234 199.145C188.245 198.531 187.157 196.225 189.782 194.425C196.323 189.943 205.751 182.083 207.027 179.498C208.771 175.976 213.985 151.532 222.669 106.165Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M277.102 57.3262L284.175 56.1157C295.312 95.4979 352.541 122.366 356.049 137.088C357.341 142.495 357 144.999 358 146.499L347.5 154.999C346 153.999 341.363 155.099 334.462 152.776C327.561 150.453 277.102 103.963 277.102 57.3262Z"
          fill="#2B253A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288.99 62.5617C288.173 114.703 318.312 127.994 311.599 148.924C306.055 166.198 253.005 184.765 243.822 167.834C240.11 160.98 238.425 152.695 238.229 143.682C235.885 150.601 234.032 156.561 232.669 161.561C230.314 170.182 221.5 174.5 221.5 175.5L208.501 171C209.318 166.493 204.271 164.841 205.792 154.764C212.6 109.599 230.903 76.8614 260.699 56.5501L261.01 52.1416C261.07 51.2761 261.472 50.4702 262.127 49.9011C262.782 49.332 263.636 49.0464 264.501 49.1071L286.546 50.653C286.975 50.6821 287.394 50.7955 287.779 50.9868C288.164 51.1782 288.508 51.4437 288.791 51.7681C289.073 52.0925 289.289 52.4694 289.426 52.8774C289.562 53.2853 289.617 53.7162 289.587 54.1454L288.99 62.5617Z"
          fill="#2B253A"
        />
        <path
          d="M294.796 96.8638C302.074 126.709 319.719 138.471 314.421 154.976"
          stroke="#1D2127"
          strokeWidth="1.54707"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M296.203 37.1577C296.823 37.8453 297.579 38.3955 298.424 38.773C299.269 39.1505 300.184 39.347 301.109 39.3497"
          fill="#C4C7CA"
        />
        <path
          d="M296.203 37.1577C296.823 37.8453 297.579 38.3955 298.424 38.773C299.269 39.1505 300.184 39.347 301.109 39.3497"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M294.094 22.5737L294.658 26.9086"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M396.663 154.976V150.069"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M396.663 140.328V121.222C396.663 116.883 398.386 112.723 401.452 109.655C404.519 106.587 408.679 104.864 413.016 104.864H630.829C635.166 104.864 639.325 106.587 642.392 109.655C645.459 112.723 647.182 116.883 647.182 121.222V249.404C647.182 253.742 645.459 257.903 642.392 260.971C639.325 264.039 635.166 265.762 630.829 265.762H610.452"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10.53 10.53"
        />
        <path
          d="M605.588 265.787H600.682"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.539 115.415C121.36 125.802 125.031 149.153 121.286 152.817H97.5735V152.686C93.2772 152.206 89.3085 150.158 86.4259 146.936C83.5434 143.713 81.9491 139.54 81.9478 135.216V118.04C81.9478 113.376 83.7999 108.903 87.0968 105.606C90.3937 102.308 94.8653 100.455 99.5278 100.455H101.163C105.37 100.457 109.436 101.967 112.624 104.712C115.812 107.457 117.911 111.254 118.539 115.415Z"
          fill="#9D98A9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.1468 131.937C87.3773 127.512 84.8344 122.122 85.2596 116.012C86.4861 98.5092 110.845 102.362 115.579 111.195C117.214 114.238 118.22 118.891 118.58 123.742C118.801 126.662 119.226 128.191 121.033 133.908C120.175 134.398 119.226 134.707 118.245 134.816C117.525 139.674 115.988 143.404 113.617 144.017C111.245 144.631 105.971 143.101 100.681 139.928L104.001 163.451H84.3765L91.1468 131.937Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4536 128.975C97.3612 138.692 96.9933 149.782 94.3031 152.416H70.5905C69.2659 129.457 82.038 138.348 82.038 116.731C82.8556 115.954 83.5834 115.185 84.3274 114.514V113.885C88.4322 105.476 93.6408 101.272 99.9532 101.272C109.422 101.272 112.415 105.673 114.958 109.452C113.052 115.995 105.669 116.952 99.111 121.017C98.5517 120.565 97.8923 120.254 97.188 120.11C96.4838 119.966 95.7553 119.993 95.0636 120.188C94.3719 120.384 93.7372 120.742 93.2125 121.234C92.6879 121.725 92.2886 122.336 92.0483 123.013C91.8079 123.691 91.7334 124.416 91.8311 125.128C91.9287 125.841 92.1957 126.519 92.6095 127.107C93.0233 127.695 93.572 128.175 94.2095 128.507C94.8471 128.839 95.5549 129.014 96.2737 129.016L96.4536 128.975Z"
          fill="#9D98A9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.335 137.825C115.202 137.991 116.093 137.991 116.96 137.825H114.335Z"
          fill="#C4C7CA"
        />
        <path
          d="M114.335 137.825C115.202 137.991 116.093 137.991 116.96 137.825"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.607 125.704V128.697"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.1183 381.903C113.891 381.903 142.079 354.989 142.079 321.787C142.079 288.586 113.891 261.671 79.1183 261.671C44.3458 261.671 16.1572 288.586 16.1572 321.787C16.1572 354.989 44.3458 381.903 79.1183 381.903Z"
          fill="#5F36BC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M198.099 372.628C187.616 349.432 180.045 329.23 177.003 318.581C171.794 300.334 167.837 284.278 166.831 278.283C164.443 263.986 185.196 263.152 187.943 270.259C192.097 281.015 199.342 316.348 208.05 369.717L198.099 372.628ZM76.576 299.835C86.1837 296.031 119.847 284.139 136.086 280.777C140.747 279.812 145.269 278.913 149.521 278.095C162.154 275.682 168.704 298.485 156.537 300.244C126.225 304.603 83.2564 309.11 79.5769 309.617C74.4255 310.32 70.5334 302.223 76.576 299.835Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.28 271.822L105.284 245.313V239.588H111.466C161.769 252.402 187.474 260.616 188.581 264.232C188.577 264.272 188.577 264.313 188.581 264.354L188.654 264.452C198.622 278.169 204.591 345.114 206.553 350.128L187.452 352.983C180.142 325.877 156.765 306.992 157.386 274.537C157.231 273.641 157.196 272.728 157.28 271.822Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.601 287.55C104.842 286.593 100.467 285.742 96.7144 285.096C72.503 280.843 65.4382 267.879 69.6166 239.596H114.229C121.514 241.69 159.389 257.59 179.038 266.022C192.047 271.617 187.951 291.778 180.583 297.029C180.581 297.163 180.542 297.294 180.47 297.406C180.397 297.519 180.295 297.609 180.175 297.667C140.55 314.091 109.208 305.47 98.7422 308.226L93.9015 292.94L109.601 287.55Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.8627 297.407C77.9664 297.494 76.0968 297.889 74.3271 298.576C72.4113 299.593 70.5794 300.761 68.8486 302.069L89.0698 343.528C93.1581 342.874 94.7771 341.315 93.9268 338.85C93.1091 336.396 92.3977 334.335 91.8744 332.691L91.0567 309.234C91.0567 309.017 90.9706 308.809 90.8172 308.655C90.6639 308.502 90.4559 308.416 90.239 308.416L86.6331 308.645C84.9714 307.444 83.5788 305.909 82.5447 304.138C81.9 302.494 81.5995 300.734 81.6616 298.969C81.6509 298.542 81.4739 298.137 81.1684 297.839C80.8629 297.541 80.453 297.374 80.0263 297.374L79.8627 297.407Z"
          fill="#DCCDFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.331 368.327C192.58 370.067 192.116 371.918 191.958 373.806C192.032 375.976 192.278 378.137 192.693 380.268H238.81C240.01 376.298 239.317 374.158 236.733 373.847C234.15 373.537 232.005 373.264 230.298 373.029L208.867 363.476C208.67 363.388 208.445 363.381 208.243 363.458C208.041 363.534 207.877 363.688 207.788 363.885L206.398 367.181C204.589 368.125 202.603 368.682 200.568 368.817C198.81 368.667 197.1 368.167 195.539 367.345C195.343 367.256 195.131 367.207 194.916 367.2C194.701 367.193 194.487 367.228 194.286 367.304C194.085 367.38 193.9 367.495 193.743 367.642C193.587 367.79 193.46 367.967 193.372 368.163C193.347 368.214 193.333 368.27 193.331 368.327Z"
          fill="#DCCDFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.954 206.032L175.244 201.624C180.82 198.385 186.816 195.93 193.062 194.328C194.591 194.279 197.019 194.835 193.405 197.853C189.791 200.871 186.111 204.208 186.757 205.82C187.403 207.431 190.764 206.826 191.328 209.304C191.693 210.94 186.242 211.212 174.975 210.122L148.809 220.509L142.954 206.032ZM41.5053 213.508L59.4124 213.573C39.7882 257.74 29.5509 280.879 28.7005 282.989C26.8444 287.684 30.8755 294.824 32.5599 298.66C26.9834 301.163 27.5721 291.912 20.5401 295.192C14.1213 298.177 9.23162 303.592 1.8153 299.012C0.907675 298.447 -0.0980642 296.329 2.32226 294.677C8.33217 290.546 17.0404 283.324 18.177 280.944C19.7742 277.705 27.5503 255.227 41.5053 213.508Z"
          fill="#F8F5FF"
          stroke="#9D98A9"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.5658 155.705L104.589 156.637C108.04 187.881 126.846 207.618 168.948 200.935L174.541 241.176C135.195 246.264 100.68 230.503 93.5912 187.824C91.7923 176.782 90.345 165.283 90.5658 155.705Z"
          fill="#2B253A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.6645 151.624L104.924 156.531C104.924 193.075 116.56 216.394 122.529 243.229H65.1523C64.3347 252.513 63.8441 262.041 63.5824 271.856H20.6299C30.3657 220.055 50.9112 179.978 82.2663 151.624H83.6645Z"
          fill="#2B253A"
        />
        <path
          d="M66.7881 244.039C68.4234 225.644 69.5764 207.446 74.1962 191.088"
          stroke="#1D2127"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_233_6355">
          <rect width="648" height="381.905" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="648"
      height="382"
      viewBox="0 0 648 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_206_11648)">
        <path
          d="M560.967 167.825H343.154C334.123 167.825 326.801 175.149 326.801 184.183V312.39C326.801 321.424 334.123 328.748 343.154 328.748H560.967C569.999 328.748 577.32 321.424 577.32 312.39V184.183C577.32 175.149 569.999 167.825 560.967 167.825Z"
          fill="white"
        />
        <line
          x1="327.094"
          y1="202.445"
          x2="578.161"
          y2="202.445"
          stroke="#41FFD1"
          strokeWidth="1.76808"
        />
        <circle cx="342.565" cy="183.438" r="3.97817" fill="#41FFD1" />
        <rect
          x="282.008"
          y="227.198"
          width="161.779"
          height="43.3179"
          rx="21.6589"
          stroke="#2A0062"
          strokeWidth="1.76808"
        />
        <rect x="507.438" y="271.4" width="123.765" height="37.1296" rx="7.07231" fill="#DFD9E7" />
        <circle cx="524.235" cy="289.965" r="8.84038" fill="#2A0062" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M271.273 34.5732C267.094 29.6658 264.265 23.6542 264.731 16.9228C266.088 -2.48606 293.096 1.78341 298.346 11.5819C299.858 14.3955 300.881 18.4523 301.412 22.8445C301.641 24.7584 304.887 28.5698 305.296 30.5082C305.852 33.1501 301.829 32.2013 301.723 33.8862C301.249 41.0674 299.417 47.1281 296.187 47.9623C293.481 48.6657 287.708 46.9563 281.845 43.4148L285.525 69.4896H263.774L271.273 34.5732Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288.795 13.0288C285.843 14.6646 283.742 17.7809 281.207 21.8377C280.574 21.5906 279.9 21.463 279.22 21.4614C278.138 21.4604 277.081 21.7821 276.183 22.3853C275.285 22.9885 274.588 23.8459 274.18 24.8478C273.771 25.8497 273.671 26.9506 273.892 28.0098C274.112 29.0689 274.644 30.0382 275.418 30.7937C274.167 32.5686 272.752 34.4252 271.133 36.3473C265.107 32.6586 260.642 22.1403 266.366 11.9574C271.313 -8.44119 299.392 4.21999 304.797 0.784785C305.991 8.18683 302.18 14.1494 288.795 13.0288Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.804 139.477L276.342 245.992L278.942 324.732L292.933 326.801L305.394 145.922L261.804 139.477Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M245.172 145.668C247.257 199.298 246.439 228.554 245.989 233.429C245.539 238.304 242.866 270.93 224.771 328.061H239.489C263.455 273.261 271.82 240.619 274.567 233.429C277.314 226.24 285.434 196.975 297.282 145.668H245.172Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.166 139.387C263.679 175.669 268.719 233.74 276.285 313.601L298.804 311.564C312.001 232.702 311.208 184.789 305.566 145.995L261.166 139.387Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M244.691 146C246.735 199.63 241.42 252.475 225.001 316.942H248.714C272.721 262.96 288.289 210.377 300.055 146H244.691Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222.653 338.694L223.471 325.607C228.224 326.998 234.177 326.453 241.329 323.972C249.015 329.424 258.694 333.143 270.365 335.128C270.792 335.202 271.172 335.442 271.422 335.797C271.672 336.151 271.771 336.59 271.698 337.017C271.698 337.075 271.698 337.132 271.698 337.189L270.569 341.148H224.28L222.653 338.694Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.049 331.888L276.867 318.802C281.614 320.181 287.564 319.636 294.716 317.166C302.403 322.618 312.081 326.337 323.752 328.322C323.964 328.358 324.167 328.435 324.35 328.549C324.532 328.664 324.69 328.813 324.815 328.988C324.939 329.164 325.028 329.362 325.076 329.572C325.124 329.782 325.129 329.999 325.093 330.211C325.083 330.269 325.07 330.327 325.052 330.383L323.932 334.342H277.676L276.049 331.888Z"
          fill="#2A0062"
        />
        <path
          d="M277.116 239.115L268.416 274.694"
          stroke="#F7F9FC"
          strokeWidth="1.54707"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M340.374 130.383L365.566 154.961C372.005 157.578 378.058 161.062 383.555 165.316C384.602 166.592 385.755 169.021 380.799 167.933C375.844 166.845 370.587 165.921 369.654 167.532C368.722 169.144 371.47 171.622 369.744 173.724C368.6 175.131 364.743 170.66 358.174 160.31L332.009 144.86L340.374 130.383ZM222.669 106.165L242.138 106.23C227.305 154.291 219.434 179.455 218.523 181.723C216.463 186.827 220.821 194.589 222.677 198.768C216.618 201.484 217.264 191.407 209.594 194.99C202.611 198.261 197.329 204.126 189.234 199.145C188.245 198.531 187.157 196.225 189.782 194.425C196.323 189.943 205.751 182.083 207.027 179.498C208.771 175.976 213.985 151.532 222.669 106.165Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M277.102 57.3262L284.175 56.1157C295.312 95.4979 352.541 122.366 356.049 137.088C357.341 142.495 357 144.999 358 146.499L347.5 154.999C346 153.999 341.363 155.099 334.462 152.776C327.561 150.453 277.102 103.963 277.102 57.3262Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288.99 62.5617C288.173 114.703 318.312 127.994 311.599 148.924C306.055 166.198 253.005 184.765 243.822 167.834C240.11 160.98 238.425 152.695 238.229 143.682C235.885 150.601 234.032 156.561 232.669 161.561C230.314 170.182 221.5 174.5 221.5 175.5L208.501 171C209.318 166.493 204.271 164.841 205.792 154.764C212.6 109.599 230.903 76.8614 260.699 56.5501L261.01 52.1416C261.07 51.2761 261.472 50.4702 262.127 49.9011C262.782 49.332 263.636 49.0464 264.501 49.1071L286.546 50.653C286.975 50.6821 287.394 50.7955 287.779 50.9868C288.164 51.1782 288.508 51.4437 288.791 51.7681C289.073 52.0925 289.289 52.4694 289.426 52.8774C289.562 53.2853 289.617 53.7162 289.587 54.1454L288.99 62.5617Z"
          fill="#2A0062"
        />
        <path
          d="M294.796 96.8638C302.074 126.709 319.719 138.471 314.421 154.976"
          stroke="#F7F9FC"
          strokeWidth="1.54707"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M296.203 37.1577C296.823 37.8453 297.579 38.3955 298.424 38.773C299.269 39.1505 300.184 39.347 301.109 39.3497"
          fill="#F6F6F6"
        />
        <path
          d="M296.203 37.1577C296.823 37.8453 297.579 38.3955 298.424 38.773C299.269 39.1505 300.184 39.347 301.109 39.3497"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M294.094 22.5737L294.658 26.9086"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M396.663 154.976V150.069"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M396.663 140.328V121.222C396.663 116.883 398.386 112.723 401.452 109.655C404.519 106.587 408.679 104.864 413.016 104.864H630.829C635.166 104.864 639.325 106.587 642.392 109.655C645.459 112.723 647.182 116.883 647.182 121.222V249.404C647.182 253.742 645.459 257.903 642.392 260.971C639.325 264.039 635.166 265.762 630.829 265.762H610.452"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10.53 10.53"
        />
        <path
          d="M605.588 265.787H600.682"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.539 115.415C121.36 125.802 125.031 149.153 121.286 152.817H97.5735V152.686C93.2772 152.206 89.3085 150.158 86.4259 146.936C83.5434 143.713 81.9491 139.54 81.9478 135.216V118.04C81.9478 113.376 83.7999 108.903 87.0968 105.606C90.3937 102.308 94.8653 100.455 99.5278 100.455H101.163C105.37 100.457 109.436 101.967 112.624 104.712C115.812 107.457 117.911 111.254 118.539 115.415Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.1468 131.937C87.3773 127.512 84.8344 122.122 85.2596 116.012C86.4861 98.5092 110.845 102.362 115.579 111.195C117.214 114.238 118.22 118.891 118.58 123.742C118.801 126.662 119.226 128.191 121.033 133.908C120.175 134.398 119.226 134.707 118.245 134.816C117.525 139.674 115.988 143.404 113.617 144.017C111.245 144.631 105.971 143.101 100.681 139.928L104.001 163.451H84.3765L91.1468 131.937Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4536 128.975C97.3612 138.692 96.9933 149.782 94.3031 152.416H70.5905C69.2659 129.457 82.038 138.348 82.038 116.731C82.8556 115.954 83.5834 115.185 84.3274 114.514V113.885C88.4322 105.476 93.6408 101.272 99.9532 101.272C109.422 101.272 112.415 105.673 114.958 109.452C113.052 115.995 105.669 116.952 99.111 121.017C98.5517 120.565 97.8923 120.254 97.188 120.11C96.4838 119.966 95.7553 119.993 95.0636 120.188C94.3719 120.384 93.7372 120.742 93.2125 121.234C92.6879 121.725 92.2886 122.336 92.0483 123.013C91.8079 123.691 91.7334 124.416 91.8311 125.128C91.9287 125.841 92.1957 126.519 92.6095 127.107C93.0233 127.695 93.572 128.175 94.2095 128.507C94.8471 128.839 95.5549 129.014 96.2737 129.016L96.4536 128.975Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.335 137.825C115.202 137.991 116.093 137.991 116.96 137.825H114.335Z"
          fill="#F6F6F6"
        />
        <path
          d="M114.335 137.825C115.202 137.991 116.093 137.991 116.96 137.825"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.607 125.704V128.697"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.1183 381.903C113.891 381.903 142.079 354.989 142.079 321.787C142.079 288.586 113.891 261.671 79.1183 261.671C44.3458 261.671 16.1572 288.586 16.1572 321.787C16.1572 354.989 44.3458 381.903 79.1183 381.903Z"
          fill="#41FFD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M198.099 372.628C187.616 349.432 180.045 329.23 177.003 318.581C171.794 300.334 167.837 284.278 166.831 278.283C164.443 263.986 185.196 263.152 187.943 270.259C192.097 281.015 199.342 316.348 208.05 369.717L198.099 372.628ZM76.576 299.835C86.1837 296.031 119.847 284.139 136.086 280.777C140.747 279.812 145.269 278.913 149.521 278.095C162.154 275.682 168.704 298.485 156.537 300.244C126.225 304.603 83.2564 309.11 79.5769 309.617C74.4255 310.32 70.5334 302.223 76.576 299.835Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.28 271.822L105.284 245.313V239.588H111.466C161.769 252.402 187.474 260.616 188.581 264.232C188.577 264.272 188.577 264.313 188.581 264.354L188.654 264.452C198.622 278.169 204.591 345.114 206.553 350.128L187.452 352.983C180.142 325.877 156.765 306.992 157.386 274.537C157.231 273.641 157.196 272.728 157.28 271.822Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.601 287.55C104.842 286.593 100.467 285.742 96.7144 285.096C72.503 280.843 65.4382 267.879 69.6166 239.596H114.229C121.514 241.69 159.389 257.59 179.038 266.022C192.047 271.617 187.951 291.778 180.583 297.029C180.581 297.163 180.542 297.294 180.47 297.406C180.397 297.519 180.295 297.609 180.175 297.667C140.55 314.091 109.208 305.47 98.7422 308.226L93.9015 292.94L109.601 287.55Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.8627 297.407C77.9664 297.494 76.0968 297.889 74.3271 298.576C72.4113 299.593 70.5794 300.761 68.8486 302.069L89.0698 343.528C93.1581 342.874 94.7771 341.315 93.9268 338.85C93.1091 336.396 92.3977 334.335 91.8744 332.691L91.0567 309.234C91.0567 309.017 90.9706 308.809 90.8172 308.655C90.6639 308.502 90.4559 308.416 90.239 308.416L86.6331 308.645C84.9714 307.444 83.5788 305.909 82.5447 304.138C81.9 302.494 81.5995 300.734 81.6616 298.969C81.6509 298.542 81.4739 298.137 81.1684 297.839C80.8629 297.541 80.453 297.374 80.0263 297.374L79.8627 297.407Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.331 368.327C192.58 370.067 192.116 371.918 191.958 373.806C192.032 375.976 192.278 378.137 192.693 380.268H238.81C240.01 376.298 239.317 374.158 236.733 373.847C234.15 373.537 232.005 373.264 230.298 373.029L208.867 363.476C208.67 363.388 208.445 363.381 208.243 363.458C208.041 363.534 207.877 363.688 207.788 363.885L206.398 367.181C204.589 368.125 202.603 368.682 200.568 368.817C198.81 368.667 197.1 368.167 195.539 367.345C195.343 367.256 195.131 367.207 194.916 367.2C194.701 367.193 194.487 367.228 194.286 367.304C194.085 367.38 193.9 367.495 193.743 367.642C193.587 367.79 193.46 367.967 193.372 368.163C193.347 368.214 193.333 368.27 193.331 368.327Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.954 206.032L175.244 201.624C180.82 198.385 186.816 195.93 193.062 194.328C194.591 194.279 197.019 194.835 193.405 197.853C189.791 200.871 186.111 204.208 186.757 205.82C187.403 207.431 190.764 206.826 191.328 209.304C191.693 210.94 186.242 211.212 174.975 210.122L148.809 220.509L142.954 206.032ZM41.5053 213.508L59.4124 213.573C39.7882 257.74 29.5509 280.879 28.7005 282.989C26.8444 287.684 30.8755 294.824 32.5599 298.66C26.9834 301.163 27.5721 291.912 20.5401 295.192C14.1213 298.177 9.23162 303.592 1.8153 299.012C0.907675 298.447 -0.0980642 296.329 2.32226 294.677C8.33217 290.546 17.0404 283.324 18.177 280.944C19.7742 277.705 27.5503 255.227 41.5053 213.508Z"
          fill="white"
          stroke="#2A0062"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.5658 155.705L104.589 156.637C108.04 187.881 126.846 207.618 168.948 200.935L174.541 241.176C135.195 246.264 100.68 230.503 93.5912 187.824C91.7923 176.782 90.345 165.283 90.5658 155.705Z"
          fill="#2A0062"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.6645 151.624L104.924 156.531C104.924 193.075 116.56 216.394 122.529 243.229H65.1523C64.3347 252.513 63.8441 262.041 63.5824 271.856H20.6299C30.3657 220.055 50.9112 179.978 82.2663 151.624H83.6645Z"
          fill="#2A0062"
        />
        <path
          d="M66.7881 244.039C68.4234 225.644 69.5764 207.446 74.1962 191.088"
          stroke="#F7F9FC"
          strokeWidth="1.76808"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_206_11648">
          <rect width="648" height="381.905" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
