export const Logout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37729 11.3627L9.88377 8.57604C10.1604 8.35618 10.2053 7.95516 9.98395 7.68034C9.76263 7.40552 9.35895 7.36096 9.08231 7.58082L5.11114 10.7368C4.29629 11.3844 4.29629 12.6156 5.11114 13.2632L9.08231 16.4192C9.35895 16.639 9.76263 16.5945 9.98395 16.3197C10.2053 16.0448 10.1604 15.6438 9.88377 15.424L6.37729 12.6373L15.4044 12.6373C15.7587 12.6373 16.0459 12.3519 16.0459 12C16.0459 11.6481 15.7587 11.3627 15.4044 11.3627L6.37729 11.3627ZM14.2695 18.8627C14.2695 18.5108 14.5567 18.2255 14.9109 18.2255L15.8978 18.2255C17.1787 18.2255 18.217 17.194 18.217 15.9216L18.217 8.07843C18.217 6.80601 17.1787 5.77451 15.8978 5.77451L14.9109 5.77451C14.5567 5.77451 14.2695 5.4892 14.2695 5.13725C14.2695 4.78531 14.5567 4.5 14.9109 4.5L15.8978 4.5C17.8873 4.5 19.5 6.10212 19.5 8.07843L19.5 15.9216C19.5 17.8979 17.8873 19.5 15.8978 19.5L14.9109 19.5C14.5567 19.5 14.2695 19.2147 14.2695 18.8627Z"
      />
    </svg>
  )
}
