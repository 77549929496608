import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

interface ContainerProps {
  width?: string
  $widthMobile?: string
  className?: any
  $styleName: 'primary' | 'secondary'
}

export const StyledButton = styled.button<ContainerProps>`
  outline: none;
  display: inline-flex;
  width: ${(props) => (props.width ? props.width : 'auto')};
  min-width: 136px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  color: ${(props) => props.theme.button[props.$styleName].color};
  background-color: ${(props) => props.theme.button[props.$styleName].bgColor};
  border: ${(props) => props.theme.button[props.$styleName].border};
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  &:hover {
    background-color: ${(props) => props.theme.button[props.$styleName].hover.bgColor};
    border: ${(props) => props.theme.button[props.$styleName].hover.border};
    box-shadow: ${(props) => props.theme.button[props.$styleName].hover.boxShadow};
  }

  &:active {
    background-color: ${(props) => props.theme.button[props.$styleName].active.bgColor};
    border: ${(props) => props.theme.button[props.$styleName].active.border};
    box-shadow: ${(props) => props.theme.button[props.$styleName].active.boxShadow};
  }

  &:disabled,
  &[disabled] {
    color: ${(props) => props.theme.button[props.$styleName].disabled.color};
    background-color: ${(props) => props.theme.button[props.$styleName].disabled.bgColor};
    border: ${(props) => props.theme.button[props.$styleName].disabled.border};
  }

  ${Animatable}
`
