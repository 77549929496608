import { action, computed, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'
import { makeLocalStorage } from '@/helpers/localStorageHelpers'
import { axiosInstance } from '@/axios/AxiosInstance'
import { dateEnd, dateStart } from '@/helpers/dateHelpers'
import { SelectOption } from '@/types/generic'

export type StatusValue = 'all' | 'completed' | 'pending' | 'expired'

export const statusOptions: StatusValue[] = ['all', 'completed', 'pending', 'expired']

export interface TagFilterObject {
  name: string
  state: 'include' | 'exclude' | ''
}

interface TransactionFilters {
  transactionId: string
  configurationId: string
  userRef: string
  status: StatusValue
  transactionStart: string
  transactionEnd: string
  tags: TagFilterObject[]
  tenantId: string
}

export class TransactionListFilterStore {
  rootStore: RootStore

  fetchedTags: string[] = []

  // Filter values
  transactionId = ''
  configurationId = ''
  userRef = ''
  status: StatusValue = statusOptions[0]
  transactionStart: number = undefined
  transactionEnd: number = undefined
  tags: TagFilterObject[] = []

  // Combined filters
  get filters(): TransactionFilters {
    return {
      transactionId: this.transactionId,
      configurationId: this.configurationId,
      userRef: this.userRef,
      status: this.status === 'all' ? undefined : this.status,
      transactionStart: this.transactionStart?.toString(),
      // transactionStart: undefined,
      transactionEnd: this.transactionEnd?.toString(),
      // transactionEnd: undefined,
      tags: this.tags,
      tenantId: this.rootStore.AppState.selectedTenant,
    }
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      filters: computed,
    })
    this.rootStore = rootStore

    makeLocalStorage(this, 'filtersStore', [
      'transactionId',
      'configurationId',
      'userRef',
      'status',
      'tags',
    ])

    void this.fetchTags()
  }

  @action.bound async fetchTags() {
    try {
      if (
        this.rootStore.Auth.currentUserId?.length &&
        this.rootStore.AppState.selectedTenant?.length &&
        this.rootStore.AppState.selectedTenant !== 'null' &&
        this.rootStore.AppState.selectedTenant !== 'undefined'
      ) {
        const res = await axiosInstance.get<{ items: string[] }>(`/tags`, {
          params: {
            tenantId: this.rootStore.AppState.selectedTenant,
          },
        })

        this.setFetchedTags(res.data.items)

        this.setTags(
          res.data.items.map((tag) => {
            const selectedTag = this.tags.find((item) => item.name === tag)

            return {
              name: tag,
              state: selectedTag ? selectedTag.state : '',
            }
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action.bound resetFilters(): void {
    this.transactionId = ''
    this.configurationId = ''
    this.userRef = ''
    this.status = statusOptions[0]
    this.transactionStart = undefined
    this.transactionEnd = undefined
    this.tags = this.tags.map((tag) => ({ ...tag, state: '' }))
  }

  @action.bound setTransactionId = (filter: string): void => {
    this.transactionId = filter
  }

  @action.bound setConfigurationId = (configurationId: string): void => {
    this.configurationId = configurationId
  }

  @action.bound setUserRef = (userRef: string): void => {
    this.userRef = userRef
  }

  @action.bound setTransactionStart = (transactionStart: number): void => {
    console.log('transactionStart', transactionStart)
    this.transactionStart = transactionStart
  }

  @action.bound setTransactionEnd = (transactionEnd: number): void => {
    console.log('transactionEnd', transactionEnd)
    this.transactionEnd = transactionEnd
  }

  @action.bound setStatus = (status: StatusValue): void => {
    this.status = status
  }

  @action.bound setFetchedTags = (tags: string[]): void => {
    this.fetchedTags = tags
  }

  @action.bound setTags = (tags: TagFilterObject[]): void => {
    this.tags = tags
  }
}
