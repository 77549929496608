import { DEV_MODE } from '@/constants/envs'

export const replaceConsoleLogMethod = () => {
  // Step 1: Save a reference to the original console.log
  const originalConsoleLog = window.console.log

  // Step 2: Create your custom log function
  window.console.log = function (...args) {
    // Verify if the app is running in development environment
    const isDev = window.location.href.includes('dev-') || DEV_MODE
    if (!isDev) return

    originalConsoleLog(...args)
  }
}
