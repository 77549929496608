import { FC, useContext } from 'react'
import { Container, LimitContainer, LinksContainer } from './Pagination.styles'

import { observer } from 'mobx-react'
import { IconButton } from '@/components/IconButton/IconButton'
import { SelectInput } from '@components/Generic'
import { SelectOption } from '@/types/generic'
import { StoreContext } from '@/components/App'

export type LimitOptions = 10 | 25

export const limitOptions: SelectOption[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
]

export const Pagination: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionsPage: trans } = store.TranslationsState.translations
  const { currentPageIndex } = store.TransactionListState
  const { total, limit, pagesNumber, currentListLength, currentPage } =
    store.TransactionListState.pagination

  // const value = limitOptions?.findIndex((item) => item.value === limit?.toString())

  const prevOnClick = () => {
    store.TransactionListState.setCurrentPageIndex(currentPageIndex - 1)
  }

  const nextOnClick = () => {
    store.TransactionListState.setCurrentPageIndex(currentPageIndex + 1)
  }

  return (
    <Container>
      <LimitContainer>
        {trans.show}{' '}
        <SelectInput
          options={limitOptions}
          value={{ label: limit?.toString(), value: limit?.toString() }}
          onChange={(option: SelectOption) =>
            store.TransactionListState.setPagesLimit(Number(option.value) as LimitOptions)
          }
          height={24}
        />{' '}
        {trans.entries}
      </LimitContainer>

      {pagesNumber <= 1 ? (
        <span>
          {1}-{currentListLength} {trans.of} {total}
        </span>
      ) : (
        <>
          <span>
            {currentListLength < limit ? (
              <>
                {currentPage * limit + 1} - {currentPage * limit + currentListLength} of {total}
              </>
            ) : (
              <>
                {currentPage * limit + 1} - {(currentPage + 1) * limit} of {total}
              </>
            )}
          </span>
          <LinksContainer>
            <IconButton onClick={prevOnClick} disabled={currentPage <= 0} iconType="chevron-left" />
            <IconButton
              onClick={nextOnClick}
              disabled={currentPage >= pagesNumber - 1}
              iconType="chevron-right"
            />
          </LinksContainer>
        </>
      )}
    </Container>
  )
})
