import { styled } from 'styled-components'
import { Link } from 'wouter'

export const Content = styled.div`
  max-width: 1440px;
  padding: 40px 84px;
  /* display: flex;
  flex-direction: column;
  gap: 32px; */

  margin: 0 auto;

  min-height: calc(100vh - 80px);
  height: 1px;
`

export const TopContent = styled.div`
  display: grid;
  grid-template-columns: 100px 650px auto;
  gap: 8px 12px;

  align-items: center;
  justify-items: start;
`

export const BackButton = styled(Link)`
  grid-column: 1/-1;
  grid: row 1;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;

  color: ${({ theme }) => theme.transactionDetailsPage.backButton.color};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  text-decoration: none;

  &:hover {
    filter: ${({ theme }) => `brightness(${theme.effects.hover.brightness})`};
  }
`

export const Title = styled.h1`
  margin: 0;
  grid-column: 1 / 3;
  grid-row: 2;
  color: ${({ theme }) => theme.transactionDetailsPage.title.secondaryColor};
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  span {
    color: ${({ theme }) => theme.transactionDetailsPage.title.primaryColor};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
`

export const Buttons = styled.div`
  margin: 0;
  grid-column: 3;
  grid-row: 2;
  display: flex;
  gap: 12px;
`

export const SearchInputContainer = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
`

export const SearchInputIcon = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.transactionDetailsPage.searchBar.iconColor};
`

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 38px;
  background-color: transparent;
  border: ${({ theme }) => theme.transactionDetailsPage.searchBar.border};
  color: ${({ theme }) => theme.transactionDetailsPage.searchBar.color};
  border-radius: 4px;
  outline: none;
`

export const DataGrid = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 16px) calc(50% - 16px);
  gap: 32px;
`

export const DataColumn = styled.div``
